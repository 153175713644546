import { FC, useCallback, useEffect } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import { Portal } from "reakit";
import { BannerHeaderPropsType } from "../../../components/Banners/types";
import { PopupPropsType } from "../../../components/Catalog/Popup/Popup";
import type { NotificationPropsType } from "../../../components/Notification/Notification";
import { SearchField } from "../../../components/Search/Field";
import { StyledSearchPopup } from "../../../components/Search/StyledSearch";
import { Button } from "../../../components/ui/Button/Button";
import { cssButtonCatalogPopup, cssButtonMenuToggle } from "../../../components/ui/Button/StyledButton";
import { Provider as HeaderProvider, useHeader } from "../../../hooks/header";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useScrollDirection } from "../../../hooks/scrollDirection";
import { Provider as SearchProvider } from "../../../hooks/search/search";
import { useWindowSize } from "../../../hooks/windowSize";
import { setIsShowFullScreenSearch } from "../../../store/reducers/searchSlice";
import { Container } from "../../../styles/utils/StyledGrid";
import { cssIsActive, getBreakpointVal } from "../../../styles/utils/Utils";
import { breakpoints } from "../../../styles/utils/vars";
import { ROUTES } from "../../../utils/constants";
import { AuthControls } from "./Auth/AuthControls";
import { CallInfo } from "./CallInfo";
import Logo from "./Logo/Logo";
import { NavigationStatic } from "./NavigationStatic";
import { ResponsiveMenuPropsType } from "./ResponsiveMenu/ResponsiveMenu";
import { SelectLocation } from "./SelectLocation";
import { ButtonCatalogContainer, Controls, cssButtonSearchTrigger, cssHeaderCart, cssHeaderDefault, cssIsShowAutoComplete, cssScrollDown, cssScrollUp, RowBottom, RowTop, RowWrap, StyledHeader } from "./StyledHeader";
import { ButtonToCart } from "./ToCart";
import { ButtonToComparison } from "./ToComparison";
import { ButtonToFavorite } from "./ToFavorite";
const BannerHeader = dynamic(((() => import("../../../components/Banners/Header").then(mod => mod.BannerHeader)) as DynamicOptions<BannerHeaderPropsType>), {
  ssr: false
});
const ResponsiveMenu = dynamic(((() => import("./ResponsiveMenu/ResponsiveMenu").then(mod => mod.ResponsiveMenu)) as DynamicOptions<ResponsiveMenuPropsType>), {
  ssr: false
});
const CatalogPopup = dynamic(((() => import("../../../components/Catalog/Popup/Popup").then(mod => mod.Popup)) as DynamicOptions<PopupPropsType>), {
  ssr: false
});
const Notification = dynamic(((() => import("../../../components/Notification/Notification").then(mod => mod.Notification)) as DynamicOptions<NotificationPropsType>), {
  ssr: false
});
const Header: FC = () => {
  const catalogIsShowPopup = useAppSelector(({
    catalog
  }) => catalog.popup.isShow);
  const {
    autoComplete: {
      isShow: isShowAutoComplete
    },
    fullScreen: {
      isShow: isShowFullScreenSearch
    }
  } = useAppSelector(({
    search
  }) => search);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    isShowMiniCart,
    isShowMenu,
    notificationAuth,
    setNotificationAuth,
    catalogShowedToggle,
    menuHide,
    menuShow,
    businessAreas,
    setIsShowMiniCart
  } = useHeader();
  const scroll = useScrollDirection();
  const {
    width
  } = useWindowSize();
  const isMoreLg = width !== undefined && width > getBreakpointVal(breakpoints.lg);
  const setIsShowMiniCartHandle = useCallback((value: boolean) => {
    if (!catalogIsShowPopup && isMoreLg) {
      setIsShowMiniCart(value);
    }
  }, [catalogIsShowPopup, setIsShowMiniCart, isMoreLg]);
  const fullScreenSearchShow = () => {
    dispatch(setIsShowFullScreenSearch(true));
  };
  useEffect(() => {
    switch (scroll) {
      case "up":
        {
          document.body.classList.add(cssScrollUp);
          document.body.classList.remove(cssScrollDown);
          break;
        }
      case "down":
        {
          document.body.classList.remove(cssScrollUp);
          document.body.classList.add(cssScrollDown);
          break;
        }
      case null:
        {
          document.body.classList.remove(cssScrollUp);
          document.body.classList.remove(cssScrollDown);
        }
    }
  }, [scroll]);
  return <>
      <BannerHeader text={"Перейти на старую версию сайта"} href={"https://old.groster.me/"} />

      <StyledHeader data-isshow-catalog={catalogIsShowPopup} data-issshow-menu={isShowMenu} data-issshow-minicart={isShowMiniCart} className={cx(isShowAutoComplete && cssIsShowAutoComplete, cssHeaderDefault, router.pathname.includes(ROUTES.cart) && cssHeaderCart)}>
        <Container>
          <RowTop>
            <RowWrap>
              <SelectLocation />
              <NavigationStatic />
            </RowWrap>
            <AuthControls />
          </RowTop>

          <RowBottom>
            {!isMoreLg && <Button variant={"box"} icon={isShowMenu ? "X" : "Menu"} className={cssButtonMenuToggle} onClick={isShowMenu ? menuHide : menuShow} />}
            <Logo />

            <ButtonCatalogContainer>
              <Button as={"a"} href={ROUTES.catalog} variant={"filled"} icon={catalogIsShowPopup ? "X" : "ViewBoxes"} onClick={e => {
              e.preventDefault();
              catalogShowedToggle();
            }} className={cx(cssButtonCatalogPopup, catalogIsShowPopup && cssIsActive)}>
                Каталог
              </Button>
            </ButtonCatalogContainer>

            {isMoreLg ? <SearchField /> : <Button variant={"box"} icon={"Search"} type={"submit"} className={cx(cssButtonSearchTrigger)} onClick={fullScreenSearchShow} />}

            <Controls>
              <CallInfo />
              {isMoreLg && <>
                  <ButtonToComparison />
                  <ButtonToFavorite />
                </>}

              <ButtonToCart isShowMiniCart={isShowMiniCart} setIsShowMiniCart={setIsShowMiniCartHandle} />
            </Controls>

            {!isMoreLg && <ResponsiveMenu isShow={isShowMenu} />}
          </RowBottom>
        </Container>

        <CatalogPopup businessAreas={businessAreas} />

        {notificationAuth !== null && <Notification isOpen={true} notification={notificationAuth} setNotification={setNotificationAuth} />}

        {isShowFullScreenSearch && <Portal>
            <StyledSearchPopup>
              <SearchField isResponsiveMode isFocusInit />
            </StyledSearchPopup>
          </Portal>}
      </StyledHeader>
    </>;
};
Header.displayName = "Header";
const HeaderWithProviders: FC = () => {
  return <SearchProvider>
      <HeaderProvider>
        <Header />
      </HeaderProvider>
    </SearchProvider>;
};
export { HeaderWithProviders as Header };