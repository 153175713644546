import type { FC } from "react";
import { cx } from "@linaria/core";
import { Typography } from "../../Typography/Typography";
import { Spin } from "../Spin";
import { cssIsFixed, StyledLoaderContainer, StyledLoaderContent, StyledLoaderOverlay } from "./StyledBaseLoader";
export const BaseLoader: FC<{
  isFixed?: boolean;
  message?: string;
}> = ({
  isFixed = false,
  message
}) => {
  return <>
      <StyledLoaderContainer className={cx(isFixed && cssIsFixed)}>
        {message !== undefined ? <>
            <StyledLoaderContent>
              <Spin />
              <Typography variant={"p14"}>{message}</Typography>
            </StyledLoaderContent>
          </> : <Spin />}
        <StyledLoaderOverlay />
      </StyledLoaderContainer>
    </>;
};