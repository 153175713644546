import type { BaseHTMLAttributes, FC } from "react"
import NextLink, { LinkProps } from "next/link"
import { LinkVariants, matching } from "./constants"

export const Link: FC<
  Omit<LinkProps, "passHref"> & {
    variant?: LinkVariants
  } & BaseHTMLAttributes<HTMLAnchorElement>
> = ({
  href,
  children,
  variant = LinkVariants.DEFAULT,
  className,
  ...rest
}) => {
  const LinkComponent = matching[variant]

  return (
    <NextLink href={href} {...rest} className={className} passHref>
      <LinkComponent
        aria-label={rest["aria-label"]}
        title={rest.title}
        target={rest.target}
        className={className}
      >
        {children}
      </LinkComponent>
    </NextLink>
  )
}
