type RequestOptionsType = RequestInit
const token = process.env.NEXT_PUBLIC_DADATA_API_KEY || ""

export type DaDataLocationDataType = {
  region_type: string | null
  region: string | null
  city_type: string | null
  city: string | null
  region_kladr_id: string | null
} | null

export type DaDataLocationType = {
  data: DaDataLocationDataType
} | null

export type DaDataIpLocateResponseType = {
  location: DaDataLocationType
}

export type SuggestAddressesReturnType = {
  suggestions?: {
    unrestricted_value?: string | null
    value?: string | null
    data: DaDataLocationDataType
  }[]
}
export type SuggestFioReturnType = {
  suggestions?: {
    value?: string | null
    unrestricted_value?: string | null
    data: {
      surname: string | null
      name: string | null
      patronymic: string | null
      gender: string | null
      source: string | null
      qc: string | null
    } | null
  }[]
}
export type SuggestEmailReturnType = {
  suggestions?: {
    value: string | null
    unrestricted_value: string | null
    data: {
      local: string | null
      domain: string | null
      type: string | null
      source: string | null
      qc: string | null
    }
  }[]
}

export type SuggestPartyReturnType = {
  suggestions?: {
    data: {
      inn?: null | string
      kpp?: null | string
      ogrn?: null | string
      name?: null | {
        short_with_opf?: string | null
      }
      phones?:
        | null
        | {
            value?: null | string
            data?: {
              type?: null | string | "Мобильный"
              number?: string | null
              source?: null | string
              country_code?: null | string
              city_code?: null | string
            }
          }[]
      management?: {
        name?: string
      } | null
    } | null
  }[]
}

type BoundValueType =
  | "country"
  | "region"
  | "area"
  | "city"
  | "settlement"
  | "street"
  | "house"

type KladrIdType = Record<"kladr_id", string | number>
const KLADR_LIST_DEFAULT: KladrIdType[] = [{ kladr_id: "3400000100000" }]

export interface IfetchAddresse {
  address: string
  locations?: Record<"region_type_full", string | number>[]
  fromBound?: Record<"value", BoundValueType>
  toBound?: Record<"value", BoundValueType>
  kladrId?: string[]
}

export const fetchSuggestAddresses = ({
  address,
  locations,
  fromBound,
  toBound,
  kladrId,
}: IfetchAddresse): Promise<SuggestAddressesReturnType> => {
  const locations_boost =
    kladrId !== undefined
      ? [...kladrId.map((id) => ({ kladr_id: id } as KladrIdType))]
      : [...KLADR_LIST_DEFAULT]

  return dadataPost({
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    body: {
      query: address,
      locations: locations || [],
      from_bound: fromBound,
      to_bound: toBound,
      locations_boost: locations_boost,
    },
  })
}
export const fetchSuggestFIO = ({
  value,
}: {
  value: string
}): Promise<SuggestFioReturnType> => {
  return dadataPost({
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio",
    body: { query: value },
  })
}

export const fetchSuggestEMAIl = ({
  value,
}: {
  value: string
}): Promise<SuggestEmailReturnType> => {
  return dadataPost({
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/email",
    body: { query: value },
  })
}

export const fetchSuggestParty = ({
  value,
}: {
  value: string
}): Promise<SuggestPartyReturnType> =>
  dadataPost({
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
    body: {
      query: value,
    },
  })

export const fetchIpLocate = (
  ip: string,
): Promise<DaDataIpLocateResponseType> =>
  dadataGet({
    url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip=${
      ip !== "::1" ? ip || "" : ""
    }`,
  })

export const dadataPost = <R, Q>({
  url,
  body,
}: {
  url: string
  body: Q
}): Promise<R> => {
  return dadataFetch({
    url,
    options: {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + token,
      },
      body: JSON.stringify(body),
    },
  })
}

export const dadataGet = <R>({ url }: { url: string }): Promise<R> => {
  return dadataFetch({
    url,
    options: {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + token,
      },
    },
  })
}

export const dadataFetch = <R>({
  url,
  options,
}: {
  url: string
  options: RequestOptionsType
}): Promise<R> => {
  return fetch(`${url}`, options)
    .then((response) => response.json() as Promise<R>)
    .catch((error) => error)
}
