import {
  V1ComparesAddCreatePayload,
  V1ComparesDeletePayload,
} from "../../contracts/contracts"
import { deleteFetch, get, post } from "../service/fetcher"
import { CompareType } from "../store/reducers/accountSlice"

export const fetchCompare = (): Promise<CompareType[] | null> =>
  get<CompareType[] | null>("/compares", true)

export const fetchAddToCompare = (
  product: V1ComparesAddCreatePayload,
): Promise<null> =>
  post<null, V1ComparesAddCreatePayload>("/compares/add", product, true)

export const fetchRemoveToCompare = ({
  products,
}: V1ComparesDeletePayload): // products: V1ComparesDeletePayload,
Promise<null> => {
  return deleteFetch<null, V1ComparesDeletePayload>(
    "/compares/delete",
    { products: products },
    true,
  )
}

export const fetchRemoveAllToCompares = (): Promise<null> =>
  deleteFetch<null, null>("/compares/delete-all", null, true)
