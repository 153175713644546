import { useEffect, useRef, useState } from "react"
import useIntersectionObserver from "../../../hooks/useIntersectionObserver"
import { Container } from "../../../styles/utils/StyledGrid"
import { Slider } from "./Slider"

export const WatchedRecentlySliderContainer = () => {
  const containerRef = useRef(null)
  const intersection = useIntersectionObserver(containerRef, {
    freezeOnceVisible: false,
  })

  // если скроллим обратно вверх и секция пропадает
  // и при переходе по товарам сохраняем uuid
  // мы не должны делать повторный запрос, но секция должна быть в DOM
  const isVisible = intersection?.isIntersecting ?? false

  // при нахождении экрана на секции
  // показываем слайдер и делаем запрос
  const [inView, setInView] = useState(isVisible)

  useEffect(() => {
    setInView((prev) => prev || isVisible)
  }, [isVisible])

  return (
    <Container ref={containerRef}>
      {inView && <Slider isRun={isVisible} />}
    </Container>
  )
}

WatchedRecentlySliderContainer.displayName = "WatchedRecentlySliderContainer"
