import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  AddressType,
  OrderList,
  OrderType,
  ParamsResponse,
  ProductDetailListType,
  ResponseCustomerDataList,
} from "../../../contracts/contracts"
import { ProductType } from "../../components/Products/types"
import { setUser, User } from "../../hooks/auth"
import { QueryFiltersHistoryType } from "../../hooks/filterHistory/types"
import { OrderStateNumberType } from "../../hooks/order"
import {
  IAvailableParam,
  IAvailableParams,
  IAvailableParamsKeys,
  IFilterParamsChilds,
  IFilterParamsParents,
  LastOrderReturnType,
  PayerListItemType,
  SpecificationItemType,
  TogglePageMethodType,
} from "../../types/types"
import {
  getAllProductsID,
  getAvailableParamsTree,
  getFilterParamsTree,
  matchKeys,
  sortProductsFromApi,
} from "../../utils/helpers"

export const INITIAL_PAGE = 1
export const INITIAL_PER_PAGE = 10

export const MAX_COUNT_PRODUCTS = 31

export type CompareType = {
  products: string[]
  uuid: string
  name: string
}
const initialState = {
  favorites: {
    keys: null as string[] | null,
    products: null as ProductDetailListType | null,
    toggleProgress: [] as string[] | [],
  },
  compare: {
    categories: null as null | Array<CompareType>,
    products: null as ProductDetailListType | null,
    toggleProgressCategory: null as null | string,
    toggleProgress: [] as string[] | [],
    isFetching: false as boolean,
    keys: null as string[] | null,
  },
  addresses: null as AddressType[] | null,
  payers: null as PayerListItemType[] | null,
  user: null as Required<User> | null,
  history: {
    orders: {
      total: 0 as number,
      items: null as Record<string, OrderType> | null,
      detail: {
        specification: null as Record<string, SpecificationItemType> | null,
        products: null as Record<string, ProductType> | null,
        samples: null as Record<string, ProductType> | null,
      },
    },
    products: {
      total: 0 as number,
      items: null as Record<string, ProductType> | null,
    },
    filter: {
      availableParams: null as IAvailableParams | null,
      params: {} as IFilterParamsParents & IFilterParamsChilds,
      checkedParamsKeysTotal: [] as string[],
      state: null as (OrderStateNumberType | -1) | null,
      category: null as string | null,
    },
    query: null as QueryFiltersHistoryType | null,
    page: INITIAL_PAGE as number,
    itemsPerPage: INITIAL_PER_PAGE as number,
    togglePageMethod: "switch" as TogglePageMethodType,
    watchedRecentProducts: {
      keys: null as string[] | null,
      products: null as ProductDetailListType | null,
      exclude: [] as string[],
    },
  },
  priceList: {
    indeterminate: [] as string[] | [],
    selected: [] as string[] | [],
  },
  isAuth: false as boolean | false,
  isInit: false as boolean | false,
  lastOrder: null as LastOrderReturnType | null,
  customer: null as ResponseCustomerDataList | null,
}
export const accountSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    addToFavorites(state, { payload }: PayloadAction<string[]>) {
      state.favorites.keys = matchKeys({
        stateKeys: state.favorites.keys,
        newKeys: payload,
      })
    },
    removeFromFavorites(state, action: PayloadAction<string>) {
      if (state.favorites.keys === null) {
        return
      }
      const filter = state.favorites.keys.filter(
        (item: string) => item !== action.payload,
      )
      state.favorites.keys = !!filter.length ? filter : null
    },
    removeAllFromFavorites(state) {
      if (state.favorites.keys === null) {
        return
      }

      state.favorites.keys = null
    },
    clearFavorites(state) {
      state.favorites.keys = null
      state.favorites.products = []
      state.favorites.toggleProgress = []
    },
    setFavoritesProducts(
      state,
      { payload }: PayloadAction<ProductDetailListType>,
    ) {
      state.favorites.products = sortProductsFromApi(
        payload,
        state.favorites.keys,
      )
    },
    //==//
    // compare start
    //==//
    addToCompare(
      //common fn
      state,
      {
        payload,
      }: PayloadAction<{
        productUUID: string[]
        categoryUUID: string
      }>,
    ) {
      if (payload.categoryUUID) {
        // Проверяем существует ли категория
        const categoryIndex = (state.compare.categories ?? []).findIndex(
          (category) => category.uuid === payload.categoryUUID,
        )
        if (
          categoryIndex !== undefined &&
          categoryIndex !== -1 &&
          !!state.compare.categories
        ) {
          // Категория уже существует, добавляем новый продукт
          const category = state.compare.categories[categoryIndex]
          // Используем Set для того, чтобы избежать дублирования продуктов в списке.
          const products = new Set(
            category.products.concat(payload.productUUID),
          )
          state.compare.categories[categoryIndex] = {
            ...category,
            products: Array.from(products),
          }
        } else {
          if (!state.isAuth) {
            // Категория не существует, создаем новую
            state.compare.categories = [
              ...(state.compare.categories || []),
              {
                products: payload.productUUID,
                uuid: payload.categoryUUID,
                name: "",
              },
            ]
          }
        }
      }

      state.compare.keys = matchKeys({
        stateKeys: state.compare.keys,
        newKeys: payload.productUUID,
      })
    },
    addCompareCategory(state, { payload }: PayloadAction<Array<CompareType>>) {
      //common fn
      const newKeysFromCategories = getAllProductsID(payload)

      state.compare.categories = payload

      state.compare.keys = matchKeys({
        stateKeys: state.compare.keys,
        newKeys: newKeysFromCategories,
      })
    },

    removeFromCompare(
      state,
      action: PayloadAction<{
        removeProductFromCategory?: string
        uuidCategory?: string
        products: string
      }>,
    ) {
      if (state.compare.keys === null) return

      // Удаляем ключи продуктов из keys
      const productIds = action.payload.products.split(",")

      const filter = state.compare.keys.filter(
        (item: string) => !productIds.includes(item),
      )
      state.compare.keys = filter.length > 0 ? filter : null

      if (state.compare.products) {
        // удаляем продукт из массива продуктов, по которому идет отрисовка
        const filter = state.compare.products.filter((product) => {
          return product.uuid !== action.payload.products
        })
        state.compare.products = filter.length > 0 ? filter : null
      }

      //common fn
      if (action.payload.uuidCategory) {
        // когда удаляем категорию
        // Создаем переменную, чтобы сохранить идентификаторы продуктов из категории, которую нужно удалить
        let productsCategory: string[] = []

        // Фильтруем массив категорий (state.compare.categories), чтобы удалить категорию с uuid, указанным в action.payload.uuidCategory
        state.compare.categories =
          state.compare.categories?.filter((category) => {
            // Если найдена категория, которую нужно удалить, то сохраняем идентификаторы продуктов из этой категории в productsCategory
            if (category.uuid === action.payload.uuidCategory) {
              productsCategory = category.products
            }

            // Возвращаем true, если uuid текущей категории не соответствует uuid категории, которую нужно удалить
            return category.uuid !== action.payload.uuidCategory
          }) || null

        // если мы удаляем категорию, зачищаем продукты этой категории
        if (productsCategory.length > 0 && state.compare.products) {
          const newProducts = state.compare.products.filter(
            (product) =>
              !!product?.uuid && !productsCategory.includes(product.uuid),
          )

          state.compare.products = newProducts.length > 0 ? newProducts : null
        }
      }
      //common fn
      //когда удаляем продукт нужно удалять его из ключей чтобы менялся счетчик у категории
      //и если мы удалили последний продукт в категории, удаляем весь объект
      if (
        action.payload.removeProductFromCategory &&
        !!state.compare?.categories
      ) {
        const categoryUUID = action.payload.removeProductFromCategory

        const index = state.compare.categories.findIndex(
          (item) => item.uuid === categoryUUID,
        )
        if (index !== -1) {
          // Удаляем из массива categories.products нужный products
          const clearProducts = state.compare.categories[index].products.filter(
            (id) => id !== action.payload.products,
          )

          if (clearProducts.length === 0) {
            // Если после удаления products стал пустым массивом Удаляем весь объект из массива
            state.compare.categories = state.compare.categories.filter(
              (categories) => categories.uuid !== categoryUUID,
            )
          } else {
            // иначе удаляем только этот продукт
            state.compare.categories[index].products = clearProducts
          }

          state.compare.products
        }
      }
    },
    removeAllFromCompares(state) {
      state.compare.categories = null
      state.compare.products = null
      state.compare.keys = null
    },
    setCompareProducts(
      state,
      { payload }: PayloadAction<ProductDetailListType>,
    ) {
      const newProducts = sortProductsFromApi(payload, state.compare.keys)
      state.compare.products = newProducts
    },
    removeComparesAllProgress(
      state,
      action: PayloadAction<{ isFetching: boolean }>,
    ) {
      state.compare.toggleProgress = action.payload.isFetching
        ? [...(state.compare.keys ?? "")]
        : []
      state.compare.isFetching = action.payload.isFetching
    },
    toggleCompareProgress(
      state,
      action: PayloadAction<{
        uuidCategory?: string
        isFetching: boolean
        products: string
      }>,
    ) {
      state.compare.toggleProgress = action.payload.isFetching
        ? [
            ...state.compare.toggleProgress,
            ...action.payload.products.split(",").map((id) => id.trim()),
          ]
        : state.compare.toggleProgress.filter(
            (id) =>
              !action.payload.products
                .split(",")
                .map((id) => id.trim())
                .includes(id),
          )
      if (action.payload.uuidCategory) {
        state.compare.toggleProgressCategory = action.payload.isFetching
          ? action.payload.uuidCategory
          : null
      }
    },
    //==//
    // compare end
    //==//
    setUserProfile(state, action: PayloadAction<User | null>) {
      if (action.payload !== null) {
        state.user = {
          email: action.payload.email || null,
          accessToken: action.payload.accessToken || null,
          phone: action.payload.phone || null,
          cart: action.payload.cart || null,
          refreshToken: action.payload.refreshToken || null,
          fio: action.payload.fio || null,
          isAdmin: !!action.payload.isAdmin,
        }
      } else {
        state.user = action.payload
      }

      setUser(action.payload)
    },
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
    setIsInit(state, action: PayloadAction<boolean>) {
      state.isInit = action.payload
    },
    toggleFavoritesProgress(
      state,
      action: PayloadAction<{ isFetching: boolean; product: string }>,
    ) {
      state.favorites.toggleProgress = action.payload.isFetching
        ? [...state.favorites.toggleProgress, action.payload.product]
        : state.favorites.toggleProgress.filter(
            (id) => id !== action.payload.product,
          )
    },
    removeFavoritesAllProgress(
      state,
      action: PayloadAction<{ isFetching: boolean }>,
    ) {
      state.favorites.toggleProgress = action.payload.isFetching
        ? [...(state.favorites.keys ?? "")]
        : []
    },
    setAddresses(state, action: PayloadAction<AddressType[] | null>) {
      state.addresses = action.payload
    },
    setPayers(state, action: PayloadAction<PayerListItemType[] | null>) {
      state.payers = action.payload
    },
    setHistoryFilterParams(state, { payload }: PayloadAction<ParamsResponse>) {
      state.history.filter.params = getFilterParamsTree(payload)
    },
    setHistoryFilterAvailableParams(
      state,
      action: PayloadAction<IAvailableParamsKeys | null>,
    ) {
      if (action.payload !== null) {
        state.history.filter.availableParams = getAvailableParamsTree(
          state.history.filter.params,
          action.payload,
        ).tree
      } else {
        state.history.filter.availableParams = action.payload
      }
    },
    updateCheckedParams(
      state,
      action: PayloadAction<{
        keyFilter: string[]
        checked: boolean
      }>,
    ) {
      if (!!state.history.filter.availableParams) {
        const { keyFilter, checked } = action.payload
        keyFilter.map((key) => {
          if (!!state.history.filter.availableParams) {
            const parent: IAvailableParam =
              state.history.filter.availableParams[
                state.history.filter.params[key].parentUuid || ""
              ]

            if (!!parent.params[key]) {
              parent.params[key].checked = checked
            }
            if (checked) {
              parent.checkedKeys = [...parent.checkedKeys, key].reduce(
                (uniq: string[], item) => {
                  return uniq.includes(item) ? uniq : [...uniq, item]
                },
                [],
              )
              state.history.filter.checkedParamsKeysTotal = [
                ...state.history.filter.checkedParamsKeysTotal,
                key,
              ].reduce((uniq: string[], item) => {
                return uniq.includes(item) ? uniq : [...uniq, item]
              }, [])
            } else {
              parent.checkedKeys = parent.checkedKeys.filter((k) => k !== key)
              state.history.filter.checkedParamsKeysTotal =
                state.history.filter.checkedParamsKeysTotal.filter(
                  (k) => k !== key,
                )
            }
          }
        })
      }
    },
    setSpecification(
      state,
      action: PayloadAction<SpecificationItemType[] | null>,
    ) {
      if (action.payload !== null) {
        let specification: Record<string, SpecificationItemType> = {}

        if (!!state.history.orders.detail.specification) {
          specification = { ...state.history.orders.detail.specification }
        }

        action.payload
          .sort((a, b) => {
            if (!a.parent) return -1
            if (!b.parent) return 1
            return 0
          })
          .map((p) => {
            if (!!p.uuid) {
              // распределение обычных товаров
              // и товаров, являющихся дочерними комплекта
              if (!!p.quantity && !!p.parent) {
                if (
                  !!specification[p.parent] &&
                  !!specification[p.parent]["child"]
                ) {
                  specification[p.parent]["child"] = {
                    ...specification[p.parent]["child"],
                    ...{ [p.uuid]: p },
                  }
                }
              } else {
                specification[p.uuid] = p
              }
            }
          })

        state.history.orders.detail.specification = specification
      } else {
        state.history.orders.detail.specification = null
      }
    },
    setProducts(
      state,
      action: PayloadAction<Record<string, ProductType> | null>,
    ) {
      state.history.orders.detail.products =
        action.payload !== null
          ? { ...state.history.orders.detail.products, ...action.payload }
          : action.payload
    },
    setSamples(
      state,
      action: PayloadAction<Record<string, ProductType> | null>,
    ) {
      state.history.orders.detail.samples =
        action.payload !== null
          ? { ...state.history.orders.detail.samples, ...action.payload }
          : action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.history.page = action.payload
    },
    setItemsPerPage(state, action: PayloadAction<number | null>) {
      state.history.itemsPerPage = action.payload || INITIAL_PER_PAGE
    },
    setTotalOrders(state, action: PayloadAction<number>) {
      state.history.orders.total = action.payload
    },
    setTogglePageMethod(state, action: PayloadAction<TogglePageMethodType>) {
      state.history.togglePageMethod = action.payload
    },
    setWatchedRecentProductsIds(state, { payload }: PayloadAction<string[]>) {
      state.history.watchedRecentProducts.keys = matchKeys({
        stateKeys: state.history.watchedRecentProducts.keys,
        newKeys: payload,
        limit: MAX_COUNT_PRODUCTS,
      })
    },
    setWatchedRecentProducts(
      state,
      action: PayloadAction<ProductDetailListType>,
    ) {
      state.history.watchedRecentProducts.products = sortProductsFromApi(
        action.payload,
        state.history.watchedRecentProducts.keys,
      )
    },
    addExcludeWatchedRecentProduct(
      state,
      { payload }: PayloadAction<string[]>,
    ) {
      state.history.watchedRecentProducts.exclude = [
        ...state.history.watchedRecentProducts.exclude,
        ...payload,
      ]
    },
    removeExcludeWatchedRecentProduct(
      state,
      { payload }: PayloadAction<string[]>,
    ) {
      state.history.watchedRecentProducts.exclude =
        state.history.watchedRecentProducts.exclude.filter(
          (uuid) => !payload.includes(uuid),
        )
    },
    setFilterState(
      state,
      action: PayloadAction<(OrderStateNumberType | -1) | null>,
    ) {
      state.history.filter.state = action.payload
    },
    setFilterCategory(state, action: PayloadAction<string | null>) {
      state.history.filter.category = action.payload
    },
    setTotalProducts(state, action: PayloadAction<number>) {
      state.history.products.total = action.payload
    },
    setHistoryProducts(
      state,
      action: PayloadAction<{
        products: ProductType[] | null
        queueKeys: string[]
      }>,
    ) {
      // при выборе Категории в фильтрах
      // товары из этой категории должны выводиться первыми
      // получили нужны порядок ключей и хаотичный порядок товаров
      // сопоставляем порядок и данные товаров
      if (action.payload.products !== null) {
        const items = {}
        let products = [...action.payload.products]
        for (const uuid of action.payload.queueKeys) {
          const foundProduct = products.find((p) => p.uuid === uuid)
          if (!foundProduct) {
            continue
          }
          items[uuid] = { ...foundProduct }
          products = products.filter((p) => p.uuid !== uuid)
        }

        if (products.length > 0) {
          for (const prod of products) {
            if (!prod.uuid) {
              continue
            }
            items[prod.uuid] = { ...prod }
          }
        }

        state.history.products.items = items
      } else {
        state.history.products.items = null
      }
    },
    setOrders: (state, action: PayloadAction<OrderList[]>) => {
      if (action.payload !== null) {
        const orders = {}
        for (const order of action.payload) {
          if (order.uid == undefined) {
            continue
          }
          orders[order.uid] = { ...order }
        }
        state.history.orders.items = orders
      } else {
        state.history.orders.items = action.payload
      }
    },
    updateOrderState: (
      state,
      action: PayloadAction<{ uid: string; state: OrderStateNumberType }>,
    ) => {
      if (state.history.orders.items !== null) {
        const order = state.history.orders.items[action.payload.uid]
        if (order !== undefined) {
          state.history.orders.items[action.payload.uid].state =
            action.payload.state
        }
      }
    },
    setCategoriesPriceListSelected: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.priceList.selected = action.payload
    },
    setLastOrder: (
      state,
      action: PayloadAction<null | LastOrderReturnType>,
    ) => {
      state.lastOrder =
        action.payload !== null ? { ...action.payload, replacement: 3 } : null
    },
    setCustomer: (
      state,
      action: PayloadAction<null | ResponseCustomerDataList>,
    ) => {
      state.customer = action.payload
    },
    setQuery: (
      state,
      { payload }: PayloadAction<QueryFiltersHistoryType | null>,
    ) => {
      state.history.query = payload
    },
  },
})

export const {
  setPayers,
  setIsInit,
  setUserProfile,
  setIsAuth,
  setProducts,
  setTotalProducts,
  setTotalOrders,
  setOrders,
  setLastOrder,
  // start favorites
  removeFavoritesAllProgress,
  toggleFavoritesProgress,
  removeAllFromFavorites,
  setFavoritesProducts,
  removeFromFavorites,
  clearFavorites,
  addToFavorites,
  // start compare
  removeComparesAllProgress,
  removeAllFromCompares,
  toggleCompareProgress,
  setCompareProducts,
  addCompareCategory,
  removeFromCompare,
  addToCompare,
  // start history
  setHistoryFilterAvailableParams,
  setHistoryFilterParams,
  setHistoryProducts,
  // start filter
  setFilterCategory,
  setFilterState,
  setCategoriesPriceListSelected,
  setSamples,
  setSpecification,
  updateOrderState,
  updateCheckedParams,
  setCurrentPage,
  setWatchedRecentProductsIds,
  setWatchedRecentProducts,
  addExcludeWatchedRecentProduct,
  removeExcludeWatchedRecentProduct,
  setItemsPerPage,
  setCustomer,
  setQuery,
} = accountSlice.actions
