import type { FC } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import LinkList from "../../../../components/List/LinkList/LinkList";
import { Button } from "../../../../components/ui/Button/Button";
import { cssButtonClean } from "../../../../components/ui/Button/StyledButton";
import { Link } from "../../../../components/ui/Link";
import { LinkVariants } from "../../../../components/ui/Link/constants";
import { Modal } from "../../../../components/ui/Modals/Modal";
import { Popover } from "../../../../components/ui/Popover/Popover";
import { Typography } from "../../../../components/ui/Typography/Typography";
import { useWindowSize } from "../../../../hooks/windowSize";
import { getBreakpointVal } from "../../../../styles/utils/Utils";
import { breakpoints } from "../../../../styles/utils/vars";
import { CONTACT_PHONE, PHONES_ITEMS } from "../../../../utils/constants";
import { ListGroup } from "../../Footer/StyledFooter";
import { StyledCallContainer } from "./StyledCallInfo";
const Recall = dynamic(((() => import("../../../../components/Forms/Recall").then(mod => mod.Recall)) as DynamicOptions), {
  ssr: false
});
export const CallInfo: FC = () => {
  const {
    width
  } = useWindowSize();
  const isResponsMode = width !== undefined && width <= getBreakpointVal(breakpoints.lg);
  return <StyledCallContainer>
      <Popover offset={[0, -2]} withHover={!isResponsMode} placement={"bottom-start"} disclosure={<Button className={cssButtonClean}>
            <Typography variant={"h3"}>
              {isResponsMode ? CONTACT_PHONE.title : <Link href={CONTACT_PHONE.path} variant={LinkVariants.BLACK_TO_PURPLE}>
                  {CONTACT_PHONE.title}
                </Link>}
            </Typography>
          </Button>}>
        <ListGroup>
          <LinkList items={isResponsMode ? PHONES_ITEMS : PHONES_ITEMS.filter((_, i) => i !== 0)} variant={LinkVariants.BLACK_TO_PURPLE} />
        </ListGroup>
      </Popover>

      <Modal closeMode={"destroy"} disclosure={<Button>Заказать звонок</Button>} title={"Заказать звонок"}>
        <Recall />
      </Modal>
    </StyledCallContainer>;
};
CallInfo.displayName = "CallInfo";