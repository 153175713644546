import type { FC } from "react"
import { memo } from "react"
import {
  InitialsBody,
  InitialsSymbol,
  InitialsWord,
} from "../../components/ui/Typography/Typography"

type WordWithInitialsPropsType = {
  word: string
}
export const WordWithInitials: FC<WordWithInitialsPropsType> = memo(
  ({ word }) => {
    return (
      <>
        <InitialsWord>
          <InitialsSymbol>{word.charAt(0)}</InitialsSymbol>
          <InitialsBody>{word.slice(1)}</InitialsBody>
        </InitialsWord>
      </>
    )
  },
)

WordWithInitials.displayName = "WordWithInitials"
