import React, { FC, memo } from "react"
import { Link } from "../Link"
import { NavigationTitlePropsType } from "./types"

const NavigationTitle: FC<NavigationTitlePropsType> = memo(
  ({ title, path, variant, isLink, isScroll }) => {
    return (
      <>
        {!!isLink ? (
          <Link variant={variant} href={path} scroll={isScroll} title={title}>
            {title}
          </Link>
        ) : (
          <>{title}</>
        )}
      </>
    )
  },
)

NavigationTitle.displayName = "NavigationTitle"
export { NavigationTitle }
