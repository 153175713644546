import { FC, memo, useCallback, useMemo } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import { Button } from "../../../../components/ui/Button/Button";
import { cssButtonToCart } from "../../../../components/ui/Button/StyledButton";
import { useCart } from "../../../../hooks/cart/cart";
import PushCounter from "../../../../styles/utils/PushCounter";
import { Wrapper } from "../../../../styles/utils/Utils";
import { LIMIT_COUNT_IN_CART_NUM, ROUTES } from "../../../../utils/constants";
const DynamicMiniCart = dynamic(((() => import("../../../../components/Cart/MiniCart").then(mod => mod.MiniCart)) as DynamicOptions), {
  ssr: false
});
export const ButtonToCart: FC<{
  isShowMiniCart?: boolean;
  setIsShowMiniCart?: (val: boolean) => void;
  isShoweBGbutton?: boolean;
}> = memo(({
  isShowMiniCart,
  setIsShowMiniCart,
  isShoweBGbutton = true
}) => {
  const {
    cartCount
  } = useCart();
  const {
    pathname
  } = useRouter();
  const onMouseEnterhandle = useCallback(() => {
    if ([ROUTES.cart, ROUTES.checkout].includes(pathname)) {
      return;
    }
    !!setIsShowMiniCart && setIsShowMiniCart(true);
  }, [pathname, setIsShowMiniCart]);
  const onMouseLeaveHandle = useCallback(() => {
    !!setIsShowMiniCart && setIsShowMiniCart(false);
  }, [setIsShowMiniCart]);
  const counterElement = useMemo(() => <>
        {cartCount !== null && cartCount > 0 && <PushCounter>
            {cartCount > LIMIT_COUNT_IN_CART_NUM ? `${LIMIT_COUNT_IN_CART_NUM}+` : cartCount}
          </PushCounter>}
      </>, [cartCount]);
  return <>
      <Wrapper onMouseEnter={onMouseEnterhandle} onMouseLeave={onMouseLeaveHandle}>
        <Button as={"a"} variant={"box"} icon={"ShoppingCart"} className={cx(isShoweBGbutton && cssButtonToCart)} href={ROUTES.cart} aria-label={"Перейти в корзину"} seoText={"Перейти в корзину"}>
          {counterElement}
        </Button>
        {isShowMiniCart && <DynamicMiniCart />}
      </Wrapper>
    </>;
});
ButtonToCart.displayName = "ButtonToCart";