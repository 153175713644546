import { FC, useMemo } from "react";
import { cx } from "@linaria/core";
import { Button } from "../../../../components/ui/Button/Button";
import { cssButtonToFavorite } from "../../../../components/ui/Button/StyledButton";
import { useAuth } from "../../../../hooks/auth";
import { useFavorites } from "../../../../hooks/favorites";
import PushCounter from "../../../../styles/utils/PushCounter";
import { LIMIT_COUNT_IN_CART_NUM, ROUTES } from "../../../../utils/constants";
export const ButtonToFavorite: FC = () => {
  const {
    quantity
  } = useFavorites();
  const {
    isInit,
    isAuth
  } = useAuth();
  const counterElement = useMemo(() => <>
        {quantity > 0 && <PushCounter>
            {quantity > LIMIT_COUNT_IN_CART_NUM ? `${LIMIT_COUNT_IN_CART_NUM}+` : quantity}
          </PushCounter>}
      </>, [quantity]);
  return <Button variant={"box"} icon={"StarOutline"} className={cx(cssButtonToFavorite)} as={"a"} href={isInit && isAuth ? `${ROUTES.account}${ROUTES.favorites}` : ROUTES.favorites} aria-label={"Перейти в избранное"} seoText={"Перейти в избранное"}>
      {counterElement}
    </Button>;
};
ButtonToFavorite.displayName = "ButtonToFavorite";