import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { useRouter } from "next/router"
import { useQueries } from "react-query"
import {
  BannerApiType,
  CategoryResponse,
  FrontDeliveryMethod,
  ParamsResponse,
  TagResponse,
} from "../../contracts/contracts"
import { Settings } from "../../contracts/src/api/settings"
import { fetchBanners } from "../api/bannersAPI"
import { fetchFreeShipping } from "../api/cartAPI"
import {
  fetchCategories,
  fetchFiltersParams,
  fetchTags,
} from "../api/catalogAPI"
import { fetchShippingMethods } from "../api/checkoutAPI"
import { fetchSettingsApp } from "../api/settingsAPI"
import {
  setBanners,
  setIsDateToOnlyCompany,
  setIsLoadingPage,
  setLocation,
  setSettings,
  SettingsAppType,
} from "../store/reducers/appSlice"
import { setFreeShipping } from "../store/reducers/cartSlice"
import {
  setBusinessAreas,
  setCategories,
  setFilterParams,
  setTags,
} from "../store/reducers/catalogSlice"
import {
  FreeShippingReturnType,
  LinkItemType,
  LocationExtendsType,
  LocationType,
  ShippingsType,
  StoreBannersType,
} from "../types/types"
import {
  getLocationCookie,
  getLocationRegion,
  scrollBodyEnable,
  setLocationCookie,
} from "../utils/helpers"
import { useAppDispatch, useAppSelector } from "./redux"
import { useShops } from "./shops/shops"
import { useWatchedRecent } from "./watchedRecent/useWatchedRecent"

export type AppContextPropsType = {
  location: LocationExtendsType | null
  settings: SettingsAppType | null
  banners: StoreBannersType | null
  updateLocation: (location: LocationType | null) => void
  updateBanners: (banners: BannerApiType[] | undefined) => void
  socials: null | Record<string, LinkItemType>
  updateIsLoadingPage: (value: boolean) => void
  isLoadingPage: boolean
  isDateToOnlyCompany: boolean
}
export type UseAppReturnType = AppContextPropsType

const cLocation = getLocationCookie()

const AppContext = createContext<null | AppContextPropsType>(null)

export function Provider({ children }: { children?: ReactNode }): JSX.Element {
  const { location, settings, banners, isLoadingPage, isDateToOnlyCompany } =
    useAppSelector(({ app }) => app)
  const dispatch = useAppDispatch()
  const router = useRouter()

  const socials = useMemo(() => {
    const socials: Record<string, LinkItemType> = {}
    if (!settings) {
      return socials
    }

    const { viber, telegram, whatsApp } = settings

    if (!!viber && viber.length > 0) {
      socials["viber"] = {
        icon: "Viber",
        path: viber,
      }
    }
    if (!!whatsApp && whatsApp.length > 0) {
      socials["whatsApp"] = {
        icon: "WhatsApp",
        path: whatsApp,
      }
    }
    if (!!telegram && telegram.length > 0) {
      socials["telegram"] = {
        icon: "Telegram",
        path: telegram,
      }
    }

    return socials
  }, [settings])

  // инициирует первоначальный запрос магазинов
  useShops()

  const { init: initWatchedRecent } = useWatchedRecent()

  useQueries([
    {
      queryKey: ["filterParams"],
      queryFn: () => fetchFiltersParams(),
      staleTime: Infinity,
      onSuccess: (data: ParamsResponse) => {
        dispatch(setFilterParams(data || null))
      },
    },
    {
      queryKey: ["categories"],
      queryFn: () => fetchCategories(),
      staleTime: Infinity,
      onSuccess: (data: CategoryResponse) => {
        const { categories, bussinessAreas } = data || {}
        dispatch(setCategories(categories || []))
        dispatch(setBusinessAreas(bussinessAreas || []))
      },
    },
    {
      queryKey: ["settings"],
      queryFn: () => fetchSettingsApp(),
      staleTime: Infinity,
      onSuccess: (data: Settings) => {
        dispatch(setSettings(data || null))
      },
    },
    {
      queryKey: ["tags"],
      queryFn: () => fetchTags(),
      staleTime: Infinity,
      onSuccess: (data: TagResponse) => {
        dispatch(setTags(data || null))
      },
    },
    {
      queryKey: ["banners"],
      queryFn: () => fetchBanners(),
      staleTime: Infinity,
      onSuccess: (data: BannerApiType[]) => {
        updateBannersDispatch(data)
      },
    },
    {
      queryKey: ["shippingMethods", location],
      queryFn: () =>
        fetchShippingMethods({
          regions: getLocationRegion(location) || "",
        }),
      staleTime: 5000,
      enabled: location !== null,
      onSuccess: (data: FrontDeliveryMethod[]) => {
        dispatch(
          setIsDateToOnlyCompany(
            !(data || []).find((m) => (m.alias as ShippingsType) === "courier"),
          ),
        )
      },
    },
    {
      queryKey: ["freeShipping", location?.city_full],
      queryFn: () => fetchFreeShipping(location?.city_full || ""),
      enabled: !!location?.city_full,
      staleTime: 5000,
      onSuccess: (data: FreeShippingReturnType) => {
        dispatch(setFreeShipping(data))
      },
    },
  ])

  const updateLocationDispatch = useCallback(
    (location: LocationType | null) => {
      dispatch(setLocation(location))
    },
    [dispatch],
  )

  const updateBannersDispatch = useCallback(
    (banners: BannerApiType[] | undefined) => {
      dispatch(setBanners(banners || null))
    },
    [dispatch],
  )

  const updateLocationHandle = useCallback(
    (location: LocationType | null) => {
      updateLocationDispatch(location)
      setLocationCookie(location)
    },
    [updateLocationDispatch],
  )

  const updateIsLoadingPage = useCallback(
    (value: boolean) => {
      dispatch(setIsLoadingPage(value))
    },
    [dispatch],
  )

  useEffect(() => {
    updateLocationDispatch(!!cLocation ? cLocation : null)
  }, [updateLocationDispatch])

  useEffect(() => {
    initWatchedRecent()
  }, [initWatchedRecent])

  useEffect(() => {
    const onRouteChangeStart = () => {
      updateIsLoadingPage(true)
    }

    const onRouteChangeComplete = () => {
      updateIsLoadingPage(false)
      scrollBodyEnable()
    }

    router.events.on("routeChangeStart", onRouteChangeStart)
    router.events.on("routeChangeComplete", onRouteChangeComplete)

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart)
      router.events.off("routeChangeComplete", onRouteChangeComplete)
    }
  }, [])

  const contextValue = useMemo(
    () =>
      ({
        location: location,
        settings,
        updateLocation: updateLocationHandle,
        banners,
        updateBanners: updateBannersDispatch,
        socials,
        isLoadingPage,
        updateIsLoadingPage: updateIsLoadingPage,
        isDateToOnlyCompany: isDateToOnlyCompany,
      } as AppContextPropsType),
    [
      settings,
      location,
      updateLocationHandle,
      banners,
      updateBannersDispatch,
      socials,
      isLoadingPage,
      updateIsLoadingPage,
      isDateToOnlyCompany,
    ],
  )

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export const useApp = (): UseAppReturnType => {
  const appContext = useContext(AppContext)

  if (appContext === null) {
    throw new Error("App context have to be provided")
  }

  return {
    location: appContext.location,
    settings: appContext.settings,
    updateLocation: appContext.updateLocation,
    banners: appContext.banners,
    updateBanners: appContext.updateBanners,
    socials: appContext.socials,
    updateIsLoadingPage: appContext.updateIsLoadingPage,
    isLoadingPage: appContext.isLoadingPage,
    isDateToOnlyCompany: appContext.isDateToOnlyCompany,
  } as const
}
