import { useEffect, useRef } from "react"
import { useRouter } from "next/router"
import { ROUTES } from "../utils/constants"

// получаем координаты элемента в контексте документа
function getCoords(elem: Element) {
  const box = elem.getBoundingClientRect()

  return {
    top: box.top + window.scrollY,
    right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY,
    left: box.left + window.scrollX,
  }
}

export const usePreserveScroll = (): void => {
  const router = useRouter()

  const scrollPositions = useRef<{ [url: string]: number }>({})
  const isBack = useRef(false)

  const lastUrlRef = useRef<string | null>(null)

  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true
      return true
    })

    const onRouteChangeStart = () => {
      const url = router.asPath
      lastUrlRef.current = url
      scrollPositions.current[url] = window.scrollY

      // чтобы не скроллилась страница до того
      // как выполнится getServerSideProps
      window.history.scrollRestoration = "manual"
    }

    const onRouteChangeComplete = (url: string) => {
      let positionToScroll = scrollPositions.current[url]
      const { current: lastUrl } = lastUrlRef

      // расширен метод для перехода из детальной в каталог для ситуации
      // если перешли из каталога, в котором были догруженные товары,
      // которые увеличат высоту страницы но не сохранят ее после перезагрузки или роутинга
      // должны оказаться там, где перешли по прошлому товару
      if (lastUrl !== null && lastUrl.includes(ROUTES.product)) {
        const splittedUrl = lastUrl.split("/")
        const alias = (splittedUrl[splittedUrl.length - 1] || "").split("?")[0]
        if (!!alias) {
          const productElement = document.querySelector(
            `[data-alias="${alias}"]`,
          )
          if (productElement) {
            positionToScroll = getCoords(productElement).top
          }
        }
      }

      if (isBack.current && positionToScroll) {
        window.scroll({
          top: positionToScroll,
          behavior: "auto",
        })
      }

      isBack.current = false
    }

    router.events.on("routeChangeStart", onRouteChangeStart)
    router.events.on("routeChangeComplete", onRouteChangeComplete)

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart)
      router.events.off("routeChangeComplete", onRouteChangeComplete)
    }
  }, [router])
}
