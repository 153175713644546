import { FC } from "react"
import { useQuery } from "react-query"
import { fetchProductsList } from "../../../api/productsAPI"
import { useAppDispatch, useAppSelector } from "../../../hooks/redux"
import { setWatchedRecentProducts } from "../../../store/reducers/accountSlice"
import { PureSlider } from "./PureSlider"

//эта обертка нужна, что бы родитель не тригирил юзэффект и небыло цикла
const TITLE = "Вы недавно просматривали"

export type SliderPropsType = {
  isRun: boolean
}

export const Slider: FC<SliderPropsType> = ({ isRun }) => {
  const {
    products,
    keys: uuids,
    exclude,
  } = useAppSelector((state) => state.profile.history.watchedRecentProducts)
  const dispatch = useAppDispatch()

  useQuery(
    ["products", uuids, isRun],
    () =>
      !!uuids
        ? fetchProductsList({
            uuids: uuids.join(",") || "",
          })
        : null,
    {
      cacheTime: Infinity,
      enabled: !!uuids && uuids.length > 0 && isRun,
      onSuccess: (data) => {
        dispatch(setWatchedRecentProducts(data || []))
      },
    },
  )

  if (products === null) return null
  return (
    <PureSlider
      products={products.filter(({ uuid }) => !exclude.includes(uuid || ""))}
      title={TITLE}
    />
  )
}

Slider.displayName = "ProductsSliderWatchedRecently"
