import { ROUTES } from "../../utils/constants"
import { MobileMenuStaticType } from "./types"

export const MOBILE_MENU_STATIC: Array<MobileMenuStaticType> = [
  { type: "profile", title: "Профиль", path: ROUTES.account },
  {
    type: "orders",
    title: "Заказы",
    path: `${ROUTES.account}${ROUTES.historyOrders}`,
  },
  {
    type: "compare",
    title: "Сравнение",
    path: `${ROUTES.account}${ROUTES.compare}`,
  },
  {
    type: "favorite",
    title: "Избранное",
    path: `${ROUTES.account}${ROUTES.favorites}`,
  },
  { type: "basket", title: "Корзина", path: ROUTES.cart },
]
