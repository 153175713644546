import { FC, memo, SVGProps } from "react";
import { css, CSSProperties } from "@linaria/core";
import { colors, sizeSVG } from "../../../styles/utils/vars";
import { IconInner } from "./Icon";
export type IconNameType = "Copy" | "AngleRight" | "AngleBottom" | "Check" | "Rotate" | "ArrowDown" | "ArrowUp" | "Plus" | "Minus" | "ViewBoxes" | "ViewSchedule" | "Layers" | "Tag" | "StatsNot" | "StatsMany" | "StatsFew" | "Car" | "Star" | "StarOutline" | "ArrowRight" | "ArrowLeft" | "X" | "Info" | "Search" | "ShoppingCart" | "Lock" | "PayApple" | "PayGoogle" | "PayMastercard" | "PayMaestro" | "PayVisa" | "PayMir" | "Phone" | "Chat" | "Email" | "Location" | "Instagram" | "Youtube" | "Facebook" | "Twitter" | "Telegram" | "Pinterest" | "Menu" | "Filter" | "Vk" | "Google" | "User" | "ArrowCircleRight" | "Share" | "Print" | "Delete" | "Transaction" | "Undo" | "CircleLoading" | "AngleLeft" | "Union" | "UserAdd" | "Edit" | "Bolt" | "ListBullet" | "Verified" | "Flag" | "Puzzle" | "Ratio" | "ZoomIn" | "NoImage" | "PlusCircle" | "Wallet" | "CalendarEdit" | "FileRemove" | "NearMe" | "Remove" | "Book" | "PlusSquare" | "Trophy" | "Heart" | "WhatsApp" | "Viber" | "File" | "Download" | "InBrowser" | "Refresh" | "Warning" | "RefreshTime" | "Link" | "CompareAdd" | "CompareActive" | "History" | "Taxi";
export type sizeSVGNamesType = keyof typeof sizeSVG;
export interface IconPropsType {
  NameIcon: IconNameType;
  fill?: string;
  size?: sizeSVGNamesType;
}
export type GetSizeSVGType = (size: typeof sizeSVG[keyof typeof sizeSVG]) => string | number | CSSProperties;
export const getSizeSVG: GetSizeSVGType = size => {
  return {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size
  };
};
export const cssIcon = "cssIcon_cc7xf1o";
export const cssIconShoppingCart = "cssIconShoppingCart_c1bwwamh";
export const Icon: FC<IconPropsType & SVGProps<SVGSVGElement>> = memo(({
  NameIcon,
  fill,
  size = "default",
  className,
  ...props
}) => {
  const classNameCalc = `${cssIcon} ${className ? className : ""} ${size ? `${size}` : ""}`;
  const styles: CSSProperties | undefined = fill ? {
    fill: fill
  } : undefined;
  return <IconInner {...props} NameIcon={NameIcon} className={classNameCalc} style={styles} />;
});
Icon.displayName = "Icon";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");