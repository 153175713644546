import { get } from "../service/fetcher"

const API_KEY = "5VJS6B47PT"
const PRODUCT_SIZE = 10
const REGION_ID = "global"
const SHOW_UNAVAILABLE_SEARCH = true
const SHOW_UNAVAILABLE_AUTOCOMPLETE = false
const WITH_CONTENT = false
const WITH_SKU = false
export const SORT = "DEFAULT"
export const PAGE = 1

export type DigiSortType = "DEFAULT" | "PRICE_ASC" | "PRICE_DESC"

export type DigiStItemType = {
  st: string
  amount: number
}

export type DigiTapItemType = {
  tap: string
  relatedSearch: string
}

export type DigiCategoryTypeAutoComplete = {
  direct: boolean
  id: string
  image_url: string
  link_url: string
  name: string
}

export type DigiAttributeType = Record<string, string[]>

export type DigiProductAutoCompleteType = {
  attributes: DigiAttributeType
  available: boolean
  brand: string
  categories: DigiCategoryTypeAutoComplete[]
  id: string
  image_url: string
  link_url: string
  name: string
  price: string
  score: number
}

export type DigiAutoCompleteResponseType = {
  brands: []
  categories: DigiCategoryTypeAutoComplete[]
  contents: []
  products: DigiProductAutoCompleteType[]
  query: string
  sts: DigiStItemType[]
  taps: DigiTapItemType[]
  correction?: string
}

type DigiAutoCompleteRequestType = {
  query?: string
  size?: number
  regionId?: number | string
  showUnavailable?: boolean
  withContent?: boolean
  withSku?: boolean
}

export type DigiSearchRequestType = Omit<
  DigiAutoCompleteRequestType,
  "withContent"
> & {
  sort?: DigiSortType
  filter?: string
  page?: number
}

export type DigiFacetDataType =
  | "SLIDER"
  | "DISTINCT"
  | "NUM_DISTINCT"
  | "RANGES"
  | "BOOLEAN"

export type DigiFacetValueType = {
  id: string
  name?: string
  value: number
  open: boolean
  selected: boolean
}
export type DigiFacetType = {
  name: string
  dataType: DigiFacetDataType
  unit?: string
  values: DigiFacetValueType[]
}

export type DigiProductSearchType = {
  groupId?: string
  available?: boolean
  score?: number
  ids?: string[]
  id?: string
}

export type DigiSearchResponseType = {
  query: string
  correction?: string
  totalHits: number
  zeroQueries: boolean
  products: DigiProductSearchType[]
  facets?: DigiFacetType[]
  selectedFacets?: DigiFacetType[]
}

export const fetchAutoComplete = ({
  query,
  size = PRODUCT_SIZE,
  regionId = REGION_ID,
  withSku = WITH_SKU,
  withContent = WITH_CONTENT,
  showUnavailable = SHOW_UNAVAILABLE_AUTOCOMPLETE,
}: DigiAutoCompleteRequestType): Promise<DigiAutoCompleteResponseType | null> => {
  return get<DigiAutoCompleteResponseType | null>(
    `https://autocomplete.diginetica.net/autocomplete?st=${
      query || ""
    }&apiKey=${API_KEY}&strategy=vectors_strict,zero_queries&productsSize=${size}&regionId=${regionId}&forIs=false&showUnavailable=${showUnavailable}&withContent=${withContent}&withSku=${withSku}`,
  )
}

export const fetchSearch = ({
  query,
  size = PRODUCT_SIZE,
  regionId = REGION_ID,
  withSku = WITH_SKU,
  showUnavailable = SHOW_UNAVAILABLE_SEARCH,
  sort = SORT,
  filter = "",
  page = PAGE,
}: DigiSearchRequestType): Promise<DigiSearchResponseType> => {
  return get(
    `https://sort.diginetica.net/search?st=${
      query || ""
    }&apiKey=${API_KEY}&strategy=vectors_strict,zero_queries&fullData=false&withCorrection=true&useCompletion=true&withFacets=true&treeFacets=true&regionId=${regionId}&useCategoryPrediction=true&size=${size}&offset=${
      (+page - 1) * size
    }&showUnavailable=${showUnavailable}&unavailableMultiplier=0.2&withSku=${withSku}&sort=${sort}&preview=false&filter=${filter}`,
  )
}
