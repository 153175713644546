import React from "react"
import Script from "next/script"
const isDevelopment = process.env.NODE_ENV === "development"

export const AppScripts = () => {
  return (
    <>
      {!isDevelopment && (
        <>
          <Script
            id={"yandexMetrika"}
            dangerouslySetInnerHTML={{
              __html: `
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(56589850, "init", {
        clickmap:true,
       trackLinks:true,
       accurateTrackBounce:true,
       webvisor:true,
       ecommerce:"dataLayer"
   });`,
            }}
            strategy="lazyOnload"
            async={true}
          />
          <Script
            src="//code.jivo.ru/widget/TpCrW7r4KD"
            id="jivo"
            strategy="lazyOnload"
            async={true}
          />
          <Script
            id={"leadhitCounter"}
            dangerouslySetInnerHTML={{
              __html: `
        var _lh_params = {
        "popup": false
      };
      lh_clid = "${process.env.NEXT_PUBLIC_LEADHIT_ID}";
      (function() {
        var lh = document.createElement('script');
        lh.type = 'text/javascript';
        lh.async = true;
        lh.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'track.leadhit.io/track.js?ver=' + Math.floor(Date.now() / 100000).toString();
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(lh, s);
      })(); 
      `,
            }}
            strategy="lazyOnload"
            async={true}
          />
        </>
      )}
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        id={"recaptcha"}
        strategy="lazyOnload"
        async={true}
      />
      <Script
        src={`//cdn.diginetica.net/2243/client.js?ts=${Date.now()}`}
        id={"diginetica"}
        strategy="lazyOnload"
        async={true}
        dangerouslySetInnerHTML={{
          __html: `var digiScript = document.createElement('script');
                    digiScript.src = '//cdn.diginetica.net/2243/client.js';
                    digiScript.defer = true;
                    digiScript.async = true;
                    document.body.appendChild(digiScript);`,
        }}
      />
    </>
  )
}
