import { FC } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import { useWindowSize } from "../../hooks/windowSize"
import { getBreakpointVal } from "../../styles/utils/Utils"
import { breakpoints } from "../../styles/utils/vars"
import { MOBILE_MENU_STATIC } from "./data"
import { MenuLinkIconI } from "./types"

const FooterBarCard = dynamic(
  (() =>
    import("./FooterBarCard").then(
      (mod) => mod.FooterBarCard,
    )) as DynamicOptions<MenuLinkIconI>,
  {
    ssr: false,
  },
)
const FooterBar: FC = () => {
  const { width } = useWindowSize()
  const isLessLg =
    width !== undefined && width <= getBreakpointVal(breakpoints.lg)

  if (!isLessLg) return null

  return <FooterBarCard data={MOBILE_MENU_STATIC} />
}

export { FooterBar }
