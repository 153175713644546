import { useCallback } from "react"
import { useMutation } from "react-query"
import { fetchPayers } from "../api/accountAPI"
import { setPayers } from "../store/reducers/accountSlice"
import { PayerListItemType } from "../types/types"
import { useAppDispatch, useAppSelector } from "./redux"

type UsePayersType = () => {
  payers: null | PayerListItemType[]
  refetch: () => void
  isFetching: boolean
  clear: () => void
}

export const usePayers: UsePayersType = () => {
  const payers = useAppSelector((state) => state.profile.payers)
  const dispatch = useAppDispatch()

  const { mutate, isLoading } = useMutation(fetchPayers, {
    onSuccess: (response) => {
      if (!!response && response.length > 0) {
        dispatch(setPayers(response))
      } else {
        dispatch(setPayers([]))
      }
    },
  })

  const clear = useCallback(() => {
    dispatch(setPayers(null))
  }, [dispatch])

  return {
    isFetching: isLoading,
    payers: payers,
    refetch: mutate,
    clear: clear,
  }
}
