import { FC, useEffect } from "react"
import { useRouter } from "next/router"
import { useAuth } from "../hooks/auth"

export const AuthGuard: FC = ({ children }) => {
  const { isAuth, isInit } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (isInit) {
      if (!isAuth) {
        void router.push("/")
      }
    }
  }, [isAuth, isInit, router])

  // если пользователь авторизован - показать защищенную страницу
  if (isAuth) {
    return <>{children}</>
  }

  /* в противном случае ничего не возвращать,
  будет выполнено перенаправление из useEffect */
  return null
}
