import {
  StyledLinkActive,
  StyledLinkBase,
  StyledLinkBlackToPurple,
  StyledLinkGrayDarkToBlack,
} from "./StyledLink"

export const enum LinkVariants {
  BLACK_TO_PURPLE = "black-to-purple",
  GRAY_DARK_TO_BLACK = "gray-dark-to-black",
  ACTIVE = "active",
  DEFAULT = "default",
}

export const matching = {
  [LinkVariants.BLACK_TO_PURPLE]: StyledLinkBlackToPurple,
  [LinkVariants.GRAY_DARK_TO_BLACK]: StyledLinkGrayDarkToBlack,
  [LinkVariants.ACTIVE]: StyledLinkActive,
  [LinkVariants.DEFAULT]: StyledLinkBase,
}
