import { FC, useEffect, useState } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import { useCompare } from "../../../../hooks/compare/useCompare"
import { useFavorites } from "../../../../hooks/favorites"
import { Modal } from "../../../ui/Modals/Modal"
import { IOfferToLogInContent } from "./Content"

const OfferToLogInContent = dynamic(
  (() =>
    import("./Content").then(
      (mod) => mod.OfferToLogInContent,
    )) as DynamicOptions<IOfferToLogInContent>,
  {
    ssr: false,
  },
)
const constTxt = {
  favorite: {
    titleContent:
      "Войдите в профиль, чтобы сохранить список. Не сохраненный список будет очищен через 7 дней.",
    title: "Товар добавлен в Избранное",
  },
  compare: {
    titleContent:
      "Войдите в профиль, чтобы иметь возможность добавлять в сравнение еще больше товаров. Не сохраненный список будет очищен через 7 дней.",
    title: "Товар не добавлен в Сравнение",
  },
}

const defaultData = {
  isShowModal: false,
  onClose: () => {
    /* do nothing */
  },
  type: "favorite",
}
const OfferToLogInPopup: FC = () => {
  const { hideToLogin: hideFavorite, isShowToLogin: isFavorite } =
    useFavorites()
  const { hideToLogin: hideCompare, isShowToLogin: isCompare } = useCompare()

  const [state, setState] = useState(defaultData)

  useEffect(() => {
    if (isFavorite) {
      setState({
        isShowModal: isFavorite,
        onClose: hideFavorite,
        type: "favorite",
      })
    } else if (isCompare) {
      setState({
        isShowModal: isCompare,
        onClose: hideCompare,
        type: "compare",
      })
    }
  }, [isFavorite, isCompare, hideFavorite, hideCompare])

  const hideToLoginHandler = () => {
    state.onClose()
    setState(defaultData)
  }

  return (
    <Modal
      title={constTxt[state.type].title}
      isShowModal={state.isShowModal}
      onClose={state.onClose}
      variant={"rounded-0"}
      closeMode={"destroy"}
      //без параметра false окно не откроется
      // во время авторизации и слияния корзин
      isModal={false}
    >
      <OfferToLogInContent
        title={constTxt[state.type].titleContent}
        hideToLogin={hideToLoginHandler}
      />
    </Modal>
  )
}

export { OfferToLogInPopup }
