import {
  AuthRequestUpdatePassword,
  LoginEmailDataRequest,
  LoginPhoneDataRequest,
  RegisterDataRequestEmail,
  RegisterDataRequestPhone,
  RequestResetPassword,
  RequestSendCode,
  ResponseCheckEmail,
  ResponseSendCode,
  ResponseSendCodeResetPassword,
  TokenResponse,
  V1AuthRefreshCreatePayload,
} from "../../contracts/contracts"
import {
  FetcherBasePropsType,
  get,
  getAbsolutePath,
  post,
} from "../service/fetcher"

export const fetchCheckEmail = (email: string): Promise<ResponseCheckEmail> =>
  get<ResponseCheckEmail>(`/auth/email_check?email=${email}`)

export const fetchSendCode = (
  phoneData: RequestSendCode,
): Promise<ResponseSendCode> =>
  post<ResponseSendCode, RequestSendCode>("/auth/send_code", phoneData)

export const fetchRegisterPhone = (
  registerData: RegisterDataRequestPhone,
): Promise<TokenResponse> =>
  post<TokenResponse, RegisterDataRequestPhone>(
    "/auth/register_phone",
    registerData,
  )

export const fetchRegisterEmail = (
  registerData: RegisterDataRequestEmail,
): Promise<TokenResponse> =>
  post<TokenResponse, RegisterDataRequestEmail>(
    "/auth/register_email",
    registerData,
  )

export const fetchLoginPhone = (
  loginData: LoginPhoneDataRequest,
): Promise<TokenResponse> =>
  post<TokenResponse, LoginPhoneDataRequest>("/auth/login_phone", loginData)

export const fetchLoginEmail = (
  loginData: LoginEmailDataRequest,
): Promise<TokenResponse> =>
  post<TokenResponse, LoginEmailDataRequest>("/auth/login_email", loginData)

export const fetchResetPassword = (
  data: RequestResetPassword,
): Promise<ResponseSendCodeResetPassword> => post("/auth/reset_password", data)

export const fetchUpdatePassword = (
  data: AuthRequestUpdatePassword,
): Promise<TokenResponse> => post("/auth/update_password", data)

export const fetchRefreshToken = ({
  res,
  req,
  server,
  ...data
}: V1AuthRefreshCreatePayload & FetcherBasePropsType): Promise<TokenResponse> =>
  post(
    `${getAbsolutePath(server)}/auth/refresh`,
    data,
    false,
    "refresh",
    req,
    res,
  )

export const fetchIp = (): Promise<{ ip: string }> => get("/auth/ip")
