import { FC } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import { useCart } from "../../../hooks/cart/cart"
import { BaseLoader } from "../../ui/Loaders/BaseLoader/BaseLoader"
import { Modal } from "../../ui/Modals/Modal"

const CartBindContent = dynamic(
  (() =>
    import("./Content").then((mod) => mod.CartBindContent)) as DynamicOptions,
  {
    ssr: false,
  },
)

const CartBindPopup: FC = () => {
  const {
    cleanMergeData,
    merge: { isShow, isFetching },
  } = useCart()

  return (
    <>
      {isFetching && <BaseLoader isFixed />}
      <Modal
        closeMode={"destroy"}
        isShowModal={isShow}
        variant={"rounded-70"}
        onClose={() => {
          cleanMergeData()
        }}
        //без параметра false окно не откроется
        // во время авторизации и слияния корзин
        isModal={false}
      >
        <CartBindContent />
      </Modal>
    </>
  )
}

export { CartBindPopup }
