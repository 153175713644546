import { IncomingMessage } from "http";
import Cookies from "universal-cookie";
import { ApiError, BannerApiType, Category, CategoryByArea, ParamsResponse, ProductDetailListType, RequestBanner, SortTypeResponse } from "../../contracts/contracts";
import { fetchCatalog } from "../api/catalogAPI";
import { DaDataLocationDataType, fetchIpLocate } from "../api/dadataAPI";
import { ProductPrintType } from "../components/Cart/Print/types";
import { CheckoutFormFieldsType } from "../components/Checkout/Checkout";
import { ProductType, ProductWithChildType } from "../components/Products/types";
import { getClientUser, setUser } from "../hooks/auth";
import { setTokenStorage } from "../hooks/cart/helpers";
import { DEFAULT_UNIT_MEASURE } from "../hooks/product/helpers";
import { CompareType } from "../store/reducers/accountSlice";
import { cssNoScroll } from "../styles/utils/Utils";
import { BannerStringTypes, CategoriesTreeStateType, GetOrderFilterSortableType, IAvailableParam, IAvailableParams, IAvailableParamsKeys, ICategoryTreeItem, IFilterParamChild, IFilterParamParent, IFilterParamsChilds, IFilterParamsParents, LocationExtendsType, LocationType, NavItemType, ProductOfferType, PropertiesType, SequenceItemType, SortByAliasType, SortByType, SortType, SpecificationItemType, StoreBannersType } from "../types/types";
import { COOKIE_LOCATION_NAME, DATE_STORE, MONTHS_DECLINATION, ROUTES } from "./constants";
export const SAMPLE_QUANTITY_LIMIT = 2;
const cookies = new Cookies();
export const priceToFormat = (price: number): string => `${(price / 100).toFixed(2)}`;
export const createCategoriesTree = (categories: Category[]): CategoriesTreeStateType => {
  if (!categories) {
    return {
      treeCompared: null,
      treeSorted: []
    };
  }
  const _categories = [...categories];
  const categoriesTree = ({} as Record<string, ICategoryTreeItem>);
  const compareChildMain = ({} as Record<string, number>);
  const treeCompared = ({} as Record<string, SequenceItemType[]>);
  const parentTree = (current: ICategoryTreeItem, parentCategory: ICategoryTreeItem) => {
    if (Object.keys(parentCategory["children"]).some(key => +key === current.parent)) {
      if (!!current.parent && !!current.id) {
        const maked = {
          ...current,
          uuidsSequence: [...parentCategory["children"][current.parent].uuidsSequence, {
            uuid: parentCategory["children"][current.parent].uuid,
            id: parentCategory["children"][current.parent].id
          }]
        };
        parentCategory["children"][current.parent]["children"][current.id] = maked;
        treeCompared[maked.uuid || ""] = maked.uuidsSequence;
      }
      return;
    }
    for (const key of Object.keys(parentCategory["children"])) {
      parentTree(current, {
        ...parentCategory["children"][key],
        uuidsSequence: [{
          uuid: parentCategory.uuid,
          id: parentCategory.id
        }]
      });
    }
  };
  const categoriesParentFirst = _categories.sort((a, b) => {
    if ((a?.parent || 0) > (b?.parent || 0)) {
      return 1;
    }
    if ((a?.parent || 0) < (b?.parent || 0)) {
      return -1;
    }
    // a должно быть равным b
    return 0;
  });
  for (const cat of categoriesParentFirst) {
    const category = ({
      ...cat,
      children: {},
      uuidsSequence: []
    } as ICategoryTreeItem);
    if (category.id === undefined || category.parent === undefined) {
      continue;
    }
    if (category.parent === 0) {
      categoriesTree[category.id] = category;
      treeCompared[category.uuid || ""] = [];
      continue;
    }

    // если родитель категории - это главная категория
    // записываем сопоставление для id текущей категории - id родителя
    if (compareChildMain[category.parent] === undefined) {
      compareChildMain[category.id] = category.parent;

      //дополнительная проверка
      if (categoriesTree[category.parent] === undefined) {
        categoriesTree[category.parent] = {
          ...categoriesTree[category.parent],
          children: {}
        };
      }

      // записываем в главную категорию ее первый уровень дочерних категорий
      const maked = {
        ...category,
        uuidsSequence: [{
          uuid: categoriesTree[category.parent].uuid,
          id: categoriesTree[category.parent].id
        }]
      };
      categoriesTree[category.parent].children[category.id] = maked;
      treeCompared[maked.uuid || ""] = maked.uuidsSequence;
    } else {
      compareChildMain[category.id] = compareChildMain[category.parent];
      parentTree(category, {
        ...categoriesTree[compareChildMain[category.id]]
      });
    }
  }
  const treeSorted = Object.entries(categoriesTree).sort(([, catA], [, catB]) => {
    const nameA = catA.name || "";
    const nameB = catB.name || "";
    return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
  }).sort(([, catA], [, catB]) => {
    const weightA = catA.weight || "";
    const weightB = catB.weight || "";
    return weightA > weightB ? 1 : weightA < weightB ? -1 : 0;
  }).map(([, category]) => category);
  return {
    treeCompared: treeCompared,
    treeSorted: treeSorted
  };
};
export const compareSortTypesWithIcon = (sortTypes: SortTypeResponse): SortByType => {
  const sorts: SortByType = {};
  for (const item of sortTypes) {
    if (!!item.alias && !!item.name) {
      const sort: SortType = {
        value: item.alias,
        name: item.name
      };
      switch ((item.alias as SortByAliasType)) {
        case "price_asc":
          sort.icon = "ArrowUp";
          break;
        case "price_desc":
          sort.icon = "ArrowDown";
          break;
      }
      if (!!sort) {
        sorts[sort.value] = {
          ...sort
        };
      }
    }
  }
  return Object.keys(sorts).length > 0 ? sorts : null;
};
export const timeToDate = (time: string): Date | null => {
  const parsedTime = time.split(":");
  if (parsedTime.length !== 2) {
    return null;
  }
  const dHour = +parsedTime[0];
  const dMinute = +parsedTime[1];
  const date = new Date();
  date.setHours(dHour);
  date.setMinutes(dMinute);
  return date;
};
type DateToStringType = (selectedDay: Date, withYear?: boolean) => string;
export const dateToString: DateToStringType = (selectedDay, withYear = true): string => {
  const normalizeDate = new Date(selectedDay);
  return `${normalizeDate.getDate()}\u00A0${MONTHS_DECLINATION["ru"][normalizeDate.getMonth()]} ${withYear ? normalizeDate.getFullYear() : ""}`;
};
export const shippingDateToString = (date: Date, messages?: {
  today?: string;
  tomorrow?: string;
  other?: (day: string) => string;
}): string => {
  let str: string;
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  switch (date.toISOString().split("T")[0]) {
    case new Date().toISOString().split("T")[0]:
      {
        str = messages?.today !== undefined ? messages.today : "Доставка сегодня";
        break;
      }
    case tomorrow.toISOString().split("T")[0]:
      {
        str = !!messages?.tomorrow ? messages.tomorrow : "Доставка завтра";
        break;
      }
    default:
      {
        str = messages?.other !== undefined ? messages.other(`${dateToString(date, false)}`) : `Доставка к\u00A0${dateToString(date, false)}`;
      }
  }
  return str;
};
export const onErrorFetcherCart = (error: ApiError): void => {
  if (error.status === 404) {
    const user = getClientUser();
    if (user !== null) {
      setUser({
        ...user,
        cart: null
      });
    }
    setTokenStorage(null);
    location.reload();
  }
};
export const getFilterParamsTree = (payload: ParamsResponse | null): IFilterParamsParents & IFilterParamsChilds => {
  if (payload === null) {
    return {};
  }
  const paramsAllTree = ({} as IFilterParamsParents & IFilterParamsChilds);
  for (const item of payload) {
    if (!item?.uuid) {
      continue;
    }
    const newItem = {
      ...item
    };
    delete newItem.values;
    paramsAllTree[item.uuid.toString()] = {
      ...newItem
    };
    if (item.values !== undefined) {
      for (const val of item.values) {
        if (!!val?.uuid) {
          paramsAllTree[val.uuid.toString()] = {
            ...val,
            checked: false,
            parentUuid: item.uuid.toString()
          };
        }
      }
    }
  }
  return paramsAllTree;
};
export const getAvailableParamsTree = (stateParams: IFilterParamsParents & IFilterParamsChilds, payload: IAvailableParamsKeys | null): {
  tree: IAvailableParams | null;
} => {
  if (payload === null) {
    return {
      tree: null
    };
  }
  const tree: IAvailableParams = ({} as IAvailableParams);
  Object.keys(payload).map(key => {
    const child: IFilterParamChild = {
      ...(stateParams[key] as IFilterParamChild),
      product_qty: payload[key]
    };
    if (!!child.parentUuid) {
      const parent: IFilterParamParent = (stateParams[child.parentUuid || ""] as IFilterParamParent);

      //create parent param
      const availableParam: IAvailableParam = tree[parent.uuid || ""];
      const checkedKeys = !!availableParam ? availableParam.checkedKeys : ([] as string[]);
      tree[parent.uuid || ""] = {
        ...parent,
        params: !!availableParam ? availableParam.params : ({} as IFilterParamsParents),
        checkedKeys: checkedKeys
      };

      //create child values in parent param
      tree[parent.uuid || ""].params[child.uuid || ""] = {
        ...child
      };
    }
  });
  return {
    tree: Object.keys(tree).length > 0 ? tree : null
  };
};
export const normalizeCategoriesByAreas = (areas: CategoryByArea[], categories: Record<string, Category>): Category[] => {
  const fetchedBusinessAreas = ([] as Category[]);
  const normalize = (areas: CategoryByArea[], categories: Record<string, Category>) => {
    areas.map(cat => {
      if (cat.product_qty !== undefined) {
        fetchedBusinessAreas.push({
          ...categories[cat.uuid || ""],
          product_qty: cat.product_qty || 0
        });
        if (!!cat.children && cat.children.length > 0) {
          normalize(cat.children, categories);
        }
      }
    });
  };
  normalize(areas, categories);
  return fetchedBusinessAreas;
};
export const compareSpecificationProducts = (specification: Record<string, SpecificationItemType> | null, datasource: Record<string, ProductWithChildType> | null): {
  products: Record<string, ProductWithChildType> | null;
  samples: Record<string, ProductWithChildType> | null;
} => {
  // полученные products которые есть в корзине
  // разделить по виду Товары или Образцы
  // пример: обрзец товара может быть в корзине - товара при этом может не быть

  const product: Record<string, ProductWithChildType> = {};
  const sample: Record<string, ProductWithChildType> = {};
  if (specification === null || datasource === null) {
    return {
      products: null,
      samples: null
    };
  }
  for (const [sKey, itemSpec] of Object.entries(specification)) {
    //если есть такой товар - сохранили
    if (!datasource[sKey]) {
      continue;
    }
    if (!!itemSpec?.quantity) {
      product[sKey] = {
        ...datasource[sKey]
      };
    }

    // если кол-во sample больше 0, т.е. ОБРАЗЕЦ в корзине
    if (!!itemSpec?.sample || !!itemSpec?.isSampleRemoved) {
      //если есть такой товар - сохранили
      sample[specification[sKey].uuid || ""] = datasource[sKey];
    }

    //если спецификация это комплект, сохраняем дочерние
    if (!!itemSpec.child) {
      for (const [sChildKey, specChild] of Object.entries(itemSpec.child)) {
        // если есть такой товар
        if (!datasource[sChildKey]) {
          continue;
        }
        if (!product[sKey]) {
          continue;
        }

        // сохранили как дочерний комплекта
        product[sKey].child = {
          ...product[sKey].child,
          ...{
            [sChildKey]: datasource[sChildKey]
          }
        };

        // если у дочернего есть образец - также сохранили
        // у составляющих комплекта тоже могут быть образцы
        // т.к. это реальные, обыкновенные товары
        if (!!specChild?.sample) {
          sample[sChildKey] = datasource[sChildKey];
        }
      }
    }
  }
  return {
    products: Object.keys(product).length > 0 ? product : null,
    samples: Object.keys(sample).length > 0 ? sample : null
  };
};
export const normalizeQuery = (query: Record<string, string | number | undefined>): Record<string, string | number> => {
  const q = {
    ...query
  };
  Object.keys(q).map(key => {
    if (q[key] === null || q[key] === undefined || (q[key] as string)?.length <= 0) {
      delete q[key];
    }
  });
  return (q as Record<string, string | number>);
};

// 1 - слайдер главная
// * 2 - одиночный главная
// * 3 - одиночный в списке товаров
// * 4 - одиночный в каталоге под товарами
export const getBannerStringType = ({
  type
}: Pick<BannerApiType, "type">): BannerStringTypes | undefined => {
  switch (type) {
    case 1:
      {
        return "main_slider";
      }
    case 2:
      {
        return "main_single";
      }
    case 3:
      {
        return "catalog_products_single";
      }
    case 4:
      {
        return "catalog_under_products_single";
      }
    default:
      {
        return undefined;
      }
  }
};
export const scrollBodyDisable = (targetElement?: HTMLElement | Element): void => {
  console.log("targetEl ", targetElement);
  document.body.classList.add(cssNoScroll);
};
export const scrollBodyEnable = (): void => {
  document.body.classList.remove(cssNoScroll);
};
export const setLocationCookie = (location: LocationType | null): void => {
  if (!!location) {
    cookies.set(COOKIE_LOCATION_NAME, JSON.stringify(location), {
      path: "/"
    });
  } else {
    cookies.remove(COOKIE_LOCATION_NAME);
  }
};
export const getLocationCookie = (): LocationType | undefined => {
  return cookies.get(COOKIE_LOCATION_NAME);
};
export const getLocationFormat = (location: LocationType | null): LocationExtendsType | null => {
  return location !== null ? {
    ...location,
    city_full: [location.city_type, location.city].filter(i => i !== null).join(" "),
    region_full: [location.region_type, location.region].filter(i => i !== null).join(" ")
  } : null;
};
export const getLocationRegion = (location: LocationExtendsType | null): string | null => {
  return location !== null ? [location?.city_full, location?.region_full].filter(item => !!item).join(",") : null;
};
export const getFormatResponseLocation = (payload: DaDataLocationDataType): LocationType => {
  return {
    region_type: payload?.region_type || null,
    region: payload?.region || null,
    city_type: payload?.city_type || null,
    city: payload?.city || null,
    region_kladr_id: payload?.region_kladr_id || null
  };
};
export const isCurrentYear = (date: Date | string): boolean => new Date(date).getFullYear() === new Date().getFullYear();
export const getTranslationProperties = (properties: PropertiesType): {
  name: string;
  value: string;
}[] => {
  const PROPERTIES_RU = {
    length: "длина",
    width: "ширина",
    height: "высота",
    size: "размер",
    weight: "вес"
  };
  return properties.filter(prop => !!prop.value && !!prop.name).map(p => {
    return {
      value: p.value || "",
      name: PROPERTIES_RU[p.name || ""] !== undefined ? PROPERTIES_RU[p.name || ""] : p.name || ""
    };
  });
};
export const CHECKOUT_SAVED_TEMP_KEY = "checkoutSaved";
export type LocalStorageCheckoutType = CheckoutFormFieldsType & {
  location: LocationType | null;
};
export const setCheckoutToStorage = (data: Omit<LocalStorageCheckoutType, "shipping_date" | "comment" | "dataPrivacyAgreement"> | null): void => {
  if (typeof localStorage === "undefined" || !localStorage) {
    return;
  }
  if (data === null) {
    localStorage.removeItem(CHECKOUT_SAVED_TEMP_KEY);
  } else {
    localStorage.setItem(CHECKOUT_SAVED_TEMP_KEY, JSON.stringify(data));
  }
};
export const getCheckoutFromStorage = (): LocalStorageCheckoutType | null => {
  if (typeof localStorage === "undefined" || !localStorage) {
    return null;
  }
  const data = localStorage.getItem(CHECKOUT_SAVED_TEMP_KEY);
  return !!data ? JSON.parse(data) : null;
};
export const dateToISOString = (date: string | Date): string => {
  return new Date(date).toISOString().split("T")[0];
};
export const getDateToStore = (date: string | Date | null): typeof DATE_STORE => {
  if (date === null) {
    return {
      iso: null
    };
  }
  const d = new Date(date);
  return {
    iso: dateToISOString(d)
  };
};
export const declinationOfNum = (number: number, words: string[]): string => words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]];
export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};
export const getImagePath = (path: string): string => {
  return path.includes("http") ? path : `https://${path}`;
};
export const getNumberOfDays = (start: string | Date, end: string | Date): number => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  return Math.round(diffInTime / oneDay);
};
export const getExpireOneYear = (): Date => new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365);
export const normalizeValueToSort = (str?: string): string | number => {
  const strInit = str || "";
  if (str === undefined) {
    return strInit;
  } else {
    const digitals = strInit.match(/\d+/g) || [];
    if (digitals.length === 0) {
      return strInit;
    } else {
      if (digitals.length >= 1) {
        // если число дробное, приходит через "," получаем такое число в формате number
        if (strInit.includes(",") && digitals.length >= 2) {
          return +`${digitals[0]}.${digitals[1]}`;
        } else {
          return +`${digitals[0]}`;
        }
      }
    }
  }
  return strInit.trim();
};
export const setLeadHitStockId = (id: string | null): void => {
  const KEY_NAME = "_lh_stock_id";
  if (id !== null) {
    localStorage.setItem(KEY_NAME, id);
  } else {
    localStorage.removeItem(KEY_NAME);
  }
};
export type RuleSortProduct = {
  uuid?: string;
  weight?: number;
};
export const filterProductsByCategory = ({
  products,
  category
}: {
  products: ProductDetailListType;
  category: string;
}): ProductDetailListType => {
  return products.filter(p => (p.categories || []).includes(category));
};
export const sortProductsWeightRule = ({
  products,
  rules
}: {
  products: ProductDetailListType;
  rules?: RuleSortProduct[];
}): ProductDetailListType => {
  const weightRule = rules || [];
  const _getCurrRule = (uuid?: string): RuleSortProduct | undefined => weightRule.find(w => w.uuid === uuid);
  return [...products].sort((a, b) => {
    const weightA = _getCurrRule(a.uuid)?.weight;
    const weightB = _getCurrRule(b.uuid)?.weight;
    if (weightA !== undefined && weightB !== undefined) {
      return weightA > weightB ? 1 : weightA < weightB ? -1 : 0;
    } else {
      return 0;
    }
  }).sort((a, b) => {
    return !!a.total_qty === !!b.total_qty ? 0 : !!a.total_qty ? -1 : 1;
  });
};
export const getIp = (req: IncomingMessage | undefined): string | undefined => {
  const forwarded = req?.headers["x-forwarded-for"];
  let ip = typeof forwarded === "string" ? forwarded.split(/, /)[0] : (req?.headers["x-real-ip"] as string | undefined);
  if (!ip) {
    ip = req?.socket?.remoteAddress;
  }
  return ip;
};
export const getIpCookieEntry = async (req: IncomingMessage | undefined, ip: string): Promise<string | undefined> => {
  const cookies = req?.headers.cookie;
  if (cookies === undefined) {
    return undefined;
  }
  if (cookies.search(COOKIE_LOCATION_NAME) !== -1) {
    return undefined;
  }
  const result = await fetchIpLocate(ip);
  let format: string | undefined;
  if (!!result && !!result.location) {
    format = JSON.stringify(getFormatResponseLocation(result.location.data));
  }
  if (!!format) {
    return `${COOKIE_LOCATION_NAME}=${encodeURIComponent(format)}; Path=/; Expires=${getExpireOneYear().toUTCString()}`;
  }
  return undefined;
};
export const toSpecialChars = (string: string): string => {
  return string.replaceAll(/&/g, "&amp;").replaceAll(/>/g, "&gt;").replaceAll(/</g, "&lt;").replaceAll(/"/g, "&quot;").trim();
};
export async function getProductsSite<T = ProductOfferType>(categoryIds: string[], formattedMehod: (p: ProductType) => T): Promise<T[]> {
  let page = 1;
  let products: T[] = [];
  let totalPages = 0;
  const PER_PAGE = 500;
  const getFetcherCatalog = (page: number) => fetchCatalog({
    server: true,
    categories: categoryIds,
    perPage: PER_PAGE,
    page: page,
    isEnabled: "0",
    isSearch: true
  });
  const firstData = await getFetcherCatalog(page);
  if (!!firstData) {
    totalPages = !!firstData.total ? Math.ceil(firstData.total / PER_PAGE) : 0;
    if (!!firstData.products && firstData.products.length > 0) {
      products = [...products, ...firstData.products.map(p => {
        return formattedMehod(p);
      })];
    }
    if (totalPages > 0) {
      while (page < totalPages) {
        page++;
        const result = await getFetcherCatalog(page);
        if (!!result && !!result.products) {
          products = [...products, ...result.products.map(p => {
            return formattedMehod(p);
          })];
        } else {
          break;
        }
      }
    }
  }
  return products;
}
export const makeAvailableFilters = ({
  queryParams,
  catalogParams,
  stateParams
}: {
  catalogParams: Record<string, number>;
  queryParams: string[];
  stateParams: IFilterParamsParents & IFilterParamsChilds;
}) => {
  const maked: IAvailableParams = {};
  for (const [uuid, quantity] of Object.entries(catalogParams)) {
    const uuidChild = (uuid || "").toString();
    const availableParam = stateParams[uuidChild];
    if (!availableParam) {
      continue;
    }
    if (!availableParam.parentUuid) {
      continue;
    }
    const {
      parentUuid: parentUuidChild,
      name: nameChild
    } = availableParam;

    // если ее не запоминали такого фильтра (пр. цвет / размер)
    // сохраняем впервые для наполнения значениями
    if (!maked[parentUuidChild]) {
      const {
        uuid: uuidParent,
        name: nameParent,
        order: orderParent
      } = stateParams[parentUuidChild];
      maked[parentUuidChild] = {
        params: {},
        checkedKeys: [],
        uuid: uuidParent,
        name: nameParent,
        order: orderParent
      };
    }

    // если такой id есть в url - значит он выбран и нужно активировать
    const isChecked = queryParams.includes(uuidChild);
    if (isChecked) {
      const {
        checkedKeys
      } = maked[parentUuidChild];
      maked[parentUuidChild].checkedKeys = [...checkedKeys, uuidChild];
    }

    // сохраняем значение (пр. красный / желтый)
    maked[parentUuidChild].params[uuidChild] = {
      uuid: uuidChild,
      name: nameChild,
      product_qty: quantity,
      checked: isChecked
    };
  }
  return maked;
};
export const makeBanners = (payload: RequestBanner[]) => {
  const banners = ({} as StoreBannersType);
  for (const item of payload) {
    const typeString = getBannerStringType({
      type: item.type
    });
    if (!!typeString) {
      if (banners[typeString] === undefined) {
        banners[typeString] = [];
      }
      banners[typeString].push(item);
    }
  }
  return Object.keys(banners).length > 0 ? banners : null;
};
export const getCategoryUuidsChild = (category: ICategoryTreeItem): string[] => {
  const uuids: string[] = [];
  const _process = (category: ICategoryTreeItem) => {
    if (Object.keys(category.children || {}).length <= 0) {
      return;
    }
    for (const key of Object.keys(category.children)) {
      const id = category.children[key]?.uuid;
      if (id !== undefined) {
        uuids.push(id);
        _process(category.children[key]);
      }
    }
  };
  _process(category);
  return uuids;
};
export const getSubItemsBreadcrumbs = (treeSlice: ICategoryTreeItem | null): NavItemType[] => treeSlice !== null ? Object.entries(treeSlice.children || {}).map(([, {
  uuid,
  alias,
  name
}]) => ({
  id: uuid,
  path: `${ROUTES.catalog}/${alias || ""}`,
  title: `${name}`
})) : [];
export const getOrderSortAsc = (a: number | string, b: number | string) => a > b ? 1 : a < b ? -1 : 0;
export const getOrderSortDesc = (a: number | string, b: number | string) => a > b ? -1 : a < b ? 1 : 0;
const getNameFilterSortable = (str?: string): number[] => {
  // const converted = (str || "").replace(/[^0-9]/g, "")
  // return converted.length > 0 ? +converted : (str || "").toLowerCase()
  const reg = new RegExp(/[\d\.]+/g);
  const numArr = (str || "").replaceAll(",", ".").match(reg);
  return !!numArr ? numArr.filter(n => n !== ".").map(n => +n) : [];
};
export const getOrderFilterSortable: GetOrderFilterSortableType = (a = "", b = "", sort = "asc") => {
  /*
   * name может быть след. видов:
   * 30 х 40
   * 20,5 х 24,5
   * 20,5 х 24,5 x 6,5
   * 6,7
   * 7
   * AB
   * Красный
   * Сортировка должна быть по возрастанию
   * */

  const sortMethod = sort === "asc" ? getOrderSortAsc : getOrderSortDesc;
  const namesA = getNameFilterSortable(a);
  const namesB = getNameFilterSortable(b);
  const lenA = namesA.length;
  const lenB = namesB.length;
  let order = 0;

  // если в фильтрах нет чисел хотя бы у одного
  if (lenA === 0 || lenB === 0) {
    return sortMethod(a, b);
  }

  // если сравниваемые фильтры содержат несколько групп чисел
  // и потенциально первое сравниваемое может быть одинаковым
  if (lenA === lenB && lenA > 1) {
    let i = 0;
    while (i < lenA) {
      order = sortMethod(namesA[i], namesB[i]);
      if (order !== 0) {
        break;
      }
      i++;
    }
  } else {
    order = sortMethod(namesA[0], namesB[0]);
  }
  return order;
};
export const numberWithThousands = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
type GetPricePDFFnType = (payload: {
  price: number;
  withCurrency?: boolean;
}) => string;
export const getPricePDF: GetPricePDFFnType = ({
  price,
  withCurrency = true
}) => {
  const currency = withCurrency ? "руб" : "";
  return `${numberWithThousands(priceToFormat(price))}${currency ? ` ${currency}` : ""}`;
};
export const configPrintProducts = ({
  products,
  specification
}: {
  specification: Record<string, SpecificationItemType> | null;
  products: Record<string, ProductWithChildType> | null;
}): ProductPrintType[] => {
  const p: ProductPrintType[] = [];
  if (!specification || !products) {
    return [];
  }
  for (const [uuid, {
    images = [],
    name = "",
    code = "",
    alias = "",
    price = 0,
    unit = DEFAULT_UNIT_MEASURE
  }] of Object.entries(products)) {
    const currSpec = specification[uuid];
    if (!currSpec) {
      continue;
    }
    const {
      quantity = 0,
      isRemoved = false
    } = currSpec;
    if (isRemoved) {
      continue;
    }
    p.push({
      uuid: uuid,
      code: code,
      price: price,
      cost: price * quantity,
      quantity: quantity,
      name: name,
      image: images[0] || "",
      alias: alias,
      unit: unit,
      quantityFormatted: `${quantity} ${unit}`
    });
  }
  return p;
};
export const configPrintSamples = ({
  samples,
  specification
}: {
  specification: Record<string, SpecificationItemType> | null;
  samples: Record<string, ProductWithChildType> | null;
}): ProductPrintType[] => {
  const s: ProductPrintType[] = [];
  if (!specification || !samples) {
    return [];
  }
  for (const [uuid, {
    images = [],
    name = "",
    code = "",
    alias = "",
    price = 0,
    unit = DEFAULT_UNIT_MEASURE
  }] of Object.entries(samples)) {
    const currSpec = specification[uuid];
    if (!currSpec) {
      continue;
    }
    const {
      sample = 0,
      isSampleRemoved = false
    } = currSpec;
    if (isSampleRemoved) {
      continue;
    }
    s.push({
      uuid: uuid,
      code: code,
      price: price,
      cost: price * sample,
      quantity: sample,
      name: name,
      image: images[0] || "",
      alias: alias,
      unit: unit,
      quantityFormatted: `${sample} ${unit}`
    });
  }
  return s;
};
type MathKeysFnType = (payload: {
  stateKeys: null | string[];
  newKeys: string[];
  limit?: number;
}) => string[];
export const matchKeys: MathKeysFnType = ({
  stateKeys,
  newKeys,
  limit
}) => {
  if (stateKeys === null) {
    return newKeys;
  }
  const keysMatching: string[] = [...newKeys, ...stateKeys].reduce((uniq: string[], item) => uniq.includes(item) ? uniq : [...uniq, item], []);
  let out = !keysMatching.length ? [] : keysMatching;
  if (limit !== undefined) {
    out = out.slice(0, limit);
  }
  return out;
};
export function getAllProductsID(productsArray: Array<CompareType>): string[] {
  return productsArray.reduce((allProducts: string[], productObject: CompareType) => {
    return allProducts.concat(productObject.products);
  }, []);
}
export const sortProductsFromApi = (products: ProductType[], uuidsSorting: string[] | null) => !uuidsSorting ? products : (uuidsSorting.map(uuidSorted => products.find(({
  uuid = ""
}) => uuid === uuidSorted)).filter(p => !!p) as ProductType[]);