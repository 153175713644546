import type { FC } from "react";
import { memo } from "react";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import { cssListVertical, StyledList } from "../../List/StyledList";
import { LinkVariants } from "../Link/constants";
import { NavigationItem } from "./NavigationItem";
import { StyledNav, Title } from "./StyledNavigation";
import { NavigationPropsType } from "./types";
const Navigation: FC<NavigationPropsType> = memo(({
  title,
  items,
  variant = LinkVariants.BLACK_TO_PURPLE,
  activePath,
  orientation = "horizontal",
  isScroll
}) => {
  const router = useRouter();
  return <StyledNav>
        {!!title && <Title>{title}</Title>}
        <StyledList className={cx(orientation === "vertical" && cssListVertical)}>
          {items.map((item, index) => {
        return <NavigationItem variant={variant} key={index} {...item} isScroll={isScroll || item.isScroll} activePath={activePath} currentPath={router.route} />;
      })}
        </StyledList>
      </StyledNav>;
});
Navigation.displayName = "Navigation";
export default Navigation;