const FACET_CATEGORIES_KEY = "categories"
const SEARCH_PER_PAGE = 20
const HISTORY_SEARCH_KEY = "searchHistory"
const TRANSLATE_NAME_FACET = {
  price: "Цена",
  brands: "Бренды",
  FACET_CATEGORIES_KEY: "Категории",
}
const PRODUCT_FETCH_SIZE_PC = 4
const PRODUCT_FETCH_SIZE_MOBILE = 2

export {
  FACET_CATEGORIES_KEY,
  SEARCH_PER_PAGE,
  HISTORY_SEARCH_KEY,
  TRANSLATE_NAME_FACET,
  PRODUCT_FETCH_SIZE_PC,
  PRODUCT_FETCH_SIZE_MOBILE,
}
