import { BaseHTMLAttributes, forwardRef, HTMLAttributeAnchorTarget, useMemo } from "react";
import NextLink from "next/link";
import { cx } from "@linaria/core";
import { cssButtonClean } from "../../components/ui/Button/StyledButton";
import { BaseHTMLButtonType, ButtonWrapperPropsType } from "../../components/ui/Button/types";
import { Icon } from "../../components/ui/Icon";
import { ComponentLoader } from "../../components/ui/Loaders/ComponentLoader/ComponentLoader";
import { Typography } from "../../components/ui/Typography/Typography";
import { cssSeoText } from "../../styles/utils/Utils";
const ButtonWithLink = forwardRef<HTMLButtonElement, ButtonWrapperPropsType & BaseHTMLAttributes<HTMLButtonElement> & Pick<BaseHTMLAttributes<HTMLAttributeAnchorTarget>, "target">>(({
  ...props
}, ref) => {
  const {
    as,
    href
  } = props;
  const isLink = useMemo(() => as === "a" && href !== undefined, [as, href]);
  return <>
      {isLink ? <NextLink href={href || ""} passHref>
          <ButtonInner {...props} ref={ref} />
        </NextLink> : <ButtonInner {...props} ref={ref} />}
    </>;
});
const ButtonInner = forwardRef<HTMLButtonElement, ButtonWrapperPropsType & BaseHTMLButtonType>(({
  ButtonComponent,
  icon,
  iconPosition,
  fillIcon,
  children,
  size = "medium",
  hideTextOnBreakpoint,
  isFetching,
  withArea,
  isHiddenBg,
  withOutOffset,
  seoText,
  ...props
}, ref) => {
  const isVisibleWrapper = !!icon && !!children;
  return <ButtonComponent {...props} ref={ref} data-icon-position={iconPosition} data-size={size} data-text-hide-breakpoints={hideTextOnBreakpoint} data-is-fetched={isFetching || undefined} data-with-area={withArea} data-hidden-bg={isHiddenBg} data-is-offset={withOutOffset} className={cx(props.className, props.isClean && cssButtonClean)}>
        {isFetching && <ComponentLoader />}
        {!!icon && <Icon NameIcon={icon} fill={fillIcon} />}
        {isVisibleWrapper ? <Typography variant={"span"}>{children}</Typography> : children}
        {!!seoText && <span className={cssSeoText}>{seoText}</span>}
      </ButtonComponent>;
});
ButtonInner.displayName = "ButtonInner";
ButtonWithLink.displayName = "ButtonWithLink";
export { ButtonWithLink };