import {
  CategoryByAreaResponse,
  CategoryResponse,
  ParamsResponse,
  ProductCatalogType,
  SortTypeResponse,
  Tag,
  TagResponse,
} from "../../contracts/contracts"
import { FetcherBasePropsType, get, getAbsolutePath } from "../service/fetcher"
import { CatalogResponseType, SortByAliasType } from "../types/types"

export const fetchCategories = (
  data?: FetcherBasePropsType,
): Promise<CategoryResponse> =>
  get<CategoryResponse>(`${getAbsolutePath(data?.server)}/catalog/categories`)

export const fetchTags = (data?: FetcherBasePropsType): Promise<TagResponse> =>
  get<TagResponse>(`${getAbsolutePath(data?.server)}/catalog/tags`)

export const fetchTagById = (
  id: number,
  data?: FetcherBasePropsType,
): Promise<Tag> =>
  get<Tag>(`${getAbsolutePath(data?.server)}/catalog/tags/${id}`)

export const fetchCategoriesByBusinessArea = (
  uuidArea: string | null,
): Promise<CategoryByAreaResponse> =>
  get<CategoryByAreaResponse>(
    `/catalog/bussiness-area${!!uuidArea ? `/${uuidArea}` : ""}`,
  )

type CatalogQueryType = {
  categories?: string[]
  filters?: string[]
  priceRange?: string
  isEnabled?: "0" | "1"
  isFast?: "0" | "1"
  sortBy?: SortByAliasType
  minQuantity?: string
  page?: number
  perPage?: number
  isBestseller?: boolean
  isSearch?: boolean
  store?: string[]
  isNew?: boolean
}

export const fetchCatalog = ({
  categories,
  filters,
  priceRange,
  isEnabled = "1",
  isFast = "0",
  sortBy,
  minQuantity = "0",
  page,
  perPage,
  isBestseller,
  server,
  isSearch,
  store,
  isNew,
  req,
  res,
}: CatalogQueryType & FetcherBasePropsType): Promise<CatalogResponseType> => {
  const _isSearch = isSearch !== undefined ? `&is_search=${isSearch}` : ""
  const url = `${getAbsolutePath(server)}/catalog?categories=${
    categories?.join(",") || ""
  }&params=${filters?.join(",") || ""}&price=${
    priceRange || ""
  }&is_enabled=${isEnabled}&is_fast=${isFast}&sortby=${
    sortBy || ""
  }&min_quantity=${minQuantity}&page=${page || ""}&per_page=${
    perPage || ""
  }&bestseller=${isBestseller ?? "0"}${_isSearch}&store=${
    store?.join(",") || ""
  }&new=${isNew ?? "0"}`
  console.log("url ", url)
  return get<CatalogResponseType>(url, false, req, res)
}

export const fetchFiltersParams = (
  data?: FetcherBasePropsType,
): Promise<ParamsResponse> =>
  get<ParamsResponse>(`${getAbsolutePath(data?.server)}/catalog/params`)

export const fetchSortTypes = (): Promise<SortTypeResponse> =>
  get<SortTypeResponse>(`/catalog/sort-type`)

export const fetchHits = (
  data?: FetcherBasePropsType,
): Promise<ProductCatalogType[]> =>
  get<ProductCatalogType[]>(`${getAbsolutePath(data?.server)}/hits`)

export const fetchPriceList = ({
  categories,
  email,
}: {
  categories: string[]
  email?: string
}): Promise<{ link: string }> =>
  get<{ link: string }>(
    `/catalog/pricelist?categories=${categories.join(",")}&email=${
      email || ""
    }`,
  )
