import { FC, memo } from "react"
import { getBreakpointVal } from "../../../styles/utils/Utils"
import { breakpoints } from "../../../styles/utils/vars"
import { Typography } from "../../ui/Typography/Typography"
import { ProductsSlider } from "../Slider"
import { variantArrowType } from "../Slider/types"
import { ProductType } from "../types"

const ResponsiveSliderType = {
  fiveCard: {
    [getBreakpointVal(breakpoints.lg)]: {
      slidesPerView: 5,
    },
    [getBreakpointVal(breakpoints.md)]: {
      slidesPerView: 3,
    },
    [getBreakpointVal(breakpoints.sm)]: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  },
  fourCard: {
    [getBreakpointVal(breakpoints.xxl)]: {
      slidesPerView: 3,
    },
    [getBreakpointVal(breakpoints.md)]: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  },
}

export interface IWatchedCard {
  products: ProductType[]
  title?: string
  variantArrow?: variantArrowType
  responsiveExtendsType?: "fiveCard" | "fourCard"
}

export const PureSlider: FC<IWatchedCard> = memo(
  ({ products, title, variantArrow, responsiveExtendsType = "fiveCard" }) => {
    return (
      <ProductsSlider
        variantArrow={variantArrow}
        responsiveExtends={ResponsiveSliderType[responsiveExtendsType]}
        products={products}
        title={
          title ? <Typography variant={"h3"}>{title}</Typography> : undefined
        }
      />
    )
  },
)

PureSlider.displayName = "PureSlider"
