import { dateToISOString, timeToDate } from "../../utils/helpers"
import { GetShippingsDataType, ValidateOnWeekendDateType } from "./types"

const validateOnWeekendDate: ValidateOnWeekendDateType = ({
  date,
  holidays = [],
}) => {
  const targetDate = new Date(date)
  let offset = 0
  let isValid = false

  const ruleOnWeekend = () => {
    const date = new Date(targetDate)
    date.setDate(date.getDate() + offset)
    const dayNumber = date.getDay()
    if (dayNumber !== 0) {
      return true
    }

    offset += 1
    return false
  }

  const ruleOnHolidays = () => {
    const date = new Date(targetDate)
    date.setDate(date.getDate() + offset)
    const hoDays = holidays.map((h) => dateToISOString(h))

    if (!hoDays.includes(dateToISOString(date))) {
      return true
    }

    offset += 1
    return false
  }

  while (!isValid) {
    if (!ruleOnHolidays()) {
      isValid = false
      continue
    }
    if (!ruleOnWeekend()) {
      isValid = false
      continue
    }
    isValid = true
  }

  targetDate.setDate(targetDate.getDate() + offset)

  return {
    validDate: targetDate,
    isValid: dateToISOString(date) === dateToISOString(targetDate),
  }
}

const getShippingsData: GetShippingsDataType = ({
  totalQty = 0,
  shippingShift = 1,
  currentCount = 0,
  fastQty,
  shippingFastTime,
  holidays = [],
}) => {
  let shippingDate = null as Date | null
  let isExpressShipping = false as boolean

  if (totalQty > 0 && !!shippingFastTime) {
    const now = new Date()
    const fastDate = timeToDate(shippingFastTime)
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (fastDate !== null) {
      const isMainStore =
        fastQty !== undefined && fastQty > 0
          ? totalQty > 0 && currentCount <= fastQty
          : false

      if (now.getTime() < fastDate.getTime()) {
        shippingDate = now
        const { validDate, isValid } = validateOnWeekendDate({
          date: shippingDate,
          holidays: holidays,
        })
        shippingDate = validDate
        isExpressShipping = isMainStore && isValid

        if (!isMainStore) {
          const date = new Date()
          date.setDate(shippingDate.getDate() + shippingShift)
          shippingDate = validateOnWeekendDate({
            date,
            holidays: holidays,
          }).validDate
        }
      } else {
        isExpressShipping = false
        shippingDate = tomorrow
        shippingDate = validateOnWeekendDate({
          date: shippingDate,
        }).validDate

        if (!isMainStore) {
          const date = new Date(tomorrow)
          date.setDate(date.getDate() + shippingShift)
          shippingDate = validateOnWeekendDate({
            date: date,
            holidays: holidays,
          }).validDate
        }
      }
    }
  } else {
    isExpressShipping = false
    shippingDate = null
  }

  return {
    isExpressShipping,
    shippingDate,
  }
}

export { validateOnWeekendDate, getShippingsData }
