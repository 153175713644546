import { forwardRef } from "react"
import { unstable_useId as useId } from "reakit"
import { FieldVariantsPropsType } from "../../../types/types"
import { ErrorMessageField } from "../Typography/Typography"
import {
  Placeholder,
  StyledFieldTextarea,
  StyledFieldWrapper,
  StyledHint,
  StyledTextarea,
} from "./StyledField"

export const TextareaField = forwardRef<
  HTMLTextAreaElement,
  FieldVariantsPropsType
>(
  (
    {
      name,
      errorMessage,
      withAnimatingLabel,
      placeholder,
      withButton,
      hint,
      required = false,
      ...props
    },
    ref,
  ) => {
    const { id } = useId({ id: props.id })

    return (
      <>
        <StyledFieldWrapper
          data-isanimating-label={withAnimatingLabel}
          data-iswith-button={withButton}
          data-iserror={!!errorMessage}
          data-required={required}
        >
          <StyledFieldTextarea>
            <StyledTextarea
              name={name}
              id={id}
              ref={ref}
              placeholder={withAnimatingLabel ? " " : placeholder}
              {...props}
              onInput={(e) => {
                e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
                e.currentTarget.classList.add("auto")
              }}
            />
            {withAnimatingLabel && (
              <Placeholder htmlFor={id}>{placeholder}</Placeholder>
            )}
          </StyledFieldTextarea>
          {hint && <StyledHint>{hint}</StyledHint>}
          {errorMessage && (
            <ErrorMessageField>{errorMessage}</ErrorMessageField>
          )}
        </StyledFieldWrapper>
      </>
    )
  },
)

TextareaField.displayName = "TextareaField"
