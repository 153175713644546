import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  Category,
  CategoryByAreaResponse,
  ParamsResponse,
  SortTypeResponse,
  TagResponse,
} from "../../../contracts/contracts"
import { BreadcrumbItemType } from "../../components/Breadcrumbs/BreadcrumbItem"
import { ProductType } from "../../components/Products/types"
import { ShopType } from "../../hooks/shops/types"
import {
  CategoriesStateType,
  IAvailableParam,
  IAvailableParams,
  ICategoryTreeItem,
  IFilterParamsChilds,
  IFilterParamsParents,
  PriceRangeItemType,
  QueryCatalogType,
  SortByAliasType,
  SortByType,
  TagPayloadType,
  TagType,
  TogglePageMethodType,
} from "../../types/types"
import { EMPTY_DATA_PLACEHOLDER, ROUTES } from "../../utils/constants"
import {
  compareSortTypesWithIcon,
  createCategoriesTree,
  getFilterParamsTree,
  getSubItemsBreadcrumbs,
  makeAvailableFilters,
  normalizeCategoriesByAreas,
} from "../../utils/helpers"

export const MANUAL_FILTER_KEYS = {
  store: "store",
}

export const INITIAL_PAGE = 1
export const INITIAL_PER_PAGE = 18

export type StatePriceRangeType = {
  initial: PriceRangeItemType
  checked: PriceRangeItemType
  last: PriceRangeItemType | null
  isSelected: boolean
}

export type ViewPageProductsType = "grid" | "list"

const initialState = {
  businessAreas: null as Category[] | null,
  categories: null as CategoriesStateType | null,
  currentCategory: null as Category | null,
  categoriesByAreas: [] as ICategoryTreeItem[],
  tagsByCategory: [] as TagType[],
  tags: [] as TagType[],
  currentTag: {
    id: null as number | null,
    payload: null as TagPayloadType | null,
  },
  breadcrumbs: null as Record<string, BreadcrumbItemType> | null,
  products: null as ProductType[] | null,
  productDetail: {
    uuid: null as string | null,
  },
  total: 0 as number,
  filter: {
    availableParams: null as IAvailableParams | null,
    params: {} as IFilterParamsParents & IFilterParamsChilds,
    checkedParamsKeysTotal: [] as string[],
    priceRange: {
      initial: {
        min: 0,
        max: 0,
      },
      // текущая, выбранная пользователем
      // или пришедшая из запроса фильтрованного каталога
      checked: {
        min: 0,
        max: 0,
      },
      // крайняя цена, которая пришла из запроса
      // сравнивается с checked ценой
      // если они равны,
      // значит пользователь не изменял цену - то checked не нужно отправлять в апи
      // иначе, она измененная и пользователь ограничил рамки цены
      last: null,
      isSelected: false,
    } as StatePriceRangeType,
    stores: {
      params: null as IAvailableParams | null,
    },
    query: {
      initial: null as QueryCatalogType | null,
      maked: null as QueryCatalogType | null,
      isLoading: false as boolean,
    },
    minQuantity: {
      initial: null as number | null,
      selected: null as number | null,
    },
  },
  isEnabled: true as boolean,
  isFast: false as boolean,
  sortBy: null as SortByType,
  sortBySelected: null as SortByAliasType | null,
  page: INITIAL_PAGE as number,
  itemsPerPage: null as number | null,
  isLoading: false,
  togglePageMethod: "switch" as TogglePageMethodType,
  viewProducts: "grid" as ViewPageProductsType,
  popup: {
    isShow: false as boolean,
  },
}

export const catalogSlice = createSlice({
  name: "catalog",
  initialState: initialState,
  reducers: {
    setCategories(state, action: PayloadAction<Category[]>) {
      const { treeCompared, treeSorted } = createCategoriesTree(action.payload)

      state.categories = {
        treeSorted: treeSorted,
        treeCompared: treeCompared,
        fetched:
          action.payload &&
          (action.payload.reduce(
            (a, v) => ({ ...a, [v?.uuid || ""]: v }),
            {},
          ) as Record<string, Category>),
      }
    },
    setBusinessAreas(state, action: PayloadAction<Category[]>) {
      state.businessAreas = action.payload
    },
    setTags(state, action: PayloadAction<TagResponse>) {
      state.tags = action.payload
    },
    setTagsByCategory(state, action: PayloadAction<TagResponse>) {
      state.tagsByCategory = action.payload
    },
    setProducts(state, action: PayloadAction<ProductType[]>) {
      if (state.togglePageMethod === "additional") {
        state.products = [...(state.products || []), ...action.payload]
      } else {
        state.products = action.payload
      }
    },
    setFilterParams(state, { payload }: PayloadAction<ParamsResponse>) {
      state.filter.params = getFilterParamsTree(payload)
    },
    setAvailableParams(
      state,
      action: PayloadAction<{
        catalogParams: Record<string, number>
        queryParams: string[]
      } | null>,
    ) {
      if (action.payload !== null) {
        const { params: stateParams } = state.filter
        const { catalogParams, queryParams } = action.payload

        state.filter.availableParams = makeAvailableFilters({
          stateParams,
          catalogParams,
          queryParams,
        })
      } else {
        state.filter.availableParams = action.payload
      }
    },
    setCurrentCategory(state, action: PayloadAction<Category>) {
      state.currentCategory = action.payload
    },
    updateCheckedParams(
      state,
      action: PayloadAction<{
        keyFilter: string[]
        checked: boolean
      }>,
    ) {
      if (!!state.filter && !!state.filter.availableParams) {
        const { keyFilter, checked } = action.payload
        for (const key of keyFilter) {
          if (!state.filter.availableParams) {
            continue
          }

          const parent: IAvailableParam =
            state.filter.availableParams[
              state.filter.params[key]?.parentUuid || ""
            ]

          if (!parent) {
            continue
          }

          if (!!parent?.params[key]) {
            parent.params[key].checked = checked
          }

          if (checked) {
            parent.checkedKeys = [...(parent?.checkedKeys || []), key].reduce(
              (uniq: string[], item) => {
                return uniq.includes(item) ? uniq : [...uniq, item]
              },
              [],
            )
          } else {
            parent.checkedKeys =
              parent?.checkedKeys.filter((k) => k !== key) || []
          }
        }
      }
    },
    setInitialPriceRange(
      state,
      { payload }: PayloadAction<PriceRangeItemType>,
    ) {
      state.filter.priceRange.initial = payload
    },
    setCheckedPriceRange(
      state,
      action: PayloadAction<PriceRangeItemType | null>,
    ) {
      if (action.payload === null) {
        state.filter.priceRange.checked = state.filter.priceRange.initial
        state.filter.priceRange.isSelected = false
      } else {
        const { min: payloadMin, max: payloadMax } = action.payload
        const { min: initialMin, max: initialMax } =
          state.filter.priceRange.initial

        state.filter.priceRange.checked = action.payload
        state.filter.priceRange.isSelected =
          payloadMin !== initialMin || payloadMax !== initialMax
      }
    },
    setLastPriceRange(
      state,
      { payload }: PayloadAction<PriceRangeItemType | null>,
    ) {
      if (!payload) {
        state.filter.priceRange.last = null
      } else {
        state.filter.priceRange.last = payload
      }
    },
    setCheckedParamsKeysTotal(state, action: PayloadAction<string[]>) {
      state.filter.checkedParamsKeysTotal = action.payload
    },
    setIsEnabled(state, action: PayloadAction<boolean>) {
      state.isEnabled = action.payload
    },
    setIsFast(state, action: PayloadAction<boolean>) {
      state.isFast = action.payload
    },
    setSortBy(state, action: PayloadAction<SortTypeResponse>) {
      state.sortBy = compareSortTypesWithIcon(action.payload)
    },
    setSortBySelected(state, action: PayloadAction<null | SortByAliasType>) {
      state.sortBySelected = action.payload
    },
    setInitMinQuantity(state, action: PayloadAction<number | null>) {
      state.filter.minQuantity.initial = action.payload
    },
    setSelectedMinQuantity(state, action: PayloadAction<number | null>) {
      state.filter.minQuantity.selected = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.total = action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setItemsPerPage(state, action: PayloadAction<number>) {
      state.itemsPerPage = action.payload
    },
    setTogglePageMethod(state, action: PayloadAction<TogglePageMethodType>) {
      state.togglePageMethod = action.payload
    },
    setCategoriesByAreas(
      state,
      action: PayloadAction<CategoryByAreaResponse | undefined>,
    ) {
      if (!!action.payload) {
        if (!!state.categories?.fetched) {
          const { treeSorted } = createCategoriesTree(
            normalizeCategoriesByAreas(
              action.payload,
              state.categories.fetched,
            ),
          )
          state.categoriesByAreas = treeSorted
        }
      }
    },
    setCurrentTagId(state, action: PayloadAction<number | null>) {
      state.currentTag.id = action.payload
    },
    setCurrentTagPayload(state, action: PayloadAction<TagPayloadType | null>) {
      state.currentTag.payload = action.payload
    },
    setFilterStores(
      state,
      { payload }: PayloadAction<Record<string, ShopType>>,
    ) {
      const stores: IAvailableParams = {}
      stores[MANUAL_FILTER_KEYS.store] = {
        uuid: MANUAL_FILTER_KEYS.store,
        name: "Склады",
        order: 0,
        checkedKeys: [],
        params: Object.fromEntries(
          Object.entries(payload).map(([uuid, s]) => {
            return [
              uuid,
              {
                uuid: uuid,
                name: s.address || EMPTY_DATA_PLACEHOLDER,
                parentUuid: MANUAL_FILTER_KEYS.store,
                product_qty: undefined,
                disabled: false,
                checked: false,
              },
            ]
          }),
        ),
      }
      state.filter.stores.params = stores
    },
    updateCheckedStores(
      state,
      action: PayloadAction<{
        keyFilter: string[]
        checked: boolean
      }>,
    ) {
      const { keyFilter, checked } = action.payload
      if (state.filter.stores.params !== null) {
        keyFilter.map((key) => {
          if (state.filter.stores.params !== null) {
            if (
              !!state.filter.stores.params[MANUAL_FILTER_KEYS.store].params[key]
            ) {
              state.filter.stores.params[MANUAL_FILTER_KEYS.store].params[
                key
              ].checked = checked

              if (checked) {
                state.filter.stores.params[
                  MANUAL_FILTER_KEYS.store
                ].checkedKeys = [
                  ...state.filter.stores.params[MANUAL_FILTER_KEYS.store]
                    .checkedKeys,
                  key,
                ].reduce((uniq: string[], item) => {
                  return uniq.includes(item) ? uniq : [...uniq, item]
                }, [])
              } else {
                state.filter.stores.params[
                  MANUAL_FILTER_KEYS.store
                ].checkedKeys = state.filter.stores.params[
                  MANUAL_FILTER_KEYS.store
                ].checkedKeys.filter((k) => k !== key)
              }
            }
          }
        })
      }
    },
    updateBreadcrumbs(state) {
      if (!state.categories) {
        state.breadcrumbs = null
        return
      }

      if (
        !state.currentCategory ||
        !state.categories.fetched ||
        !state.categories.treeCompared
      ) {
        state.breadcrumbs = null
        return
      }

      const breadcrumbs = {} as Record<string, BreadcrumbItemType>

      const { uuid: uuidCurrCat = "", parent: parentCurrCat } =
        state.currentCategory

      const sequenceParent = !!uuidCurrCat
        ? state.categories.treeCompared[uuidCurrCat]
        : []
      let treeSlice = null as ICategoryTreeItem | null

      for (const item of sequenceParent) {
        const foundCategory = state.categories.fetched[item.uuid || ""]

        if (treeSlice === null) {
          treeSlice =
            state.categories.treeSorted.find((t) => t.uuid === item.uuid) ||
            null
        } else {
          treeSlice = (treeSlice?.children || {})[item.id || ""] || null
        }

        if (foundCategory !== undefined) {
          breadcrumbs[foundCategory.uuid || ""] = {
            path: `${ROUTES.catalog}/${foundCategory.alias || ""}`,
            title: `${foundCategory.name}`,
            subItems: getSubItemsBreadcrumbs(treeSlice),
          }
        }
      }

      if (parentCurrCat === 0) {
        treeSlice =
          !!state.categories.treeSorted && !!uuidCurrCat
            ? state.categories.treeSorted.find((t) => t.uuid === uuidCurrCat) ||
              null
            : null
      } else {
        treeSlice =
          (treeSlice?.children || {})[state.currentCategory.id || ""] || null
      }

      breadcrumbs[uuidCurrCat] = {
        path: `${ROUTES.catalog}/${state.currentCategory.alias || ""}`,
        title: `${state.currentCategory.name}`,
        subItems: getSubItemsBreadcrumbs(treeSlice),
      }

      state.breadcrumbs = breadcrumbs
    },
    setQueryMaked: (state, action: PayloadAction<QueryCatalogType | null>) => {
      state.filter.query.maked = action.payload
      // state.filter.query.isLoading = false
    },
    setQueryIsLoading: (state, action: PayloadAction<boolean>) => {
      state.filter.query.isLoading = action.payload
    },
    setQueryInitial: (
      state,
      action: PayloadAction<QueryCatalogType | null>,
    ) => {
      state.filter.query.initial = action.payload
    },
    setProductDetailUUId: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.productDetail.uuid = payload
    },
    setPopupIsShow: (state, { payload }: PayloadAction<boolean>) => {
      state.popup.isShow = payload
    },
    setViewProducts: (
      state,
      { payload }: PayloadAction<ViewPageProductsType>,
    ) => {
      state.viewProducts = payload
    },
  },
})

export const {
  setCategoriesByAreas,
  updateBreadcrumbs,
  setCurrentCategory,
  setCurrentPage,
  setTogglePageMethod,
  setProducts,
  setTotalCount,
  setTagsByCategory,
  setFilterParams,
  updateCheckedParams,
  setInitialPriceRange,
  setCheckedPriceRange,
  setLastPriceRange,
  setIsEnabled,
  setSortBy,
  setItemsPerPage,
  setIsFast,
  setAvailableParams,
  setSortBySelected,
  setInitMinQuantity,
  setSelectedMinQuantity,
  setCheckedParamsKeysTotal,
  setCurrentTagId,
  setCurrentTagPayload,
  setFilterStores,
  updateCheckedStores,
  setCategories,
  setBusinessAreas,
  setTags,
  setQueryMaked,
  setQueryIsLoading,
  setQueryInitial,
  setProductDetailUUId,
  setPopupIsShow,
  setViewProducts,
} = catalogSlice.actions
