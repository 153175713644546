import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ShopType } from "../../hooks/shops/types"

const initialState = {
  shops: null as Record<string, ShopType> | null,
  selected: null as string | null,
  activated: null as string | null,
}

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShops: (state, { payload }: PayloadAction<ShopType[] | null>) => {
      const maked = {}
      if (payload !== null) {
        for (const shop of payload) {
          maked[shop.uuid] = { ...shop }
        }
      }
      state.shops = maked
    },
    setActivatedShop: (state, { payload }: PayloadAction<string | null>) => {
      state.activated = payload
    },
    setSelectedShop: (state, { payload }: PayloadAction<string | null>) => {
      state.selected = payload
    },
  },
})

export const { setShops, setActivatedShop, setSelectedShop } = shopSlice.actions
