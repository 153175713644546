import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { useMutation, useQuery } from "react-query"
import Cookies from "universal-cookie"
import {
  ApiError,
  ChangeQtyRequest,
  ProductSpecificationType,
} from "../../../contracts/contracts"
import {
  fetchBindUserCart,
  fetchCart,
  fetchChangeQtyProduct,
  fetchChangeQtySample,
  fetchClearCart,
  fetchRemoveCart,
} from "../../api/cartAPI"
import { fetchProductsList } from "../../api/productsAPI"
import { ProductType } from "../../components/Products/types"
import {
  addProductsFetching,
  appendDataSource,
  clearCart,
  removeProductsFetching,
  removeProductSpecification,
  setHasProductsInAuth,
  setIsFetchingMerge,
  setIsInit,
  setIsShowMerge,
  setMergeProducts,
  setMergeSpecification,
  setMinShippingDate,
  setMultiAddedToCartFetching,
  setMultiAddedToCartFinish,
  setMultiAddedToCartSuccess,
  setNextShippingDate,
  setOrder,
  setPickupDate,
  setProductsCost,
  setSpecification,
  setToken,
  setTotalCost,
  updateProductPriceUnit,
  updateSpecification,
} from "../../store/reducers/cartSlice"
import {
  ChangeQtyResponse,
  OfferOrderThankType,
  OrderThankType,
  SpecificationItemType,
} from "../../types/types"
import {
  COOKIE_HOST_NAME,
  ROUTES,
  SITE_URL,
  TIMEOUT_SUCCESS,
} from "../../utils/constants"
import {
  compareSpecificationProducts,
  dateToISOString,
  onErrorFetcherCart,
} from "../../utils/helpers"
import { useAuth } from "../auth"
import { useClipboardCopy } from "../clipboardCopy"
import { usePromocode } from "../promocode"
import { useAppDispatch, useAppSelector } from "../redux"
import { useShippings } from "../shippings/shippings"
import {
  formatProductsToSpecification,
  getEntityCartIds,
  getProductQty,
  getSampleQty,
  getTokenStorage,
  setTokenStorage,
} from "./helpers"
import { CartContextType } from "./types"

const cookies = new Cookies()

const CartContext = createContext<CartContextType | null>(null)

export const Provider: FC = ({ children }) => {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {
    specification,
    datasource,
    totalCost,
    productsCost,
    token,
    order,
    productsFetching,
    merge: {
      products: productsMerge,
      specification: specificationMerge,
      isFetching: isFetchingMerge,
      isShow: isShowMerge,
    },
    isInit,
    pickup,
    shipping,
    hasProductsInAuth,
    multiAddedToCart,
  } = useAppSelector((state) => state.cart)

  const { isInit: isInitAuth, isAuth, user, updateUser } = useAuth()
  const { updatePromocode, updateDiscount } = usePromocode({
    cart: token,
  })
  const { shippingCost, recalcShippingDate } = useShippings()

  const { replace } = useRouter()

  const { handleCopyClick, isCopied } = useClipboardCopy()

  const notAuthCart = useRef<string | null>(null)

  const hideShowBind = useCallback(() => {
    dispatch(setIsShowMerge(false))
  }, [dispatch])

  const { refetch: refetchCart, isFetching: isFetchingDataCart } = useQuery(
    ["cart", token, isInit],
    () => (!!token ? fetchCart(token || "") : null),
    {
      staleTime: 5000,
      enabled: !!token || isInit,
      onSuccess: (response) => {
        if (!!response) {
          dispatch(setSpecification(response.products || []))
          updateTotalCostDispatch(response.total_cost || 0)
          updateDiscount(response.discount || 0)
          updatePromocode(response.promocode || null)
        } else {
          dispatch(setSpecification([]))
          updateTotalCostDispatch(0)
          updateDiscount(0)
          updatePromocode(null)
        }
      },
      onError: (error: ApiError) => {
        onErrorFetcherCart(error)
      },
    },
  )

  const { mutate: bindCartUserMutate, mutateAsync: bindCartUserMutateAsync } =
    useMutation(fetchBindUserCart, {
      onSuccess: (response, request) => {
        if (user !== null) {
          updateUser({ ...user, cart: request.cart })
        }
      },
    })

  const withOutDataSourceIds = useMemo(() => {
    const specificationIds = getEntityCartIds(specification)
    const datasourceIds = getEntityCartIds(datasource)

    return specificationIds.filter((sId) => !datasourceIds.includes(sId))
  }, [datasource, specification])

  // на основе сохраненных ids товаров
  // получаем данные этих товаров для вывода
  useQuery(
    ["datasource", withOutDataSourceIds],
    () =>
      fetchProductsList({
        uuids: withOutDataSourceIds.join(",") || "",
      }),
    {
      enabled: withOutDataSourceIds.length > 0,
      onSuccess: (dataProductsInCart) => {
        // получаем данные товаров и
        // сохраняем в стейте текущие товары в корзине
        if (dataProductsInCart !== undefined) {
          dispatch(appendDataSource(dataProductsInCart))
        }
      },
    },
  )

  const {
    mutate: getProductsInAuthMutate,
    isLoading: isLoadingProductsInAuth,
  } = useMutation(fetchProductsList, {
    onSuccess: (response) => {
      dispatch(setMergeProducts(response || []))
    },
    onError: () => {
      dispatch(setMergeProducts([]))
    },
  })

  const { mutate: getCartMutate } = useMutation(fetchCart, {
    onSuccess: async (response) => {
      if (!!response && !!response.products && token !== null) {
        notAuthCart.current = token
        const withAuthCart = user?.cart

        dispatch(setIsFetchingMerge(true))
        await bindCartUserMutateAsync({
          cart: notAuthCart.current,
        })

        if (!!withAuthCart) {
          await removeCartMutateAsync({
            uid: withAuthCart,
          })
        }
        dispatch(setIsFetchingMerge(false))

        if (!!response.products && response.products.length > 0) {
          dispatch(setHasProductsInAuth(true))
          dispatch(
            setMergeSpecification(
              formatProductsToSpecification({
                products: response.products,
                specification,
              }),
            ),
          )
          getProductsInAuthMutate({
            uuids: response.products.map((p) => p.uuid).join(","),
          })
        }
      }
    },
    onError: (error: ApiError) => {
      onErrorFetcherCart(error)
    },
  })

  const updateToken = useCallback(
    (token: string | null) => {
      setTokenStorage(token)
      dispatch(setToken(token))
      if (isInitAuth && isAuth) {
        if (user !== null && user.cart === null) {
          updateUser({ ...user, cart: token })
        }
      }
    },
    [dispatch, isAuth, isInitAuth, updateUser, user],
  )

  // обертка диспатчер
  const updateSpecificationDispatch = useCallback(
    (specification: SpecificationItemType) => {
      dispatch(updateSpecification(specification))
    },
    [dispatch],
  )

  // обертка диспатчер
  const updateTotalCostDispatch = useCallback(
    (cost: number) => {
      dispatch(setTotalCost(cost))
    },
    [dispatch],
  )

  const getUrl = useCallback((): string | undefined => {
    if (specification === null) {
      return
    }
    const host = cookies.get(COOKIE_HOST_NAME)
    if (!host) {
      return
    }

    return `https://${host}${ROUTES.favorites}/shared/${Object.keys(
      specification,
    )
      .filter(
        (key) => !!specification[key].quantity || !!specification[key].sample,
      )
      .map((key) => {
        return `${specification[key].uuid}:${
          specification[key].isRemoved ? 0 : specification[key].quantity
        }:${specification[key].sample || 0}`
      })
      .join(",")}`
  }, [specification])

  const [lastSharedUrl, setLastSharedUrl] = useState<undefined | string>()

  const shareCartHandler = useCallback(() => {
    const url = getUrl()
    setLastSharedUrl(url)
    !!url && handleCopyClick(url)
  }, [handleCopyClick, getUrl])

  const { mutateAsync: changeQtyProductMutateAsync } = useMutation(
    fetchChangeQtyProduct,
    {
      onSuccess: (response: ChangeQtyResponse, variables: ChangeQtyRequest) => {
        if (token === null) {
          updateToken(response.cart)
        }
        updateTotalCostDispatch(response.total_cost)
        dispatch(removeProductsFetching(variables.product))
      },
      onError: (error: ApiError, variables: ChangeQtyRequest) => {
        dispatch(removeProductsFetching(variables.product))
      },
      onMutate: (variables: ChangeQtyRequest) => {
        dispatch(addProductsFetching(variables.product))
      },
    },
  )

  const { mutateAsync: changeQtySampleMutateAsync } = useMutation(
    fetchChangeQtySample,
    {
      onSuccess: (response: ChangeQtyResponse, variables: ChangeQtyRequest) => {
        if (token === null) {
          updateToken(response.cart)
        }
        updateTotalCostDispatch(response.total_cost)
        dispatch(removeProductsFetching(variables.product))
      },
      onError: (error: ApiError, variables: ChangeQtyRequest) => {
        dispatch(removeProductsFetching(variables.product))
      },
      onMutate: (variables: ChangeQtyRequest) => {
        dispatch(addProductsFetching(variables.product))
      },
    },
  )

  const multiAddToCart = useCallback(
    async (
      restProducts: Record<string, ProductSpecificationType>,
      tokenCart: string | null,
      afterToCart?: boolean,
      onSuccess?: () => void,
    ) => {
      dispatch(setMultiAddedToCartFetching(true))
      dispatch(setMultiAddedToCartSuccess(false))

      const products = { ...restProducts }
      const promises: Promise<ChangeQtyResponse>[] = []

      const _run = async (
        products: Record<string, ProductSpecificationType>,
        tokenCart: string | null,
      ) => {
        const token = tokenCart
        const productsKeys = Object.keys(products)
        if (productsKeys.length === 0) {
          return
        }

        if (token === null) {
          const firstKey: string = productsKeys[0]
          let newToken: string | null = null

          if ((products[firstKey].quantity || 0) > 0) {
            const resAdded = await changeQtyProductMutateAsync({
              product: products[firstKey].uuid || "",
              quantity: products[firstKey].quantity || 0,
              cart: undefined,
            })
            if (!!resAdded) {
              newToken = resAdded.cart
            }
          } else {
            const resAdded = await changeQtySampleMutateAsync({
              product: products[firstKey].uuid || "",
              quantity: products[firstKey].sample || 0,
              cart: undefined,
            })
            if (!!resAdded) {
              newToken = resAdded.cart
            }
          }
          if (newToken !== null) {
            if (products[firstKey]) {
              delete products[firstKey]
            }
          }
          await _run(products, newToken)
        } else {
          productsKeys.map((p) => {
            const currentSpeicification =
              specification !== null ? specification[p] || null : null
            if ((products[p].quantity || 0) > 0) {
              promises.push(
                (async () => {
                  return await changeQtyProductMutateAsync({
                    product: products[p].uuid || "",
                    quantity: products[p].quantity || 0, // здесь уже объединенное кол-во должно быть
                    cart: token,
                  })
                })(),
              )
            }
            if ((products[p].sample || 0) > 0) {
              promises.push(
                (async () => {
                  return await changeQtySampleMutateAsync({
                    product: products[p].uuid || "",
                    quantity:
                      (products[p].sample || 0) +
                      (currentSpeicification?.sample || 0),
                    cart: token,
                  })
                })(),
              )
            }
          })
        }
      }

      await _run(products, tokenCart)

      if (promises.length > 0) {
        Promise.all(promises).finally(() => {
          dispatch(setMultiAddedToCartFetching(false))
          dispatch(setMultiAddedToCartSuccess(true))
          dispatch(setMultiAddedToCartFinish(true))
          setTimeout(() => {
            dispatch(setMultiAddedToCartFinish(false))
          }, TIMEOUT_SUCCESS)
          if (!!onSuccess) {
            onSuccess()
          }

          if (afterToCart) {
            void replace(ROUTES.cart)
          } else {
            void refetchCart()
          }
        })
      }
    },
    [
      dispatch,
      changeQtyProductMutateAsync,
      changeQtySampleMutateAsync,
      specification,
      replace,
      refetchCart,
    ],
  )

  const clearCartMutation = useMutation(fetchClearCart, {
    onSuccess: () => {
      dispatch(clearCart())
      setIsFetching(false)
    },
    onError: (error: ApiError) => {
      console.log(error)
      if (error.status === 418) {
        setTokenStorage(null)
      }
      setIsFetching(false)
    },
    onMutate: () => {
      setIsFetching(true)
    },
  })

  const { mutateAsync: removeCartMutateAsync } = useMutation(fetchRemoveCart)

  const clearCartHandler = useCallback(() => {
    if (token !== null) {
      clearCartMutation.mutate({
        uid: token,
      })
    }
  }, [clearCartMutation, token])

  const updateProductPriceUnitHandle = useCallback(
    ({ uuid, priceUnit }: { uuid: string; priceUnit: number }) => {
      dispatch(updateProductPriceUnit({ uuid, priceUnit }))
    },
    [dispatch],
  )

  const cartCost = useMemo(() => {
    let sum = 0
    if (specification !== null && datasource !== null) {
      for (const [key, itemSpec] of Object.entries(specification)) {
        if (!itemSpec) {
          continue
        }

        sum += !itemSpec?.isSampleRemoved
          ? (itemSpec?.sample || 0) *
            (!!datasource[key] && !!datasource[key].total_qty
              ? datasource[key]?.price || 0
              : 0)
          : 0

        sum += !itemSpec?.isRemoved
          ? (itemSpec?.quantity || 0) *
            (!!datasource[key] && !!datasource[key].total_qty
              ? datasource[key]?.price || 0
              : 0)
          : 0
      }
    }

    return sum
  }, [datasource, specification])

  const cartCount = useMemo(() => {
    let count = 0

    if (specification === null) {
      return count
    }
    for (const [, itemSpec] of Object.entries(specification)) {
      count += getProductQty(itemSpec) > 0 ? 1 : 0
      count += getSampleQty(itemSpec) > 0 ? 1 : 0
    }
    return count
  }, [specification])

  const shippingDatesOrdered = useMemo(() => {
    const dates: string[] = []
    if (specification === null || datasource === null) {
      return []
    }

    for (const [key, itemSpec] of Object.entries(specification)) {
      const _productQty = getProductQty(itemSpec)
      const _sampleQty = getSampleQty(itemSpec)
      if (_sampleQty === 0 && _productQty === 0) {
        continue
      }
      const entity: ProductType = (datasource || {})[key] || null
      if (!entity) {
        continue
      }
      const { shippingDate: date } = recalcShippingDate({
        product: {
          fastQty: entity?.fast_qty || 0,
          currentCount: _productQty + _sampleQty,
          totalQty: entity?.total_qty || 0,
        },
      })
      if (date !== null) {
        dates.push(dateToISOString(date))
      }
    }

    return [...dates]
      .reduce(
        (uniq: string[], item) =>
          uniq.includes(item) ? uniq : [...uniq, item],
        [],
      )
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
  }, [datasource, recalcShippingDate, specification])

  const onAuthHandle = useCallback(() => {
    if (!!user?.cart && token === null) {
      updateToken(user.cart)
    }

    if (!user?.cart && token !== null) {
      bindCartUserMutate({
        cart: token,
      })
    }

    if (!!user?.cart && token !== null && user.cart !== token) {
      getCartMutate(user.cart)
    }
  }, [bindCartUserMutate, getCartMutate, token, updateToken, user?.cart])

  const onNotAuthHandle = useCallback(() => {
    const tokenStorage = getTokenStorage()
    if (tokenStorage !== null) {
      updateToken(tokenStorage)
    }
  }, [updateToken])

  const removeSpecificationHandle = useCallback(
    (uuid: string) => {
      dispatch(removeProductSpecification({ uuid: uuid }))
    },
    [dispatch],
  )

  const mergeProductsCarts = useCallback(() => {
    if (specificationMerge === null) {
      return
    }
    void multiAddToCart(specificationMerge, notAuthCart.current)
  }, [multiAddToCart, specificationMerge])

  const cleanMergeData = useCallback(() => {
    setHasProductsInAuth(false)
    setTimeout(() => {
      dispatch(setMergeProducts(null))
    }, 300)
  }, [dispatch])

  const setOrderHandle = useCallback(
    (payload: OrderThankType | null) => {
      if (payload === null) {
        dispatch(setOrder(payload))
        return
      }

      const createOffers = () => {
        const offers: OfferOrderThankType[] = []
        if (specification !== null && datasource !== null) {
          for (const [key, itemSpec] of Object.entries(specification)) {
            const product = datasource[key]
            if (product !== undefined) {
              offers.push({
                url: `${SITE_URL}${ROUTES.product}/${product.alias}`,
                count: getProductQty(itemSpec) + getSampleQty(itemSpec),
                price: product.price || 0,
                currency: "RUB",
                name: product.name || "",
              })
            }
          }
        }
        return offers
      }
      dispatch(
        setOrder({
          ...payload,
          offers: createOffers(),
        }),
      )
    },
    [dispatch, datasource, specification],
  )

  const { products, samples } = useMemo(
    () => compareSpecificationProducts(specification, datasource),
    [specification, datasource],
  )

  const isEmpty = !Object.keys(datasource || {}).length

  useEffect(() => {
    dispatch(setIsShowMerge(hasProductsInAuth))
  }, [dispatch, hasProductsInAuth])

  useEffect(() => {
    if (isInitAuth) {
      if (isAuth) {
        onAuthHandle()
      } else {
        onNotAuthHandle()
      }

      dispatch(setIsInit(true))
    }
  }, [isAuth, isInitAuth, dispatch])

  // локальный пересчет total корзины
  useEffect(() => {
    dispatch(setProductsCost(cartCost))
  }, [cartCost, dispatch])

  useEffect(() => {
    setIsFetching(isFetchingDataCart)
  }, [isFetchingDataCart])

  useEffect(() => {
    if (shippingDatesOrdered.length > 0) {
      dispatch(setNextShippingDate(shippingDatesOrdered[0]))
      dispatch(
        setMinShippingDate(
          shippingDatesOrdered[shippingDatesOrdered.length - 1],
        ),
      )
    } else {
      dispatch(setNextShippingDate(null))
      dispatch(setMinShippingDate(null))
    }
  }, [dispatch, shippingDatesOrdered])

  useEffect(() => {
    dispatch(setPickupDate(shipping.nextDate.iso))
  }, [dispatch, shipping.nextDate.iso])

  const contextValue = useMemo(
    () =>
      ({
        specification,
        updateSpecification: updateSpecificationDispatch,
        totalCost: totalCost + (shippingCost || 0),
        token,
        updateToken,
        cartCount: cartCount,
        cartCost: productsCost,
        clearCart: clearCartHandler,
        shareCart: shareCartHandler,
        sharedUrl: lastSharedUrl,
        isSharedLinkCopied: isCopied,
        multiAddToCart: multiAddToCart,
        isFetching,
        products,
        samples,
        updateProductPriceUnit: updateProductPriceUnitHandle,
        refetchCart: () => {
          void refetchCart()
        },
        updateTotalCost: updateTotalCostDispatch,
        productsFetching,
        removeSpecification: removeSpecificationHandle,
        addProductInFetching: (uuid: string) => {
          dispatch(addProductsFetching(uuid))
        },
        removeProductInFetching: (uuid: string) => {
          dispatch(removeProductsFetching(uuid))
        },
        setOrder: setOrderHandle,
        order: order,
        productsInAuth: productsMerge,
        specificationMerge: specificationMerge,
        hasProductsInAuth,
        isLoadingProductsInAuth,
        mergeProductsCarts,
        cleanMergeData,
        hideShowMerge: hideShowBind,
        isEmpty,
        shipping: {
          cost: shipping.cost,
          free: shipping.free,
          nextDate: {
            iso: shipping.nextDate.iso,
            date: !!shipping.nextDate.iso
              ? new Date(shipping.nextDate.iso)
              : null,
          },
          minDate: {
            iso: shipping.minDate.iso,
            date: !!shipping.minDate.iso
              ? new Date(shipping.minDate.iso)
              : null,
          },
        },
        pickup: {
          iso: pickup.iso,
          date: !!pickup.iso ? new Date(pickup.iso) : null,
        },
        multiAddedToCart,
        merge: {
          products: productsMerge,
          specification: specificationMerge,
          isFetching: isFetchingMerge,
          isShow: isShowMerge,
        },
      } as CartContextType),
    [
      specification,
      updateSpecificationDispatch,
      totalCost,
      shippingCost,
      token,
      updateToken,
      cartCount,
      productsCost,
      clearCartHandler,
      shareCartHandler,
      isCopied,
      multiAddToCart,
      isFetching,
      products,
      samples,
      updateProductPriceUnitHandle,
      updateTotalCostDispatch,
      productsFetching,
      removeSpecificationHandle,
      setOrderHandle,
      order,
      productsMerge,
      specificationMerge,
      hasProductsInAuth,
      isLoadingProductsInAuth,
      mergeProductsCarts,
      cleanMergeData,
      hideShowBind,
      isEmpty,
      shipping,
      pickup.iso,
      multiAddedToCart,
      isFetchingMerge,
      isShowMerge,
      refetchCart,
      dispatch,
      lastSharedUrl,
    ],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

export const useCart = (): CartContextType => {
  const cartContext = useContext(CartContext)

  if (cartContext === null) {
    throw new Error("Cart context have to be provided")
  }

  return {
    totalCost: cartContext.totalCost,
    cartCost: cartContext.cartCost,
    specification: cartContext.specification,
    token: cartContext.token,
    cartCount: cartContext.cartCount,
    isFetching: cartContext.isFetching,
    samples: cartContext.samples,
    products: cartContext.products,
    isSharedLinkCopied: cartContext.isSharedLinkCopied,
    productsFetching: cartContext.productsFetching,
    updateToken: cartContext.updateToken,
    updateSpecification: cartContext.updateSpecification,
    clearCart: cartContext.clearCart,
    shareCart: cartContext.shareCart,
    sharedUrl: cartContext.sharedUrl,
    multiAddToCart: cartContext.multiAddToCart,
    updateProductPriceUnit: cartContext.updateProductPriceUnit,
    refetchCart: cartContext.refetchCart,
    updateTotalCost: cartContext.updateTotalCost,
    removeSpecification: cartContext.removeSpecification,
    addProductInFetching: cartContext.addProductInFetching,
    removeProductInFetching: cartContext.removeProductInFetching,
    setOrder: cartContext.setOrder,
    order: cartContext.order,
    pickup: cartContext.pickup,
    productsInAuth: cartContext.productsInAuth,
    hasProductsInAuth: cartContext.hasProductsInAuth,
    isLoadingProductsInAuth: cartContext.isLoadingProductsInAuth,
    mergeProductsCarts: cartContext.mergeProductsCarts,
    cleanMergeData: cartContext.cleanMergeData,
    hideShowMerge: cartContext.hideShowMerge,
    isEmpty: cartContext.isEmpty,
    shipping: cartContext.shipping,
    merge: cartContext.merge,
    multiAddedToCart: cartContext.multiAddedToCart,
  } as const
}
