import { FC, useMemo } from "react";
import { cx } from "@linaria/core";
import { Button } from "../../../../components/ui/Button/Button";
import { cssButtonToFavorite } from "../../../../components/ui/Button/StyledButton";
import { useAuth } from "../../../../hooks/auth";
import { useCompare } from "../../../../hooks/compare/useCompare";
import PushCounter from "../../../../styles/utils/PushCounter";
import { LIMIT_COUNT_IN_CART_NUM, ROUTES } from "../../../../utils/constants";
export const ButtonToComparison: FC = () => {
  const {
    isInit,
    isAuth
  } = useAuth();
  const {
    quantity
  } = useCompare();
  const counterElement = useMemo(() => <>
        {quantity > 0 && <PushCounter>
            {quantity > LIMIT_COUNT_IN_CART_NUM ? `${LIMIT_COUNT_IN_CART_NUM}+` : quantity}
          </PushCounter>}
      </>, [quantity]);
  return <Button href={isInit && isAuth ? `${ROUTES.account}${ROUTES.compare}` : ROUTES.compare} icon={quantity > 0 ? "CompareActive" : "CompareAdd"} className={cx(cssButtonToFavorite)} aria-label={"Перейти в сравнение"} seoText={"Перейти в сравнение"} variant={"box"} as={"a"}>
      {counterElement}
    </Button>;
};
ButtonToComparison.displayName = "ButtonToComparison";