import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { accountSlice } from "./reducers/accountSlice"
import { appSlice } from "./reducers/appSlice"
import { cartSlice } from "./reducers/cartSlice"
import { catalogSlice } from "./reducers/catalogSlice"
import { searchSlice } from "./reducers/searchSlice"
import { shopSlice } from "./reducers/shopSlice"

const rootReducer = combineReducers({
  [appSlice.name]: appSlice.reducer,
  [accountSlice.name]: accountSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [catalogSlice.name]: catalogSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [shopSlice.name]: shopSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export type RootStateType = ReturnType<typeof rootReducer>
export type AppStoreType = typeof store
export type AppDispatch = AppStoreType["dispatch"]
