import type { FC } from "react"
import { StyledLoaderOverlay } from "../BaseLoader/StyledBaseLoader"
import { Spin } from "../Spin"
import { StyledComponentLoaderContainer } from "./StyledComponentLoader"

export const ComponentLoader: FC = () => {
  return (
    <>
      <StyledComponentLoaderContainer>
        <Spin />
        <StyledLoaderOverlay />
      </StyledComponentLoaderContainer>
    </>
  )
}
