import type { FC } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { cx } from "@linaria/core";
import { CartBindPopup } from "../../components/Cart/Bind";
import { FooterBar } from "../../components/FooterBar";
import { OfferToLogInPopup } from "../../components/Products/parts/OfferToLogInPopup";
import { WatchedRecently } from "../../components/Products/WatchedRecently";
import { useAppSelector } from "../../hooks/redux";
import { cssLayoutWithAutoComplete, PageWrapper } from "../../styles/utils/Utils";
import { Header } from "./Header";
const AllowCookie = dynamic(((() => import("../../components/AllowCookie").then(mod => mod.AllowCookie)) as DynamicOptions), {
  ssr: false
});
const Footer = dynamic(((() => import("./Footer/Footer").then(mod => mod.Footer)) as DynamicOptions));
export const Default: FC = ({
  children,
  ...rest
}) => {
  const isShowSearchAutoComplete = useAppSelector(({
    search
  }) => search.autoComplete.isShow);
  return <PageWrapper {...rest} className={cx(isShowSearchAutoComplete && cssLayoutWithAutoComplete)}>
      <Header />

      {children}

      <AllowCookie />

      <CartBindPopup />

      <OfferToLogInPopup />

      <WatchedRecently />

      <Footer />

      <FooterBar />
    </PageWrapper>;
};