import {
  CodeRemoveResponse,
  CodeUseResponse,
  V1PromocodeDeletePayload,
  V1PromocodeUpdatePayload,
} from "../../contracts/contracts"
import { deleteFetch, put } from "../service/fetcher"

export const fetchApplyPromocode = (
  data: V1PromocodeUpdatePayload,
): Promise<CodeUseResponse> => put("/promocode", data, true)

export const fetchCancelPromocode = (
  data: V1PromocodeDeletePayload,
): Promise<CodeRemoveResponse> => deleteFetch("/promocode", data, true)
