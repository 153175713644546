import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  RequestBanner,
  SettingsFetchedType,
} from "../../../contracts/contracts"
import {
  LocationExtendsType,
  LocationType,
  StoreBannersType,
} from "../../types/types"
import { getLocationFormat, makeBanners } from "../../utils/helpers"

export type SettingsAppType = {
  shippingFastTime?: string
  shippingShift: number
  holidays: string[]
  minManyQuantity: number
  viber: string | null
  whatsApp: string | null
  telegram: string | null
}

const initialState = {
  settings: null as SettingsAppType | null,
  banners: null as StoreBannersType | null,
  lastHistoryEntry: null as string | null,
  isLoadingPage: false as boolean | false,
  location: null as LocationExtendsType | null,
  isDateToOnlyCompany: false as boolean | false,
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<SettingsFetchedType | null>) => {
      if (action.payload !== null) {
        state.settings = {
          shippingFastTime: action.payload.delivery_fast_time,
          shippingShift: action.payload.delivery_shift || 0,
          holidays: action.payload.holidays || [],
          minManyQuantity: action.payload.min_many_quantity || 0,
          telegram: action.payload.telegram || null,
          whatsApp: action.payload.whatsApp || null,
          viber: action.payload.viber || null,
        }
      } else {
        state.settings = action.payload
      }
    },
    setBanners: (state, { payload }: PayloadAction<RequestBanner[] | null>) => {
      state.banners = payload !== null ? makeBanners(payload) : payload
    },
    setLastHistoryEntry(state, action: PayloadAction<string | null>) {
      state.lastHistoryEntry = action.payload
    },
    setIsLoadingPage(state, action: PayloadAction<boolean>) {
      state.isLoadingPage = action.payload
    },
    setLocation(state, { payload }: PayloadAction<LocationType | null>) {
      state.location = payload !== null ? getLocationFormat(payload) : null
    },
    setIsDateToOnlyCompany(state, { payload }: PayloadAction<boolean>) {
      state.isDateToOnlyCompany = payload
    },
  },
})

export const {
  setSettings,
  setBanners,
  setIsLoadingPage,
  setLocation,
  setIsDateToOnlyCompany,
} = appSlice.actions
