import { FC } from "react"
import { Icon, sizeSVGNamesType } from "../../Icon"
import { StyledSpin } from "./StyledSpin"

export const Spin: FC<{ size?: sizeSVGNamesType }> = ({ size }) => {
  return (
    <StyledSpin>
      <Icon NameIcon={"CircleLoading"} size={size} />
    </StyledSpin>
  )
}
