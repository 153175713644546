import { useCallback } from "react"
import { useMutation } from "react-query"
import { AddressType } from "../../contracts/contracts"
import { fetchAddresses, fetchSetAddressDefault } from "../api/accountAPI"
import { accountSlice } from "../store/reducers/accountSlice"
import { useAppDispatch, useAppSelector } from "./redux"

type UseAddressesType = () => {
  addresses: AddressType[] | null
  refetch: () => void
  isFetching: boolean
  update: (addresses: AddressType[] | null) => void
  isFetchingDefaulted: boolean
  setDefaultAddress: (uid: string) => void
  clear: () => void
}

export const useAddresses: UseAddressesType = () => {
  const dispatch = useAppDispatch()
  const addresses = useAppSelector((state) => state.profile.addresses)
  const { setAddresses } = accountSlice.actions

  const update = useCallback(
    (addresses: AddressType[] | null) => {
      dispatch(setAddresses(addresses))
    },
    [dispatch, setAddresses],
  )

  const clear = useCallback(() => {
    update(null)
  }, [update])

  const { mutate: addressesMutate, isLoading } = useMutation<
    (AddressType & { uid: string })[]
  >(fetchAddresses, {
    onSuccess: (response) => {
      if (!!response) {
        dispatch(setAddresses(response))
      } else {
        dispatch(setAddresses([]))
      }
    },
  })

  const { mutate: setAddressDefaultMutate, isLoading: isLoadingDefaulted } =
    useMutation(fetchSetAddressDefault, {
      onSuccess: (response, request) => {
        if (addresses !== null) {
          dispatch(
            setAddresses(
              [...addresses].map((addr) => {
                return {
                  ...addr,
                  is_default: request.uid === addr.uid,
                }
              }),
            ),
          )
        }
      },
    })

  return {
    addresses: addresses,
    refetch: addressesMutate,
    update: update,
    isFetching: isLoading,
    isFetchingDefaulted: isLoadingDefaulted,
    setDefaultAddress: (uid) => {
      setAddressDefaultMutate({
        uid,
      })
    },
    clear: clear,
  }
}
