import type { FC } from "react";
import { memo, useMemo } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { Button } from "../../../../components/ui/Button/Button";
import { cssButtonLogout } from "../../../../components/ui/Button/StyledButton";
import { Modal } from "../../../../components/ui/Modals/Modal";
import { useAuth } from "../../../../hooks/auth";
import { WordWithInitials } from "../../../../styles/utils/WordWithInitials";
import { ROUTES } from "../../../../utils/constants";
import { ButtonAuthGroup } from "../StyledHeader";
const SignInForm = dynamic(((() => import("./SignInForm").then(mod => mod.SignInForm)) as DynamicOptions), {
  ssr: false
});
export const AuthControls: FC<{
  isShowButtonLogout?: boolean;
}> = memo(({
  isShowButtonLogout = true
}) => {
  const {
    user,
    logout,
    authModalRef
  } = useAuth();
  const {
    fio,
    accessToken
  } = user || {};
  const profileButtonElement = useMemo(() => !fio ? <>Профиль</> : fio.split(" ").map((f, i) => <WordWithInitials key={i} word={f} />), [fio]);
  return <ButtonAuthGroup>
        {!accessToken ? <Modal closeMode={"destroy"} hideOnClickOutside={false} disclosure={<Button ref={authModalRef} variant={"small"} icon={"Lock"}>
                Вход
              </Button>}>
            <SignInForm />
          </Modal> : <>
            <Button variant={"small"} icon={"User"} as={"a"} href={ROUTES.account}>
              {profileButtonElement}
            </Button>
            {isShowButtonLogout && <Button variant={"small"} className={cssButtonLogout} onClick={() => logout()}>
                Выход
              </Button>}
          </>}
      </ButtonAuthGroup>;
});
AuthControls.displayName = "AuthControls";