import { IFindProductsByIdCategory } from "./types"
import { TypeLocalStorage } from "components/Compare/types"

export function findProductsByIdCategory({
  categoryUUID,
  categories,
}: IFindProductsByIdCategory) {
  if (categories) {
    const item = categories.find((item) => item.uuid === categoryUUID)

    if (item) return item.products
  }
  return null
}

export const getCompareStorage = (): TypeLocalStorage | null => {
  const data = localStorage.getItem("compare")
  return data ? JSON.parse(data) : null
}
export const setCompareStorage = (data: TypeLocalStorage | "clear") => {
  if (data === "clear") {
    localStorage.removeItem("compare")
    return
  }
  if (!!data?.keys?.length && data.keys.length > 0) {
    localStorage.setItem("compare", JSON.stringify(data))
  }
}
