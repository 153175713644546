import { forwardRef } from "react"
import StyledButton from "./StyledButton"
import type { ButtonProps } from "./types"

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledButton {...props} ref={ref}>
        {children}
      </StyledButton>
    )
  },
)

Button.displayName = "Button"
export { Button }
