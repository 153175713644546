import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  FC,
} from "react"
import {
  removeAllFromCompares,
  addCompareCategory,
  removeFromCompare,
  addToCompare,
} from "../../store/reducers/accountSlice"
import { useAuth } from "../auth"
import { useAppDispatch, useAppSelector } from "../redux"
import { ContextPropsType } from "./types"
import { useCompareMutations } from "./useCompareMutations"
import {
  findProductsByIdCategory,
  getCompareStorage,
  setCompareStorage,
} from "./utils"

// лимит добавление в сравнение у не авторизованного пользователя
const limiterCompare = 30
const CompareContext = createContext<null | ContextPropsType>(null)

export const Provider: FC = ({ children }) => {
  const [isShowToLogin, setIsShowToLogin] = useState(false)
  const { isAuth, isInit } = useAuth()
  const dispatch = useAppDispatch()

  const { toggleProgress, keys, categories } = useAppSelector(
    ({ profile }) => profile.compare,
  )

  const quantity = useMemo(() => (keys !== null ? keys.length : 0), [keys])

  const {
    // тут все запросы вынесены
    removeFromComparesAllMutate,
    removeFromCompareMutate,
    getCompareUserMutate,
    addToCompareMutate,
  } = useCompareMutations({ bindCompare })

  const hideToLogin = (): void => {
    setIsShowToLogin(false)
  }

  const addToCompareHandler = useCallback(
    //common fn
    ({
      productUUID,
      categoryUUID,
    }: {
      productUUID: string[]
      categoryUUID: string
    }) => {
      if (isAuth) {
        addToCompareMutate({
          products: productUUID.join(","),
          categoryUUID: categoryUUID,
        })
      } else {
        const storage = getCompareStorage()

        if (storage && storage?.keys && storage?.keys.length > limiterCompare) {
          // если не авторизованный добавил в сравнение 30(limiterCompare) товаров больше не добавляем, предлагаем авторизоваться
          setIsShowToLogin(true)
          return
        }

        dispatch(
          //common fn
          addToCompare({
            productUUID: productUUID,
            categoryUUID: categoryUUID,
          }),
        )
      }
    },
    [addToCompare, dispatch, addToCompareMutate, isAuth],
  )

  const removeFromCompareHandler = useCallback(
    (productUUID: string, categoryUUID?: string) => {
      if (isAuth) {
        removeFromCompareMutate({
          removeProductFromCategory: categoryUUID,
          products: productUUID,
        })
      } else {
        setCompareStorage("clear")

        dispatch(
          removeFromCompare({
            removeProductFromCategory: categoryUUID,
            products: productUUID,
            uuidCategory: "",
          }),
        )
      }
    },
    [dispatch, isAuth, removeFromCompareMutate, categories],
  )

  const removeFromComparesCategoryHandler = useCallback(
    (categoryUUID: string) => {
      const arrID = findProductsByIdCategory({ categories, categoryUUID })
      if (!arrID) return

      if (isAuth) {
        removeFromCompareMutate({
          products: arrID.join(","),
          uuidCategory: categoryUUID,
        })
      } else {
        setCompareStorage("clear")
        dispatch(
          removeFromCompare({
            uuidCategory: categoryUUID,
            products: arrID.join(","),
          }),
        )
      }
    },
    [dispatch, isAuth, removeFromCompareMutate, categories],
  )

  const removeFromComparesAllHandler = useCallback(() => {
    if (isAuth) {
      removeFromComparesAllMutate()
    } else {
      setCompareStorage("clear")
      dispatch(removeAllFromCompares())
    }
  }, [dispatch, isAuth, removeFromComparesAllMutate, removeAllFromCompares])

  function bindCompare() {
    const compareBeforeAuth = getCompareStorage()

    if (
      compareBeforeAuth !== null &&
      !!compareBeforeAuth.keys &&
      compareBeforeAuth.keys.length > 0
    ) {
      addToCompareHandler({
        productUUID: compareBeforeAuth.keys,
        categoryUUID: "",
      }) //common fn
      setCompareStorage("clear") //fn not auth user
    }
  }

  useEffect(() => {
    // only not auth user
    if (isInit && !isAuth) {
      setCompareStorage({ keys: keys, categories: categories })
    }
  }, [keys, isAuth, isInit, categories])

  useEffect(() => {
    if (isInit) {
      if (!isAuth) {
        const savedCompare = getCompareStorage()

        savedCompare &&
          !!savedCompare.categories && //common fn
          dispatch(addCompareCategory(savedCompare.categories))
      } else {
        getCompareUserMutate() // only auth user
      }
    }
  }, [
    getCompareUserMutate,
    addCompareCategory,
    getCompareStorage,
    dispatch,
    isAuth,
    isInit,
  ])

  const contextValue = useMemo(
    () => ({
      removeCategory: removeFromComparesCategoryHandler,
      removeAll: removeFromComparesAllHandler,
      remove: removeFromCompareHandler,
      getCompare: getCompareUserMutate,
      fetchingUUIds: toggleProgress,
      add: addToCompareHandler,
      quantity: quantity,
      isShowToLogin,
      hideToLogin,
      keys,
    }),
    [
      removeFromComparesCategoryHandler,
      removeFromComparesAllHandler,
      removeFromCompareHandler,
      getCompareUserMutate,
      addToCompareHandler,
      toggleProgress,
      isShowToLogin,
      hideToLogin,
      quantity,
      keys,
    ],
  )

  return (
    <CompareContext.Provider value={contextValue}>
      {children}
    </CompareContext.Provider>
  )
}

export const useCompare = (
  productsUUID?: string,
): Omit<ContextPropsType, "fetchingUUIds"> & {
  isFetching: boolean
  isCompare: boolean
} => {
  const compareContext = useContext(CompareContext)

  if (compareContext === null) {
    throw new Error("User context have to be provided")
  }

  const isCompare = useMemo(
    () => (compareContext.keys || []).some((item) => item === productsUUID),
    [compareContext.keys, productsUUID],
  )

  const isFetching = useMemo(
    () =>
      (compareContext.fetchingUUIds || []).some(
        (item) => item === productsUUID,
      ),
    [compareContext.fetchingUUIds, productsUUID],
  )

  return {
    removeCategory: compareContext.removeCategory,
    removeAll: compareContext.removeAll,
    remove: compareContext.remove,

    isShowToLogin: compareContext.isShowToLogin,
    hideToLogin: compareContext.hideToLogin,

    getCompare: compareContext.getCompare,
    quantity: compareContext.quantity,

    keys: compareContext.keys,
    add: compareContext.add,

    isFetching,
    isCompare,
  }
}
