import { FC, useMemo } from "react"
import { LinkVariants } from "../../../../components/ui/Link/constants"
import Navigation from "../../../../components/ui/Navigation/Navigation"
import { useAppSelector } from "../../../../hooks/redux"
import { NavType } from "../../../../types/types"
import { ROUTES } from "../../../../utils/constants"

const navItems = (isAuth: boolean | null): NavType => {
  return {
    title: "",
    items: [
      { title: "О компании", path: ROUTES.about },
      { title: "Как купить", path: ROUTES.help },
      { title: "Магазины", path: ROUTES.stores },
      { title: "Контакты", path: ROUTES.contacts },
      {
        title: !!isAuth ? "История заказов" : "",
        path: !!isAuth ? `${ROUTES.account}${ROUTES.historyOrders}` : "",
      },
      {
        title: "FAQ",
        path: "",
        isLink: false,
        subItems: [
          { title: "Помощь", path: ROUTES.help },
          { title: "Вопрос-ответ", path: ROUTES.faq },
          { title: "Условия оплаты", path: ROUTES.paymentTerm },
          { title: "Условия доставки", path: ROUTES.deliveryTerm },
          { title: "Гарантия на товар", path: ROUTES.productWarranty },
        ],
      },
    ],
  }
}

const NavigationStatic: FC = () => {
  const { isAuth } = useAppSelector(({ profile }) => profile)
  const { items, title } = useMemo(() => navItems(isAuth), [isAuth])

  return (
    <Navigation
      items={items}
      title={title}
      variant={LinkVariants.GRAY_DARK_TO_BLACK}
    />
  )
}

export { NavigationStatic }
