import { useCallback } from "react"
import { useQuery } from "react-query"
import { fetchShops } from "../../api/checkoutAPI"
import {
  setActivatedShop,
  setSelectedShop,
  setShops,
} from "../../store/reducers/shopSlice"
import { useAppDispatch, useAppSelector } from "../redux"
import { UseShopsType } from "./types"

const useShops: UseShopsType = () => {
  const {
    shops: shopsApp,
    activated,
    selected,
  } = useAppSelector(({ shop }) => shop)
  const dispatch = useAppDispatch()

  const { refetch: refetchShops, isLoading: isFetching } = useQuery(
    ["shops"],
    () => fetchShops(),
    {
      staleTime: Infinity,
      onSuccess: (response) => {
        dispatch(setShops(response || null))
      },
    },
  )

  const requestShops = async () => {
    await refetchShops()
  }

  const updateActivatedShop = useCallback(
    (shop: string | null) => {
      dispatch(setActivatedShop(shop))
    },
    [dispatch],
  )

  const updateSelectedShop = useCallback(
    (shop: string | null) => {
      dispatch(setSelectedShop(shop))
    },
    [dispatch],
  )

  return {
    shops: shopsApp,
    isFetching: isFetching,
    requestShops: requestShops,
    activatedShop: activated,
    setActivatedShop: updateActivatedShop,
    selectedShop: selected,
    setSelectedShop: updateSelectedShop,
  }
}

export { useShops }
