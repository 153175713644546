import {
  createContext,
  FC,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { ServerResponse } from "http"
import { GetServerSidePropsContext } from "next"
import { useQueries } from "react-query"
import Cookies from "universal-cookie"
import { parseCookies } from "universal-cookie/lib/utils"
import { ResponseCustomerDataList } from "../../contracts/contracts"
import { fetchCustomerData, fetchLastOrder } from "../api/checkoutAPI"
import { NotificationType } from "../components/Notification/Notification"
import {
  clearFavorites,
  setCustomer,
  setIsAuth,
  setIsInit,
  setLastOrder,
  setUserProfile,
} from "../store/reducers/accountSlice"
import { clearCart } from "../store/reducers/cartSlice"
import { LastOrderReturnType } from "../types/types"
import {
  getExpireOneYear,
  scrollBodyEnable,
  setCheckoutToStorage,
  setLeadHitStockId,
} from "../utils/helpers"
import { useAddresses } from "./addresses"
import { usePayers } from "./payers"
import { useAppDispatch, useAppSelector } from "./redux"

export type User = {
  accessToken: string | null
  refreshToken: string | null
  email?: string | null
  phone?: string | null
  cart: string | null
  fio?: string | null
  isAdmin?: boolean
}

type ContextType = {
  login: (user: User, notification?: NotificationType) => void
  logout: () => void
  user: Required<User> | null
  isAuth: boolean
  isInit: boolean
  notification: NotificationType | null
  setNotification: (notification: NotificationType | null) => void
  authModalRef: RefObject<HTMLButtonElement>
  showModalAuth: () => void
  updateUser: (user: User | null) => void
  customer: null | ResponseCustomerDataList
  lastOrder: null | LastOrderReturnType
}

const cookies = new Cookies()

const COOKIE_USER_KEY = "user"

export const getServerUser = ({
  req,
}: Pick<GetServerSidePropsContext, "req">): User | null => {
  const cookie = parseCookies(req.headers.cookie)
  const user = cookie[COOKIE_USER_KEY]
  return !!user ? (typeof user === "string" ? JSON.parse(user) : user) : null
}

export const getClientUser = (): User | null => {
  const user = cookies.get(COOKIE_USER_KEY)
  return user || null
}

export const setUser = (user: User | null, res?: ServerResponse): void => {
  if (user === null) {
    if (res !== undefined) {
      res.setHeader("Set-Cookie", `${COOKIE_USER_KEY}=null; Path=/; Max-Age=0`)
    } else {
      cookies.remove(COOKIE_USER_KEY, { path: "/" })
    }
  } else {
    if (res !== undefined) {
      res.setHeader(
        "Set-Cookie",
        `${COOKIE_USER_KEY}=${encodeURIComponent(
          JSON.stringify(user),
        )}; Path=/; Expires=${getExpireOneYear().toUTCString()}`,
      )
    } else {
      cookies.set(COOKIE_USER_KEY, JSON.stringify(user), {
        path: "/",
        expires: getExpireOneYear(),
      })
    }
  }
}

// если пользователь авторизовываеться отправляем uuid просмотренных товаров

const UserContext = createContext<null | ContextType>(null)

type LoginHandleType = (
  user: User,
  notification?: NotificationType,
  withReload?: boolean,
) => void

export const Provider: FC = ({ children }) => {
  const { user, isAuth, isInit, customer, lastOrder } = useAppSelector(
    (state) => state.profile,
  )
  const { businessAreas } = useAppSelector((state) => state.catalog)
  const dispatch = useAppDispatch()
  const [notificationContent, setNotificationContent] =
    useState<NotificationType | null>(null)
  const authModalRef = useRef<HTMLButtonElement>(null)

  const { clear: clearAddresses } = useAddresses()
  const { clear: clearPayers } = usePayers()

  // запросы при авторизации
  useQueries([
    {
      queryKey: ["customer"],
      queryFn: fetchCustomerData,
      staleTime: Infinity,
      enabled: isAuth,
      onSuccess: (response: ResponseCustomerDataList) => {
        dispatch(setCustomer(response || null))
      },
    },
    {
      queryKey: ["lastOrder"],
      queryFn: fetchLastOrder,
      staleTime: Infinity,
      enabled: isAuth,
      onSuccess: (response: LastOrderReturnType) => {
        dispatch(setLastOrder(response || null))
      },
    },
  ])

  const login: LoginHandleType = useCallback(
    (user, notification) => {
      scrollBodyEnable()
      dispatch(setUserProfile(user))
      dispatch(clearFavorites())
      dispatch(setIsAuth(true))
      if (notification !== undefined) {
        setNotificationContent(notification)
      }
    },
    [dispatch],
  )

  const logout = useCallback(() => {
    scrollBodyEnable()
    dispatch(setIsAuth(false))
    dispatch(setUserProfile(null))
    dispatch(clearFavorites())
    dispatch(clearCart())
    setCheckoutToStorage(null)
    clearAddresses()
    clearPayers()
  }, [clearAddresses, clearPayers, dispatch])

  const showModalAuth = useCallback(() => {
    authModalRef?.current?.click()
  }, [])

  useEffect(() => {
    const storedUser = getClientUser()
    if (storedUser !== null) {
      login(storedUser, undefined)
    } else {
      setCheckoutToStorage(null)
    }
    dispatch(setIsInit(true))
  }, [dispatch, login])

  useEffect(() => {
    if (!isInit || !isAuth) {
      return
    }
    if (businessAreas === null || businessAreas.length === 0 || !isAuth) {
      setLeadHitStockId(null)
      return
    }

    const _getBusinessAreaUuid = (foundName: string) => {
      const businessArea = businessAreas.find(
        (ba) => (ba.name || "").toLowerCase() === foundName.toLowerCase(),
      )
      return businessArea?.uuid || null
    }

    // в lastOrder может не быть или
    // может быть своя сфера бизнеса,
    // которая не может быть в stock id в фиде anyquery
    // + хранится название сферы бизнеса с пробелами
    const lastOrderBusinessAreaName = lastOrder?.bussiness_area || null

    setLeadHitStockId(
      lastOrderBusinessAreaName !== null
        ? _getBusinessAreaUuid(lastOrderBusinessAreaName)
        : null,
    )
  }, [businessAreas, isAuth, isInit, lastOrder?.bussiness_area])

  const contextValue = useMemo(
    () => ({
      login,
      logout,
      user,
      isAuth,
      isInit,
      notification: notificationContent,
      authModalRef: authModalRef,
      showModalAuth: showModalAuth,
      updateUser: (user: User | null) => {
        dispatch(setUserProfile(user))
      },
      setNotification: setNotificationContent,
      customer,
      lastOrder,
    }),
    [
      login,
      logout,
      user,
      isAuth,
      isInit,
      notificationContent,
      authModalRef,
      showModalAuth,
      dispatch,
      setNotificationContent,
      customer,
      lastOrder,
    ],
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

export const useAuth = (): ContextType => {
  const userContext = useContext(UserContext)

  if (userContext === null) {
    throw new Error("User context have to be provided")
  }

  return {
    user: userContext.user,
    login: userContext.login,
    logout: userContext.logout,
    isAuth: userContext.isAuth,
    isInit: userContext.isInit,
    notification: userContext.notification,
    authModalRef: userContext.authModalRef,
    showModalAuth: userContext.showModalAuth,
    updateUser: userContext.updateUser,
    setNotification: userContext.setNotification,
    customer: userContext.customer,
    lastOrder: userContext.lastOrder,
  } as const
}
