import { useCallback, useEffect } from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { fetchSendRecentProducts } from "../../api/accountAPI"
import {
  addExcludeWatchedRecentProduct,
  removeExcludeWatchedRecentProduct,
  setWatchedRecentProductsIds,
} from "../../store/reducers/accountSlice"
import { useAppSelector } from "../redux"
import {
  getWatchedRecentProductsStorage,
  setWatchedRecentProductsStorage,
} from "./helpers"
import { AddProductToRecentFnType, UseWatchedRecentProductType } from "./types"

const lastViewProductsUUIds = getWatchedRecentProductsStorage()

export const useWatchedRecent: UseWatchedRecentProductType = ({
  uuid,
} = {}) => {
  const dispatch = useDispatch()
  const {
    isAuth,
    isInit,
    history: {
      watchedRecentProducts: { keys: ids },
    },
  } = useAppSelector(({ profile }) => profile)

  const { mutate: sendRecentProductsMutate } = useMutation(
    fetchSendRecentProducts,
    {
      onSuccess: (response) => {
        dispatch(
          setWatchedRecentProductsIds(
            [...(response?.products || [])].reverse(),
          ),
        )
      },
    },
  )

  // при посещении страницы товара
  // запишется в память
  const addProductToRecent: AddProductToRecentFnType = useCallback(
    ({ uuid }) => {
      if (!uuid) {
        return
      }
      if (isAuth) {
        sendRecentProductsMutate({
          products: `${uuid}`,
        })
      } else {
        dispatch(setWatchedRecentProductsIds([`${uuid}`]))
      }
    },
    [dispatch, isAuth, sendRecentProductsMutate],
  )

  // при загрузке страницы
  // заинитятся данные
  const initSet = useCallback(() => {
    if (isInit) {
      if (isAuth) {
        sendRecentProductsMutate({
          products: [...lastViewProductsUUIds].reverse().join(","),
        })
      } else {
        dispatch(setWatchedRecentProductsIds(lastViewProductsUUIds))
      }
    }
  }, [dispatch, isAuth, isInit, sendRecentProductsMutate])

  // при наличии входящего парамета uuid
  // понимаем что находимся на детальной товара
  // значит этот товар не должен быть отображен в ранее просмотренных
  // на его же детальной
  useEffect(() => {
    if (!uuid) {
      return
    }

    dispatch(addExcludeWatchedRecentProduct([uuid]))

    return () => {
      dispatch(removeExcludeWatchedRecentProduct([uuid]))
    }
  }, [dispatch, uuid])

  useEffect(() => {
    addProductToRecent({ uuid: uuid || undefined })
  }, [uuid, addProductToRecent])

  useEffect(() => {
    if (isInit) {
      if (!isAuth) {
        setWatchedRecentProductsStorage(ids)
      }
    }
  }, [ids, isAuth, isInit])

  return {
    init: initSet,
  }
}
