import {
  BaseHTMLAttributes,
  FC,
  FunctionComponentElement,
  useEffect,
  useRef,
  cloneElement,
  memo,
} from "react"
import { Placement } from "@popperjs/core"
import { usePopoverState } from "reakit/Popover"
import { ANIMATED_TRANSITION_DEFAULT } from "../../../styles/utils/vars"
import {
  PopoverContainer,
  StyledPopover,
  StyledPopoverArrow,
  StyledPopoverDisclosure,
  StyledPopoverInner,
} from "./StyledPopover"

export type PopoverPropsType = {
  disclosure: FunctionComponentElement<unknown>
  isShow?: boolean
  isStylingIconDisclosure?: boolean
  size?: "small" | "default"
  isHiddenDisclosure?: boolean
  animatedTransition?: number
  baseId?: string
  hideOnClickOutside?: boolean
  withHover?: boolean
  withArrow?: boolean
  offset?: [string | number, string | number]
  placement?: Placement
}

export const Popover: FC<
  PopoverPropsType & BaseHTMLAttributes<HTMLDivElement>
> = memo(
  ({
    disclosure,
    isShow,
    isStylingIconDisclosure = true,
    size = "default",
    isHiddenDisclosure,
    animatedTransition = ANIMATED_TRANSITION_DEFAULT,
    hideOnClickOutside,
    withHover = false,
    withArrow = false,
    offset,
    placement,
    ...props
  }) => {
    const popover = usePopoverState({
      animated: animatedTransition,
      gutter: 16,
      placement: placement || "bottom-start",
      unstable_offset: offset,
    })

    const element = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      if (isShow !== undefined) {
        if (isShow) {
          popover.show()
        } else {
          popover.hide()
        }
      }
    }, [isShow])

    return (
      <PopoverContainer
        data-styling-icon={isStylingIconDisclosure}
        data-size={size}
        data-hidden={isHiddenDisclosure}
        {...props}
        onMouseEnter={() => {
          if (!withHover) {
            return
          }
          popover.show()
        }}
        onMouseLeave={() => {
          if (!withHover) {
            return
          }
          popover.hide()
        }}
      >
        <StyledPopoverDisclosure
          {...popover}
          ref={disclosure.ref}
          {...(disclosure.props || {})}
        >
          {(disclosureProps) => cloneElement(disclosure, disclosureProps)}
        </StyledPopoverDisclosure>
        <StyledPopover
          {...popover}
          aria-label="popover"
          tabIndex={0}
          hideOnClickOutside={hideOnClickOutside}
          unstable_autoFocusOnHide={false}
          unstable_finalFocusRef={element}
        >
          <StyledPopoverInner ref={element}>
            {withArrow && <StyledPopoverArrow {...popover} />}
            {props.children}
          </StyledPopoverInner>
        </StyledPopover>
      </PopoverContainer>
    )
  },
)

Popover.displayName = "Popover"
