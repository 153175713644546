import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useSearch } from "../../../hooks/search/search";
import { cssIsActive } from "../../../styles/utils/Utils";
import { Button } from "../../ui/Button/Button";
import { Field } from "../../ui/Field/Field";
import type { AutoCompletePropsType } from "../AutoComplete";
import { StyledAutoCompleteOverlay } from "../AutoComplete/StyledAutoComplete";
import { cssBackIsActive, cssSearchSubmit, StyledField, StyledFieldForm } from "./StyledField";
const SearchAutoComplete = dynamic(((() => import("../AutoComplete").then(mod => mod.AutoComplete)) as DynamicOptions<AutoCompletePropsType>));
const SearchField: FC<{
  isResponsiveMode?: boolean;
  onBackCb?: () => void;
  isFocusInit?: boolean;
}> = memo(({
  isResponsiveMode = false,
  onBackCb,
  isFocusInit = false
}) => {
  const {
    updateQueryText,
    appendToHistory,
    currentText,
    correction,
    query,
    updateSearch,
    hideAutoComplete,
    showAutoComplete,
    isShowAutoComplete,
    setIsSubmitting,
    inputRef,
    autoComplete: {
      ref
    },
    clearAutoComplete,
    inputFocus
  } = useSearch();
  const router = useRouter();
  const {
    handleSubmit,
    control,
    setValue,
    formState: {
      submitCount,
      isSubmitting
    }
  } = useForm<{
    query: string;
  }>({
    defaultValues: {
      query: currentText || ""
    }
  });
  useEffect(() => {
    if (isFocusInit) {
      inputFocus();
    }
  }, [inputFocus, isFocusInit]);
  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);
  const onSubmit = handleSubmit(data => {
    hideAutoComplete();
    inputRef?.current?.blur();
    if (data.query.length > 0) {
      appendToHistory({
        query: data.query
      });
    }
    updateSearch({
      query: data.query,
      withReset: true
    });
  });
  useEffect(() => {
    setValue("query", currentText || "", {
      shouldDirty: true,
      shouldTouch: true
    });
  }, [currentText, setValue]);
  useEffect(() => {
    if (correction !== null && correction !== currentText && query !== null && query === currentText) {
      setValue("query", correction || "", {
        shouldDirty: false,
        shouldTouch: false
      });
    }
  }, [correction, setValue, currentText, submitCount, query]);
  const mobileButtonElement = useMemo(() => isResponsiveMode && <Button variant={"box"} icon={isShowAutoComplete ? "X" : "ArrowLeft"} type={"button"} className={cx(isShowAutoComplete && cssBackIsActive)} onClick={() => {
    if (onBackCb !== undefined) {
      onBackCb();
    } else {
      if (isShowAutoComplete) {
        hideAutoComplete();
      } else {
        void router.push("/", undefined, {
          shallow: false
        });
      }
    }
  }} />, [isResponsiveMode, isShowAutoComplete]);
  const onChangeFieldHandle = useCallback((e: ChangeEvent<HTMLInputElement>) => (field: ControllerRenderProps<{
    query: string;
  }, "query">) => {
    field.onChange(e);
    updateQueryText(e.target.value);
  }, [updateQueryText]);
  const onClickFieldHandle = useCallback(() => {
    if (isShowAutoComplete) {
      return;
    }
    showAutoComplete();
  }, [isShowAutoComplete, showAutoComplete]);
  return <>
      <StyledField ref={ref}>
        <StyledFieldForm onSubmit={onSubmit}>
          {mobileButtonElement}

          <Controller control={control} name={"query"} render={({
          field
        }) => {
          return <Field {...field} value={field.value} name={"query"} id={"id-search"} placeholder={"Например, стакан ..."} aria-label={"Поиск"} type={"text"} variant={"input"} withAnimatingLabel={false} withButton iconButton={isResponsiveMode ? undefined : "Search"} className={"searchField"} classNameButton={cssSearchSubmit} textButton={isResponsiveMode ? "Найти" : undefined} variantButton={isResponsiveMode ? "filled" : undefined} withClean={!!field.value?.length} cleanCb={clearAutoComplete} onChange={e => {
            onChangeFieldHandle(e)(field);
          }} onFocus={onClickFieldHandle} ref={inputRef} />;
        }} />
        </StyledFieldForm>

        <SearchAutoComplete onHide={() => {
        hideAutoComplete();
      }} fieldRef={ref} selectQuery={(query: string) => {
        setValue("query", query, {
          shouldDirty: true,
          shouldTouch: true
        });
        void onSubmit();
      }} showAllResults={() => {
        void onSubmit();
      }} />
      </StyledField>
      <StyledAutoCompleteOverlay className={cx(isShowAutoComplete && cssIsActive)} onClick={hideAutoComplete} />
    </>;
});
SearchField.displayName = "SearchField";
export { SearchField };