import { useState } from "react";
import type { AppContext } from "next/app";
import Head from "next/head";
import { Router } from "next/router";
import NProgress from "nprogress"; //nprogress module
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import { Provider as ReakitIdProvider } from "reakit";
import { AppScripts } from "../components/AppScripts";
import { Fonts } from "../components/Fonts";
import { AuthGuard } from "../hoc/AuthGuard";
import { Provider as AppProvider } from "../hooks/app";
import { Provider as UserProvider } from "../hooks/auth";
import { Provider as CartProvider } from "../hooks/cart/cart";
import { Provider as CompareProvider } from "../hooks/compare/useCompare";
import { Provider as FavoritesProvider } from "../hooks/favorites";
import { usePreserveScroll } from "../hooks/preserveScroll";
import { Default } from "../layouts/Default/Default";
import { store } from "../store/store";
import { breakpoints, colors, fontDefault, fontSizeDefault } from "../styles/utils/vars";
import type { AppPropsWithLayout, PagePropsType } from "../types/types";
import { getExpireOneYear, getIp, getIpCookieEntry } from "../utils/helpers";
import "styles/scss/globals.scss";
import { TITLE_DEFAULT } from "../components/Meta/constants";
NProgress.configure({
  showSpinner: false
});
Router.events.on("routeChangeStart", () => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
});
Router.events.on("routeChangeError", () => {
  NProgress.done();
});
function GrosterApp({
  Component,
  pageProps
}: AppPropsWithLayout): JSX.Element {
  const withLayout = Component.getLayout || (page => <Default>{page}</Default>);
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        retry: 1,
        retryDelay: 100,
        keepPreviousData: true
      },
      mutations: {
        retry: 1,
        retryDelay: 100
      }
    }
  }));
  usePreserveScroll();
  return <>
      <style jsx global={true}>{`
        #__next {
          height: 100%;
          position: relative;
        }

        html {
          font-family: ${fontDefault};
          color: ${colors.black};
          font-size: ${fontSizeDefault};
          height: 100%;
        }

        html,
        body {
          font-size: 16px;
          line-height: 187%;
          font-weight: 500;
          padding: 0;
          margin: 0;
          -webkit-text-size-adjust: none;
          text-rendering: optimizeSpeed;
          // обязательно width 100% при открытии модальных окон
          // - body становится fixed, на ios все ломается
          width: 100%;
        }

        html #nprogress .bar {
          background: ${colors.brand.purple};
          height: 8px;
        }

        html #nprogress .peg {
          box-shadow: 0 0 10px ${colors.brand.purple},
            0 0 5px ${colors.brand.purpleDarken};
        }

        html #nprogress .spinner-icon {
          border-top-color: ${colors.brand.purple};
          border-left-color: ${colors.brand.purple};
        }

        @media (max-width: ${breakpoints.md}) {
          html #nprogress .bar {
            background: ${colors.white};
          }

          html #nprogress .peg {
            box-shadow: 0 0 10px ${colors.white}, 0 0 5px ${colors.white};
          }
        }

        body {
          padding-bottom: env(safe-area-inset-bottom);
          -webkit-overflow-scrolling: touch;
          position: relative;
          top: 0;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
          outline: none;
        }

        * {
          outline: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        img {
          max-width: 100%;
        }

        a {
          text-decoration: none;
          color: ${colors.brand.purple};
          -webkit-tap-highlight-color: transparent;
        }

        a:hover,
        a:active {
          color: ${colors.brand.purpleDarken};
        }

        label {
          -webkit-tap-highlight-color: transparent;
        }

        .grecaptcha-badge {
          z-index: 10;
          display: none !important;
        }

        input:focus,
        textarea:focus,
        select:focus,
        button:focus,
        img:focus {
          outline: none;
        }

        jdiv[class^="wrap"],
        jdiv[class^="globalClass"] > jdiv {
          z-index: 100 !important;
        }

        jdiv.__jivoMobileButton {
          margin-bottom: 100px !important;
        }
      `}</style>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>{TITLE_DEFAULT}</title>
        <Fonts />
      </Head>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={(pageProps as PagePropsType)?.dehydratedState}>
            <ReakitIdProvider>
              <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""} scriptProps={{
              appendTo: "body",
              id: "reCaptchaG",
              async: true
            }}>
                <AppProvider>
                  <UserProvider>
                    <FavoritesProvider>
                      <CompareProvider>
                        <CartProvider>
                          {Component.requireAuth ? <AuthGuard>
                              {withLayout(<Component {...pageProps} />)}
                            </AuthGuard> : <>{withLayout(<Component {...pageProps} />)}</>}
                        </CartProvider>
                      </CompareProvider>
                    </FavoritesProvider>
                  </UserProvider>
                </AppProvider>
              </GoogleReCaptchaProvider>
            </ReakitIdProvider>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
      <AppScripts />
    </>;
}
GrosterApp.getInitialProps = async (appContext: AppContext) => {
  const {
    ctx: {
      req,
      res,
      pathname,
      query
    },
    Component
  } = appContext;
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({
      ...appContext,
      pathname: pathname,
      query: query
    });
  }
  let makedCookies = res?.getHeader("Set-Cookie") || "";
  const ip = getIp(req);
  if (ip !== undefined) {
    const ipCookie = await getIpCookieEntry(req, ip);
    if (ipCookie !== undefined) {
      makedCookies += ipCookie;
    }
  }

  // Content-Disposition : form-data; name="field_value"
  makedCookies += `host=${encodeURIComponent(req?.headers.host || "")}; Path=/; Expires=${getExpireOneYear().toUTCString()}`;
  res?.setHeader("Set-Cookie", makedCookies);
  return {
    pageProps: pageProps
  };
};
export default GrosterApp;