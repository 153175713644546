import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useMutation } from "react-query"
import {
  fetchAddToFavorites,
  fetchFavorites,
  fetchRemoveAllToFavorites,
  fetchRemoveToFavorites,
} from "../api/favoritesAPI"
import {
  addToFavorites,
  removeFromFavorites,
  removeAllFromFavorites,
  toggleFavoritesProgress,
  removeFavoritesAllProgress,
} from "../store/reducers/accountSlice"
import { useAuth } from "./auth"
import { useAppDispatch, useAppSelector } from "./redux"

const getFavoritesStorage = (): string[] | null => {
  const favoritesKeys = localStorage.getItem("favorites")
  return favoritesKeys ? JSON.parse(favoritesKeys) : null
}

const setFavoritesStorage = (favoritesKeys: string[] | null) => {
  if (favoritesKeys == null) {
    return
  }

  if (favoritesKeys.length < 1) {
    localStorage.removeItem("favorites")
  } else {
    localStorage.setItem("favorites", JSON.stringify(favoritesKeys))
  }
}

type ContextPropsType = {
  add: (productUUID: string[]) => void
  remove: (productUUID: string) => void
  favoritesKeys: null | string[]
  hideToLogin: () => void
  fetchingUUIds: string[]
  isShowToLogin: boolean
  removeAll: () => void
  quantity: number
}

const FavoritesContext = createContext<null | ContextPropsType>(null)

export const Provider: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const { keys: favoritesKeys, toggleProgress: toggleProgressList } =
    useAppSelector((state) => state.profile.favorites)
  const { isAuth, isInit } = useAuth()
  const [isShowToLogin, setIsShowToLogin] = useState(false)

  const addFavoritesToState = useCallback(
    (products: string[]) => {
      dispatch(addToFavorites(products))
    },
    [dispatch],
  )

  const { mutate: addToFavoritesMutate } = useMutation(fetchAddToFavorites, {
    onMutate: (request) => {
      dispatch(
        toggleFavoritesProgress({
          isFetching: true,
          product: request.products,
        }),
      )
    },
    onSuccess: (response, request) => {
      addFavoritesToState(request.products.split(","))
    },
    onSettled: (data, error, request) => {
      dispatch(
        toggleFavoritesProgress({
          isFetching: false,
          product: request.products,
        }),
      )
    },
  })

  const { mutate: removeFromFavoritesMutate } = useMutation(
    fetchRemoveToFavorites,
    {
      onMutate: (request) => {
        dispatch(
          toggleFavoritesProgress({
            isFetching: true,
            product: request.product,
          }),
        )
      },
      onSuccess: (response, request) => {
        dispatch(removeFromFavorites(request.product))
      },
      onSettled: (data, error, request) => {
        dispatch(
          toggleFavoritesProgress({
            isFetching: false,
            product: request.product,
          }),
        )
      },
    },
  )

  const { mutate: removeAllFromFavoritesMutate } = useMutation(
    fetchRemoveAllToFavorites,
    {
      onMutate: () => {
        dispatch(removeFavoritesAllProgress({ isFetching: true }))
      },
      onSuccess: () => {
        dispatch(removeAllFromFavorites())
      },
      onSettled: () => {
        dispatch(removeFavoritesAllProgress({ isFetching: false }))
      },
    },
  )

  const { mutate: getFavoritesUserMutate } = useMutation(fetchFavorites, {
    onSuccess: (response) => {
      addFavoritesToState(response !== null ? response.products : [])
      bindFavorites()
    },
  })

  const addToFavoritesHandler = useCallback(
    (productUUIDs: string[]) => {
      if (isAuth) {
        addToFavoritesMutate({ products: productUUIDs.join(",") })
      } else {
        addFavoritesToState(productUUIDs)

        // Старт если юзер не авторизован и добавил товар в избранное, после 1 клика предлагаем ему авторизоваться
        const keyOfferToLogIn = sessionStorage.getItem("offerToLogIn")
        const isOfferToLogIn = !!keyOfferToLogIn
          ? JSON.parse(keyOfferToLogIn)
          : false

        if (!isOfferToLogIn) {
          sessionStorage.setItem("offerToLogIn", "true")
          setIsShowToLogin(true)
        }
        // Конец
      }
    },
    [addFavoritesToState, addToFavoritesMutate, isAuth],
  )

  const removeFromFavoritesHandler = useCallback(
    (productUUID: string) => {
      if (isAuth) {
        removeFromFavoritesMutate({ product: productUUID })
      } else {
        localStorage.removeItem("favorites")
        dispatch(removeFromFavorites(productUUID))
      }
    },
    [dispatch, isAuth, removeFromFavoritesMutate],
  )

  const removeAllFromFavoritesHandler = useCallback(() => {
    if (isAuth) {
      removeAllFromFavoritesMutate()
    } else {
      setFavoritesStorage([])
      dispatch(removeAllFromFavorites())
    }
  }, [dispatch, isAuth, removeFromFavoritesMutate])

  const bindFavorites = useCallback(() => {
    const favoritesBeforeAuth = getFavoritesStorage()
    if (favoritesBeforeAuth !== null && favoritesBeforeAuth.length > 0) {
      addToFavoritesHandler(favoritesBeforeAuth)
      setFavoritesStorage([])
    }
  }, [addToFavoritesHandler])

  const quantity = useMemo(
    () => (favoritesKeys !== null ? favoritesKeys.length : 0),
    [favoritesKeys],
  )

  const hideToLogin = () => {
    setIsShowToLogin(false)
  }

  useEffect(() => {
    if (isInit) {
      if (!isAuth) {
        setFavoritesStorage(favoritesKeys)
      }
    }
  }, [favoritesKeys, isAuth, isInit])

  useEffect(() => {
    if (isInit) {
      if (!isAuth) {
        const savedFavorites = getFavoritesStorage()
        if (savedFavorites) {
          addFavoritesToState(savedFavorites)
        }
      } else {
        getFavoritesUserMutate()
      }
    }
  }, [
    addFavoritesToState,
    addToFavoritesHandler,
    dispatch,
    getFavoritesUserMutate,
    isAuth,
    isInit,
  ])

  const contextValue = useMemo(
    () => ({
      add: addToFavoritesHandler,
      remove: removeFromFavoritesHandler,
      removeAll: removeAllFromFavoritesHandler,
      favoritesKeys,
      quantity: quantity,
      fetchingUUIds: toggleProgressList,
      isShowToLogin,
      hideToLogin,
    }),
    [
      addToFavoritesHandler,
      removeFromFavoritesHandler,
      favoritesKeys,
      toggleProgressList,
      quantity,
      isShowToLogin,
    ],
  )

  return (
    <FavoritesContext.Provider value={contextValue}>
      {children}
    </FavoritesContext.Provider>
  )
}

export const useFavorites = (
  productsUUID?: string,
): Omit<ContextPropsType, "fetchingUUIds"> & {
  isFavorites: boolean
  isFetching: boolean
} => {
  const favoritesContext = useContext(FavoritesContext)

  if (favoritesContext === null) {
    throw new Error("User context have to be provided")
  }

  const isFavorites = useMemo(
    () =>
      (favoritesContext.favoritesKeys || []).some(
        (item) => item === productsUUID,
      ),
    [favoritesContext.favoritesKeys, productsUUID],
  )
  const isFetching = useMemo(
    () =>
      (favoritesContext.fetchingUUIds || []).some(
        (item) => item === productsUUID,
      ),
    [favoritesContext.fetchingUUIds, productsUUID],
  )

  return {
    add: favoritesContext.add,
    remove: favoritesContext.remove,
    removeAll: favoritesContext.removeAll,
    favoritesKeys: favoritesContext.favoritesKeys,
    isFavorites,
    quantity: favoritesContext.quantity,
    isFetching,
    isShowToLogin: favoritesContext.isShowToLogin,
    hideToLogin: favoritesContext.hideToLogin,
  }
}
