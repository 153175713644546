import { cloneElement, createContext, FC, FunctionComponentElement, memo, useCallback, useEffect, useRef, useState } from "react";
import { cx } from "@linaria/core";
import { DisclosureProps } from "reakit";
import { DialogDisclosure, useDialogState } from "reakit/Dialog";
import { cssIsSticky } from "../../../layouts/Default/Header/StyledHeader";
import { CloseModeType } from "../../../types/types";
import { scrollBodyDisable, scrollBodyEnable } from "../../../utils/helpers";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
import { cssButtonModalClose, Header, Heading, StyledDialog, StyledDialogContent, StyledModalBackdrop } from "./StyledModal";
export type ModalDefaultPropsType = {
  variant?: "rounded-0" | "full" | "rounded-100" | "rounded-50" | "rounded-70";
  title?: string;
  disclosure?: FunctionComponentElement<unknown>;
  onClose?: () => void;
  isShowModal?: boolean;
  closeMode?: CloseModeType;
  hideOnClickOutside?: boolean;
  isModal?: boolean;
};
export const ModalContext = createContext<null | {
  hide: () => void;
  baseId: string;
}>(null);
export const Modal: FC<ModalDefaultPropsType> = memo(({
  title,
  variant = "rounded-0",
  disclosure,
  children,
  onClose,
  isShowModal,
  closeMode = "hide",
  hideOnClickOutside = true,
  isModal = true
}) => {
  const dialog = useDialogState({
    animated: 1000,
    modal: isModal,
    visible: isShowModal
  });
  const element = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const previousVisibleRef = useRef(dialog.visible);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const onScrollhandle = useCallback(() => {
    if (dialog.animating) {
      return;
    }
    const offsetTop = bodyRef.current?.getBoundingClientRect().top || 0;
    setIsSticky(offsetTop < 10);
  }, [dialog.animating]);
  useEffect(() => {
    if (previousVisibleRef.current !== dialog.visible) {
      if (!dialog.visible) {
        scrollBodyEnable();
        onClose && onClose();
      } else {
        scrollBodyDisable(element.current || undefined);
      }
    }
    previousVisibleRef.current = dialog.visible;
  }, [dialog.baseId, dialog.visible]);
  useEffect(() => {
    if (isShowModal !== undefined) {
      if (isShowModal) {
        dialog.show();
      } else {
        dialog.hide();
      }
    }
  }, [isShowModal]);
  useEffect(() => {
    return () => {
      scrollBodyEnable();
    };
  }, []);
  return <ModalContext.Provider value={{
    hide: dialog.hide,
    baseId: dialog.baseId
  }}>
        {disclosure !== undefined && <DialogDisclosure {...dialog} ref={disclosure.ref} {...disclosure.props || {}}>
            {(disclosureProps: DisclosureProps) => cloneElement(disclosure, disclosureProps)}
          </DialogDisclosure>}
        <StyledModalBackdrop data-variant={variant} {...dialog} role="alertdialog" className={cx(isSticky && cssIsSticky)} onScroll={onScrollhandle}>
          <StyledDialog {...dialog} preventBodyScroll={false} aria-label="dialog" tabIndex={0} unstable_finalFocusRef={element} unstable_autoFocusOnShow={false} unstable_autoFocusOnHide={false} hideOnClickOutside={hideOnClickOutside}>
            {(closeMode === "hide" || dialog.visible || dialog.animating) && <StyledDialogContent ref={bodyRef}>
                <Header>
                  {title && <Heading>
                      <Typography variant={"h3"}>{title}</Typography>
                    </Heading>}
                  <Button variant={"box"} icon={"X"} className={cssButtonModalClose} onClick={() => {
              dialog.hide();
            }} />
                </Header>
                {children}
              </StyledDialogContent>}
          </StyledDialog>
        </StyledModalBackdrop>
      </ModalContext.Provider>;
});
Modal.displayName = "Modal";