import { useMutation } from "react-query"
import {
  fetchRemoveAllToCompares,
  fetchRemoveToCompare,
  fetchAddToCompare,
  fetchCompare,
} from "../../api/compareAPI"
import {
  removeComparesAllProgress,
  removeAllFromCompares,
  toggleCompareProgress,
  addCompareCategory,
  removeFromCompare,
  addToCompare,
} from "../../store/reducers/accountSlice"
import { useAppDispatch } from "../redux"

type BindCompareFn = () => void

interface CompareProps {
  bindCompare: BindCompareFn
}

export const useCompareMutations = ({ bindCompare }: CompareProps) => {
  const dispatch = useAppDispatch()

  const { mutate: addToCompareMutate } = useMutation(fetchAddToCompare, {
    // only auth user
    onMutate: (request) => {
      dispatch(
        toggleCompareProgress({
          products: request.products,
          isFetching: true,
        }),
      )
    },
    onSuccess: (response, request) => {
      dispatch(
        addToCompare({
          productUUID: request.products.split(","),
          categoryUUID: request.categoryUUID ?? "",
        }),
      )
    },
    onSettled: (data, error, request) => {
      dispatch(
        toggleCompareProgress({
          products: request.products,
          isFetching: false,
        }),
      )
    },
  })

  const { mutate: removeFromCompareMutate } = useMutation(
    // only auth user
    fetchRemoveToCompare,
    {
      onMutate: (request) => {
        dispatch(
          toggleCompareProgress({
            uuidCategory: request?.uuidCategory ?? "",
            products: request.products,
            isFetching: true,
          }),
        )
      },
      onSuccess: (response, request) => {
        dispatch(
          removeFromCompare({
            removeProductFromCategory: request?.removeProductFromCategory ?? "",
            uuidCategory: request?.uuidCategory ?? "",
            products: request.products ?? "",
          }),
        )
      },
      onSettled: (data, error, request) => {
        dispatch(
          toggleCompareProgress({
            uuidCategory: request?.uuidCategory ?? "",
            products: request.products,
            isFetching: false,
          }),
        )
      },
    },
  )

  const { mutate: removeFromComparesAllMutate } = useMutation(
    // only auth user
    fetchRemoveAllToCompares,
    {
      onMutate: () => {
        dispatch(removeComparesAllProgress({ isFetching: true }))
      },
      onSuccess: () => {
        dispatch(removeAllFromCompares())
      },
      onSettled: () => {
        dispatch(removeComparesAllProgress({ isFetching: false }))
      },
    },
  )

  const { mutate: getCompareUserMutate } = useMutation(fetchCompare, {
    //only for auth user
    onSuccess: (response) => {
      response && dispatch(addCompareCategory(response))
      bindCompare()
    },
  })

  return {
    removeFromComparesAllMutate,
    removeFromCompareMutate,
    getCompareUserMutate,
    addToCompareMutate,
  }
}
