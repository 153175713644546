import { FC, useCallback, useEffect, useState } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import type { LocationListPropsType } from "../../../../components/LocationList";
import { Button } from "../../../../components/ui/Button/Button";
import { ButtonGroup } from "../../../../components/ui/Button/StyledButton";
import { Modal } from "../../../../components/ui/Modals/Modal";
import type { PopoverPropsType } from "../../../../components/ui/Popover/Popover";
import { Typography } from "../../../../components/ui/Typography/Typography";
import { useApp } from "../../../../hooks/app";
import { LocationType } from "../../../../types/types";
import { COOKIE_LOCATION_DEFAULT } from "../../../../utils/constants";
import { getLocationCookie, setLocationCookie } from "../../../../utils/helpers";
import { cssButtonSelectCity } from "../StyledHeader";
import { StyledSelectLocation } from "./Styled";
const LocationList = dynamic(((() => import("../../../../components/LocationList").then(mod => mod.LocationList)) as DynamicOptions<LocationListPropsType>), {
  ssr: false
});
const DynamicPopover = dynamic(((() => import("../../../../components/ui/Popover/Popover").then(mod => mod.Popover)) as DynamicOptions<PopoverPropsType>));
export const SelectLocation: FC = () => {
  const {
    location,
    updateLocation
  } = useApp();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isShowOther, setIsShowOther] = useState<boolean>(false);
  const fastYesHandle = useCallback(() => {
    updateLocation(getLocationCookie() || null);
    setIsShow(false);
  }, [updateLocation]);
  const otherHandle = useCallback(() => {
    setIsShowOther(true);
  }, []);
  const onSelectLocationHandle = useCallback((location: LocationType | null) => {
    updateLocation(location);
    setIsShow(false);
  }, [updateLocation]);
  useEffect(() => {
    if (!getLocationCookie()) {
      setLocationCookie(COOKIE_LOCATION_DEFAULT);
      setIsShow(true);
    }
  }, [location]);
  return <StyledSelectLocation>
      <DynamicPopover disclosure={<Button />} isShow={isShow} size={"default"} isHiddenDisclosure hideOnClickOutside={false}>
        <Typography variant={"p14"}>
          Ваш город {COOKIE_LOCATION_DEFAULT.city}?
        </Typography>
        <ButtonGroup>
          <Button variant={"filled"} size={"small"} onClick={fastYesHandle} aria-label={"Местоположение по-умолчанию"} type="button" role="button">
            Да
          </Button>
          <Modal title={"Выберите свой город"} variant={"rounded-50"} closeMode={"destroy"} disclosure={<Button variant={"outline"} size={"small"} onClick={otherHandle} aria-label={"Другое местоположение"} type="button" role="button">
                Другой
              </Button>} isShowModal={isShowOther}>
            <LocationList currentLocation={location || undefined} onSelectLocation={onSelectLocationHandle} />
          </Modal>
        </ButtonGroup>
      </DynamicPopover>

      <Modal closeMode={"destroy"} title={"Выберите свой город"} variant={"rounded-50"} disclosure={<Button variant={"small"} icon={"AngleBottom"} iconPosition={"right"} className={cssButtonSelectCity} aria-label={"Текущее местоположение"} type="button" role="button">
            {!!location?.city ? location.city : "Город"}
          </Button>}>
        <LocationList currentLocation={location || undefined} onSelectLocation={location => updateLocation(location)} />
      </Modal>
    </StyledSelectLocation>;
};