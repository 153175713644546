import { PictureListItemType } from "../components/List/PictureList"
import { colors } from "../styles/utils/vars"
import { LinkItemType, LocationType } from "../types/types"

export const TITLE_SITE_RU = "Гростер"
export const SITE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.NEXT_PUBLIC_BASE_URL || "https://groster.me"
export const BASE_URL =
  process.env.NEXT_PUBLIC_API_BASE_URL || `https://api.groster.me`
export const BASE_VERSION_URL =
  process.env.NEXT_PUBLIC_API_BASE_VERSION || "/api/v1"

export const ROUTES = {
  favorites: "/favorites",
  compare: "/compare",
  catalog: "/catalog",
  cart: "/cart",
  checkout: "/checkout",
  thank: "/thank",
  product: "/product",
  account: "/account",
  historyOrders: "/history",
  subscriptions: "/subscriptions",
  staff: "/staff",
  payers: "/payers",
  addresses: "/addresses",
  pricelist: "/pricelist",
  recommendations: "/recommendations",
  about: "/about",
  stores: "/stores",
  policy: "/policy",
  contacts: "/contacts",
  help: "/help",
  paymentTerm: "/term_payment",
  deliveryTerm: "/term_delivery",
  productWarranty: "/product_warranty",
  faq: "/faq",
  agree: "/agree",
  search: "/search",
  return: "/return",
  notFound: "/404",
}

export const CURRENCY = "₽"
export const CURRENCY_SHORT_NAME = "руб."

export const COOKIE_LOCATION_NAME = "location"
export const COOKIE_HOST_NAME = "host"
export const COOKIE_LOCATION_DEFAULT: LocationType = {
  city: "Волгоград",
  city_type: "г",
  region: "Волгоградская",
  region_type: "обл",
  region_kladr_id: "3400000100000",
}
export const COOKIE_ALLOWED_KEY = "isAllowedCookie"
export const COOKIE_SCROLL_POSITION_CATALOG = "scrollPositionCatalog"

export const LOCATIONS_IMPORTANT: LocationType[] = [
  {
    city: "Москва",
    city_type: "г",
    region: "Москва",
    region_type: "г",
    region_kladr_id: "7700000000000",
  },
  {
    city: "Санкт-Петербург",
    city_type: "г",
    region: "Ленинградская",
    region_type: "обл",
    region_kladr_id: "7800000000000",
  },
  {
    city: "Барнаул",
    city_type: "г",
    region: "Алтайский",
    region_type: "край",
    region_kladr_id: "2200000100000",
  },
  {
    city: "Владивосток",
    city_type: "г",
    region: "Приморский",
    region_type: "край",
    region_kladr_id: "2500000100000",
  },
  {
    ...COOKIE_LOCATION_DEFAULT,
  },
  {
    city: "Воронеж",
    city_type: "г",
    region: "Воронежская",
    region_type: "обл",
    region_kladr_id: "3600000100000",
  },
  {
    city: "Екатеринбург",
    city_type: "г",
    region: "Свердловская",
    region_type: "обл",
    region_kladr_id: "6600000100000",
  },
  {
    city: "Ижевск",
    city_type: "г",
    region: "Удмуртская",
    region_type: "Респ",
    region_kladr_id: "1800000100000",
  },
  {
    city: "Иркутск",
    city_type: "г",
    region: "Иркутская",
    region_type: "обл",
    region_kladr_id: "3800000300000",
  },
  {
    city: "Казань",
    city_type: "г",
    region: "Татарстан",
    region_type: "Респ",
    region_kladr_id: "1600000100000",
  },
  {
    city: "Кемерово",
    city_type: "г",
    region: "Кемеровская область - Кузбасс",
    region_type: "обл",
    region_kladr_id: "4200000900000",
  },
  {
    city: "Краснодар",
    city_type: "г",
    region: "Краснодарский",
    region_type: "край",
    region_kladr_id: "2300000100000",
  },
  {
    city: "Красноярск",
    city_type: "г",
    region: "Красноярский",
    region_type: "край",
    region_kladr_id: "2400000100000",
  },
  {
    city: "Махачкала",
    city_type: "г",
    region: "Дагестан",
    region_type: "Респ",
    region_kladr_id: "0500000100000",
  },
  {
    city: "Нижний Новгород",
    city_type: "г",
    region: "Нижегородская",
    region_type: "обл",
    region_kladr_id: "5200000100000",
  },
  {
    city: "Новосибирск",
    city_type: "г",
    region: "Новосибирская",
    region_type: "обл",
    region_kladr_id: "5400000100000",
  },
  {
    city: "Омск",
    city_type: "г",
    region: "Омская",
    region_type: "обл",
    region_kladr_id: "5500000100000",
  },
  {
    city: "Оренбург",
    city_type: "г",
    region: "Оренбургская",
    region_type: "обл",
    region_kladr_id: "5600000100000",
  },
  {
    city: "Пермь",
    city_type: "г",
    region: "Пермский",
    region_type: "край",
    region_kladr_id: "5900000100000",
  },
  {
    city: "Ростов-на-Дону",
    city_type: "г",
    region: "Ростовская",
    region_type: "обл",
    region_kladr_id: "6100000100000",
  },
  {
    city: "Самара",
    city_type: "г",
    region: "Самарская",
    region_type: "обл",
    region_kladr_id: "6300000100000",
  },
  {
    city: "Саратов",
    city_type: "г",
    region: "Саратовская",
    region_type: "обл",
    region_kladr_id: "6400000100000",
  },
  {
    city: "Тольятти",
    city_type: "г",
    region: "Самарская",
    region_type: "обл",
    region_kladr_id: "6300000700000",
  },
  {
    city: "Томск",
    city_type: "г",
    region: "Томская",
    region_type: "обл",
    region_kladr_id: "7000000100000",
  },
  {
    city: "Тюмень",
    city_type: "г",
    region: "Тюменская",
    region_type: "обл",
    region_kladr_id: "7200000100000",
  },
  {
    city: "Уфа",
    city_type: "г",
    region: "Башкортостан",
    region_type: "Респ",
    region_kladr_id: "0200000100000",
  },
  {
    city: "Хабаровск",
    city_type: "г",
    region: "Хабаровский",
    region_type: "край",
    region_kladr_id: "2700000100000",
  },
  {
    city: "Челябинск",
    city_type: "г",
    region: "Челябинская",
    region_type: "обл",
    region_kladr_id: "7400000100000",
  },
  {
    city: "Ярославль",
    city_type: "г",
    region: "Ярославская",
    region_type: "обл",
    region_kladr_id: "7600000100000",
  },
]

export const WEEKDAYS_SHORT = {
  ru: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
}
export const MONTHS = {
  ru: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
}

export const MONTHS_DECLINATION = {
  ru: [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ],
}

export const WEEKDAYS_LONG = {
  ru: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
}

export const LIMIT_COUNT_IN_CART_NUM = 9

export const TIMEOUT_SUCCESS = 4000

export const LIST_PICTURE_ITEMS: PictureListItemType[] = [
  {
    title: "Широкий",
    description:
      "Гростер — это широко. Широкий ассортимент, размах и цели. Мы двигаемся широко, покрывая новые территории и сферы товаров. Широкий сервис: значит каждый клиент встречается тепло и с любовью.",
    icon: "Transaction",
  },
  {
    title: "Приятный",
    description:
      "Приятная надежность. Мы стараемся удовлетворять все потребности наших клиентов и сотрудников. Мы хотим быть той компанией с которой и в которой приятно работать.",
    icon: "Heart",
  },
  {
    title: "Преимущественный",
    description:
      "В сравнении с конкурентами Гростер всегда старается выиграть. Дать на один плюс больше. Выделиться из толпы, предоставляя больше плюсов и преимуществ для клиентов и сотрудников.",
    icon: "Trophy",
  },
]

export const EMPTY_DATA_PLACEHOLDER = "Без названия"

export const CONTACT_WHATSAPP: LinkItemType = {
  icon: "WhatsApp",
  title: "+7 (905) 330-36-35",
  path: "https://wa.me/+79053303635/",
}

export const CONTACT_PHONE: LinkItemType = {
  icon: "Phone",
  title: "8 (844) 220-36-35",
  path: "tel:88442203635",
}

export const CONTACT_CHAT: LinkItemType = {
  icon: "Chat",
  title: "+7 905 330-36-35",
  path: "tel:+79053303635",
}

export const CONTACT_VIBER: LinkItemType = {
  icon: "Viber",
  title: "+7 (905) 330-36-35",
  path: "viber://chat?number=+79053303635",
}
export const CONTACTS: (LinkItemType & { fillIcon?: string })[] = [
  CONTACT_PHONE,
  CONTACT_CHAT,
  { ...CONTACT_WHATSAPP, title: "WhatsApp" },
]

export const PHONES_ITEMS: (LinkItemType & { fillIcon?: string })[] = [
  { ...CONTACT_PHONE, fillIcon: colors.brand.purple },
  { ...CONTACT_WHATSAPP, fillIcon: colors.green },
  {
    ...CONTACT_VIBER,
    fillIcon: colors.brand.purpleDarken,
  },
]

export const DELIVERY_METHODS = [
  "Самовывоз из магазина-партнёра;",
  "Курьером;",
  "Транспортной компанией.",
]

export const DELIVERY_TERMS = [
  "По Волгограду и в город Волжский – в день заказа;",
  "В города Волгоградской области и другие близлежащие города – обговаривается с менеджером, срок не превышает неделю.",
  "В другие города РФ – в соответствии со сроками транспортной компании, до транспортной компании – в тот же день.",
  "Если у Вас срочный заказ – мы отправим его на такси.",
]

export const CREATE_ORDER_STEPS = [
  "Выбирайте все понравившиеся позиции на сайте;",
  "Оформляете заказ;",
  "Ждите доставку, или пока с Вами свяжется менеджер, если Вы это просили в комментарии к заказу.",
]

export const RETURN_PRODUCT_STEPS = [
  "номер заказа;",
  "наименование товара;",
  "количество единиц;",
  "характер брака;",
  "приложить фото, где отчётливо виден брак.",
]
export const VIEW_PRODUCTS_GRID = "grid"
export const VIEW_PRODUCTS_LIST = "list"
export const VIEW_PRODUCTS_CHECKOUT = "checkout"
export const VIEW_PRODUCTS_MINI_CARD = "mini"
export const MODIFIER_PRODUCTS_MINI = "mini"

export const DATE_STORE = {
  iso: null as string | null,
}

export const NO_IMAGE_PNG = `/images/noimage.png`

export const FOUNDED_STRING = ["Найден", "Найдено", "Найдено"]
export const PRODUCTS_STRING = ["товар", "товара", "товаров"]

export const IMAGE_TITLE_CARD_SIZE_MODIFICATOR = 30
